import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../components/Input";
import CustomButton from "../components/CustomButton";
import Modals from "../components/modals/Modals";
import HomeHeader from "../components/HomeHeader";
import { history } from "../store";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { setDataController,setDataControllerList,setDataProcessor,setDataProcessorList,setError } from "../actions";
import { getDataControllers,getDataProcessors,updateDataController,updateDataProcessor } from "../api";
import SelectOption from "../components/SelectOption";
import useWindowSize from "../utils";

import { useStyles } from "../styles/Admin.style";
import { useStylesGlobal } from "../styles/Global.style";

function Admin ({ data_controller,data_processor }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const classesGlobal = useStylesGlobal();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  //console.log(data_controller);
  //console.log(data_processor);

  const [open,setOpen] = useState(false);
  // Data Controller.
  const [updatedDataController,setUpdatedDataController] =
    useState(data_controller);
  // Data Processor.
  const [updatedDataProcessor,setUpdatedDataProcessor] =
    useState(data_processor);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Data Controller.
  const handleChangeDataController = (prop) => (event) => {
    setUpdatedDataController({
      ...updatedDataController,
      [prop]: event.target.value,
    });
  }

  // Data Processor.
  const handleChangeDataProcessor = (prop) => (event) => {
    setUpdatedDataProcessor({
      ...updatedDataProcessor,
      [prop]: event.target.value,
    });
  }

  // Data Controller.
  const handleSaveDataController = () => {
    updateDataController(data_controller.id,updatedDataController)
      .then((data) => {
        dispatch(setDataController(data));
        history.push("/dcr/data-controller");
        getDataControllers()
          .then((data1) => dispatch(setDataControllerList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
  };

  // Data Processor.
  const handleSaveDataProcessor = () => {
    updateDataProcessor(data_processor.id,updatedDataProcessor)
      .then((data) => {
        dispatch(setDataProcessor(data));
        history.push("/dpr/data-processor");
        getDataProcessors()
          .then((data1) => dispatch(setDataProcessorList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
  };


  return (
    <div className={classesGlobal.container}>
      {Object.keys(data_controller).length > 0 &&
        <>
          <HomeHeader value={data_controller.name} />

          <Modals value="cancel" open={open} handleClose={handleClose} />

          <div className={width < 500 ? classes.inner : {}}>
            <CustomButton name="SAVE" onClick={handleSaveDataController} />
            <CustomButton
              name="CANCEL"
              value="back"
              isIcon={true}
              onClick={handleOpen}
            />
          </div>

          <div className={classes.inner}>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="name"
                name="NAME"
                value={updatedDataController.name}
                handle={handleChangeDataController("name")}
              />
              <Input
                field="email"
                name="EMAIL"
                value={updatedDataController.email}
                handle={handleChangeDataController("email")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="street_name"
                name="STREET NAME"
                value={updatedDataController.street_name}
                handle={handleChangeDataController("street_name")}
              />
              <Input
                field="street_number"
                name="STREET NUMBER"
                value={updatedDataController.street_number}
                handle={handleChangeDataController("street_number")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="city"
                name="CITY"
                value={updatedDataController.city}
                handle={handleChangeDataController("city")}
              />
              <Input
                field="postal_code"
                name="POSTAL CODE"
                value={updatedDataController.postal_code}
                handle={handleChangeDataController("postal_code")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <SelectOption
                field="country"
                value="country"
                label={`${t("COUNTRY")}`}
                select={updatedDataController.country}
                handle={handleChangeDataController("country")}
              />
              <Input
                field="vat_number"
                name="VAT NUMBER"
                value={updatedDataController.vat_number}
                handle={handleChangeDataController("vat_number")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="phone_number"
                name="PHONE NUMBER"
                value={updatedDataController.phone_number}
                handle={handleChangeDataController("phone_number")}
              />
            </div>
          </div>
        </>
      }
      {Object.keys(data_processor).length > 0 &&
        <>
          <HomeHeader value={data_processor.name} />

          <Modals value="cancel" open={open} handleClose={handleClose} />

          <div className={width < 500 ? classes.inner : {}}>
            <CustomButton name="SAVE" onClick={handleSaveDataProcessor} />
            <CustomButton
              name="CANCEL"
              value="back"
              isIcon={true}
              onClick={handleOpen}
            />
          </div>

          <div className={classes.inner}>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="name"
                name="NAME"
                value={updatedDataProcessor.name}
                handle={handleChangeDataProcessor("name")}
              />
              <Input
                field="email"
                name="EMAIL"
                value={updatedDataProcessor.email}
                handle={handleChangeDataProcessor("email")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="street_name"
                name="STREET NAME"
                value={updatedDataProcessor.street_name}
                handle={handleChangeDataProcessor("street_name")}
              />
              <Input
                field="street_number"
                name="STREET NUMBER"
                value={updatedDataProcessor.street_number}
                handle={handleChangeDataProcessor("street_number")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="city"
                name="CITY"
                value={updatedDataProcessor.city}
                handle={handleChangeDataProcessor("city")}
              />
              <Input
                field="postal_code"
                name="POSTAL CODE"
                value={updatedDataProcessor.postal_code}
                handle={handleChangeDataProcessor("postal_code")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <SelectOption
                field="country"
                value="country"
                label={`${t("COUNTRY")}`}
                select={updatedDataProcessor.country}
                handle={handleChangeDataProcessor("country")}
              />
              <Input
                field="vat_number"
                name="VAT NUMBER"
                value={updatedDataProcessor.vat_number}
                handle={handleChangeDataProcessor("vat_number")}
              />
            </div>
            <div className={width > 500 ? classes.input : {}}>
              <Input
                field="phone_number"
                name="PHONE NUMBER"
                value={updatedDataProcessor.phone_number}
                handle={handleChangeDataProcessor("phone_number")}
              />
            </div>
          </div>
        </>
      }
    </div>
  );
}

const mapStateProps = (state) => ({
  data_controller: state.dataControllerState.selectedDataController,
  data_processor: state.dataProcessorState.selectedDataProcessor,
});

export default connect(mapStateProps,{})(Admin);
