import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import DeleteModal from "./DeleteModal";
import NewOptionModal from "./NewOptionModal";
import NewRegistryModal from "./NewRegistryModal";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { history } from "../../store";
import {
  getDprRegistries,
  addDprRegistry,
  updateDprRegistry,
  deleteDprRegistry,
  addDirectDataProtectionOfficer,
  getDirectDataProtectionOfficers,
  addDpConcurrentStructure,
  getDpConcurrentStructures,
  addDpInternalStructure,
  getDpInternalStructures,
  addDpController,
  getDpControllers,
} from "../../api";
import {
  resetDprRegistry,
  setError,
  setDprRegistryList,
  setDataProtectionOfficers,
  resetDpo,
  setDpoList,
  setConcurrentStructures,
  setInternalStructures,
  setControllers,
} from "../../actions";
import { getCountryValue } from "../../utils";

import { useStyles } from "../../styles/Modals.style";



function DprProcessingRegistriesModal({
  value,
  handleClose,
  open,
  dprSelectedRegistry,
  selectedDpo,
  name = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState(dprSelectedRegistry || { data_processor: null });

  const [optionData, setOptionData] = useState({
    city: "",
    country: "",
    email: "",
    extra_ue: false,
    juridical_person: false,
    name: "",
    phone_number: "",
    postal_code: "",
    street_name: "",
    street_number: "",
    ue_representative: "",
  });

  const handleRegistryChange = (prop) => (event) => {
    if (prop === "data_processor") {
      setOptionData({ ...optionData, [prop]: event.target.value });
    }
    setData({ ...data, [prop]: event.target.value });
  };

  const handleOptionChange = (prop) => (event) => {
    if (
      prop === "extra_ue" ||
      prop === "juridical_person"
    ) {
      setData({ ...data, [prop]: event.target.checked });
      setOptionData({
        ...selectedDpo,
        ...optionData,
        [prop]: event.target.checked,
      });
    } else if (prop === "data_processor") {
      setData({ ...data, [prop]: event.target.value });
      setOptionData({
        ...selectedDpo,
        ...optionData,
        [prop]: event.target.value,
      });
    } else {
      setOptionData({
        ...selectedDpo,
        ...optionData,
        [prop]: event.target.value,
      });
    }
  };

  // const callDataProtectionOfficers = () => {
  //   console.log("here we are")

  //   if (open) {
  //     getDirectDataProtectionOfficers()
  //       .then((data) => dispatch(setDataProtectionOfficers(data)))
  //       .catch((err) => dispatch(resetDpo()));
  //   }
  // };
  useEffect(() => {

    if (open) {
      getDirectDataProtectionOfficers()
        .then((data) => dispatch(setDataProtectionOfficers(data)))
        .catch((err) => dispatch(resetDpo()));
    }

  }, [open]);

  const addNewOption = (name, value) => {
    switch (name) {
      case "concurrent_structures" || "concurrent structures":
        addDpConcurrentStructure(dprSelectedRegistry.data_processor, optionData)
          .then((data1) => {
            getDpConcurrentStructures(data.data_processor)
              .then((data2) => dispatch(setConcurrentStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;

      case "controllers":
        addDpController(dprSelectedRegistry.data_processor, optionData)
          .then((data1) => {
            getDpControllers(data.data_processor)
              .then((data2) => dispatch(setControllers(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;

      case "internal_reference_structure" || "internal reference structures":
        addDpInternalStructure(dprSelectedRegistry.data_processor, optionData)
          .then((data1) => {
            getDpInternalStructures(data.data_processor)
              .then((data2) => dispatch(setInternalStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      case "data_protection_officers" || "data protection officers":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers(optionData.data_processor)
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err))
          });
        break;
      case "dpo":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      default:
        break;
    }
    setOptionData({
      data_processor: dprSelectedRegistry.data_prcessor || "",
    });
  };

  const handleConfirmModal = () => {
    switch (value) {
      case "update":
        updateDprRegistry(dprSelectedRegistry.id, data)
          .then((data) => {
            getDprRegistries()
              .then((data1) => { dispatch(setDprRegistryList(data1)) })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;
      case "delete":
        // First close the modal
        handleClose();

        deleteDprRegistry(dprSelectedRegistry.id)
          .then((data) => {
            dispatch(resetDprRegistry());
            getDprRegistries()
              .then((data1) => dispatch(setDprRegistryList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
      case "new_option":
        addNewOption(name, value);
        /*
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });

        setOptionData({
          data_processor: dprSelectedRegistry.data_processor || null,
        });
        */
        handleClose();
        break;
      default:
        addDprRegistry(data)
          .then((data) => {
            getDprRegistries()
              .then((data1) => {
                dispatch(setDprRegistryList(data1))
              })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;
    }
  };

  const getBodyModal = (value) => {

    // callDataProtectionOfficers()

    switch (value) {
      case "dpr_processing_registry":
        //callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={value}
            data={{ ...(dprSelectedRegistry || "") }}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDprRegistry());
              handleClose();
            }}
          />
        )
      case "update":
        //callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={`${value}_dpr_processing_registry`}
            data={{ ...(dprSelectedRegistry || "") }}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDprRegistry());
              handleClose();
            }}
          ></NewRegistryModal>
        );
      case "delete":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        );
      case "new_option":
        const isProtectionOfficers =
          name === "data_protection_officers" ? true : false;

        switch (history.location.pathname) {
          case "/dpr/processing-registries":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_processor: data?.data_processor || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dpr/processing-registries/add-new":
            return (
              <NewOptionModal
                name={name}
                data={{
                  ...optionData,
                  data_processor: data?.data_processor || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dpr/processing-registries/registry/processing":
            return (
              <NewOptionModal
                name={name}
                data={{
                  ...optionData,
                  data_processor: data?.data_processor || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );

          default:
            break;
        }
      default:
        break;
    }
  };



  useEffect(() => {

    setData(dprSelectedRegistry);

  }, [dprSelectedRegistry]);



  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <>{getBodyModal(value)}</>
      </Modal>
    </div>
  );
}

const mapStateProps = (state, dispatch) => ({
  dprSelectedRegistry: state.dprRegistryState.dprSelectedRegistry,
  selectedDpo: state.dpoState.selectedDpo,
});

export default connect(mapStateProps, {})(DprProcessingRegistriesModal);
