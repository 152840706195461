const initialState = {
  advancedDataType: [],
  advancedType: [],
  dataType: [],
  artSix: [],
  artNine: [],
  basicDataType: [],
  basicType: [],
  dataSubject: [],
  dataWarehouse: [],
  euTransfer: [],
  orgMeasures: [],
  purpose: [],
  storageLocation: [],
  storageMethod: [],
  storageSupport: [],
  tecMeasures: [],
  warranty: [],
  concurrentStructures: [],
  externalProcessors: [],
  controllers: [],
  internalStructures: [],
  extraEuTransfer: [],
  dataProtectionOfficers: [],
  riskLevel: ["1","2","3","4"],
  systemAdmin: ["NTP","INT","EXT","UNK"],
  err: {
    advancedDataType: null,
    advancedType: null,
    dataType: null,
    artSix: null,
    artNine: null,
    basicDataType: null,
    basicType: null,
    dataSubject: null,
    dataWarehouse: null,
    euTransfer: null,
    orgMeasures: null,
    purpose: null,
    storageLocation: null,
    storageMethod: null,
    storageSupport: null,
    tecMeasures: null,
    warranty: null,
    concurrentStructures: null,
    externalProcessors: null,
    controllers: null,
    internalStructure: null,
    extraEuTransfer: null,
    dataProtectionOfficers: null,
  },
};

const processingState = (state = initialState,action) => {
  switch (action.type) {
    case "SET_PROCESSING_ADVANCED_DATA_TYPE":
      return {
        ...state,
        advancedDataType: action.payload,
      };
    case "RESET_PROCESSING_ADVANCED_DATA_TYPE":
      return {
        ...state,
        advancedDataType: [],
      };
    case "FAIL_PROCESSING_ADVANCED_DATA_TYPE":
      return {
        ...state,
        err: { advancedDataType: action.payload.err },
      };
    case "SET_PROCESSING_ADVANCED_TYPE":
      return {
        ...state,
        advancedType: action.payload,
      };
    case "RESET_PROCESSING_ADVANCED_TYPE":
      return {
        ...state,
        advancedType: [],
      };
    case "FAIL_PROCESSING_ADVANCED_TYPE":
      return {
        ...state,
        err: { advancedType: action.payload.err },
      };
    case "SET_PROCESSING_DATA_TYPE":
      return {
        ...state,
        dataType: action.payload,
      };
    case "RESET_PROCESSING_DATA_TYPE":
      return {
        ...state,
        dataType: [],
      };
    case "FAIL_PROCESSING_DATA_TYPE":
      return {
        ...state,
        err: { dataType: action.payload.err },
      };
    case "SET_PROCESSING_ART_SIX":
      return {
        ...state,
        artSix: action.payload,
      };
    case "RESET_PROCESSING_ART_SIX":
      return {
        ...state,
        artSix: [],
      };
    case "FAIL_PROCESSING_ART_SIX":
      return {
        ...state,
        err: { artSix: action.payload.err },
      };
    case "SET_PROCESSING_ART_NINE":
      return {
        ...state,
        artNine: action.payload,
      };
    case "RESET_PROCESSING_ART_NINE":
      return {
        ...state,
        artNine: [],
      };
    case "FAIL_PROCESSING_ART_NINE":
      return {
        ...state,
        err: { artNine: action.payload.err },
      };
    case "SET_PROCESSING_BASIC_DATA_TYPE":
      return {
        ...state,
        basicDataType: action.payload,
      };
    case "RESET_PROCESSING_BASIC_DATA_TYPE":
      return {
        ...state,
        basicDataType: [],
      };
    case "FAIL_PROCESSING_BASIC_DATA_TYPE":
      return {
        ...state,
        err: { basicDataType: action.payload.err },
      };
    case "SET_PROCESSING_BASIC_TYPE":
      return {
        ...state,
        basicType: action.payload,
      };
    case "RESET_PROCESSING_BASIC_TYPE":
      return {
        ...state,
        basicType: [],
      };
    case "FAIL_PROCESSING_BASIC_TYPE":
      return {
        ...state,
        err: { basicType: action.payload.err },
      };
    case "SET_PROCESSING_DATA_SUBJECT":
      return {
        ...state,
        dataSubject: action.payload,
      };
    case "RESET_PROCESSING_DATA_SUBJECT":
      return {
        ...state,
        dataSubject: [],
      };
    case "FAIL_PROCESSING_DATA_SUBJECT":
      return {
        ...state,
        err: { dataSubject: action.payload.err },
      };
    case "SET_PROCESSING_DATA_WAREHOUSE":
      return {
        ...state,
        dataWarehouse: action.payload,
      };
    case "RESET_PROCESSING_DATA_WAREHOUSE":
      return {
        ...state,
        dataWarehouse: [],
      };
    case "FAIL_PROCESSING_DATA_WAREHOUSE":
      return {
        ...state,
        err: { dataWarehouse: action.payload.err },
      };
    case "SET_PROCESSING_EU_TRANSFER":
      return {
        ...state,
        euTransfer: action.payload,
      };
    case "RESET_PROCESSING_EU_TRANSFER":
      return {
        ...state,
        euTransfer: [],
      };
    case "FAIL_PROCESSING_EU_TRANSFER":
      return {
        ...state,
        err: { euTransfer: action.payload.err },
      };
    case "SET_PROCESSING_ORG_MEASURES":
      return {
        ...state,
        orgMeasures: action.payload,
      };
    case "RESET_PROCESSING_ORG_MEASURES":
      return {
        ...state,
        orgMeasures: [],
      };
    case "FAIL_PROCESSING_ORG_MEASURES":
      return {
        ...state,
        err: { orgMeasures: action.payload.err },
      };
    case "SET_PROCESSING_PURPOSE":
      return {
        ...state,
        purpose: action.payload,
      };
    case "RESET_PROCESSING_PURPOSE":
      return {
        ...state,
        purpose: [],
      };
    case "FAIL_PROCESSING_PURPOSE":
      return {
        ...state,
        err: { purpose: action.payload.err },
      };
    case "SET_PROCESSING_STORAGE_LOCATION":
      return {
        ...state,
        storageLocation: action.payload,
      };
    case "RESET_PROCESSING_STORAGE_LOCATION":
      return {
        ...state,
        storageLocation: [],
      };
    case "FAIL_PROCESSING_STORAGE_LOCATION":
      return {
        ...state,
        err: { storageLocation: action.payload.err },
      };
    case "SET_PROCESSING_STORAGE_METHOD":
      return {
        ...state,
        storageMethod: action.payload,
      };
    case "RESET_PROCESSING_STORAGE_METHOD":
      return {
        ...state,
        storageMethod: [],
      };
    case "FAIL_PROCESSING_STORAGE_METHOD":
      return {
        ...state,
        err: { storageMethod: action.payload.err },
      };
    case "SET_PROCESSING_STORAGE_SUPPORT":
      return {
        ...state,
        storageSupport: action.payload,
      };
    case "RESET_PROCESSING_STORAGE_SUPPORT":
      return {
        ...state,
        storageSupport: [],
      };
    case "FAIL_PROCESSING_STORAGE_SUPPORT":
      return {
        ...state,
        err: { storageSupport: action.payload.err },
      };
    case "SET_PROCESSING_TEC_MEASURES":
      return {
        ...state,
        tecMeasures: action.payload,
      };
    case "RESET_PROCESSING_TEC_MEASURES":
      return {
        ...state,
        tecMeasures: [],
      };
    case "FAIL_PROCESSING_TEC_MEASURES":
      return {
        ...state,
        err: { tecMeasures: action.payload.err },
      };
    case "SET_PROCESSING_WARRANTY":
      return {
        ...state,
        warranty: action.payload,
      };
    case "RESET_PROCESSING_WARRANTY":
      return {
        ...state,
        warranty: [],
      };
    case "FAIL_PROCESSING_WARRANTY":
      return {
        ...state,
        err: { warranty: action.payload.err },
      };
    case "SET_CONCURRENT_STRUCTURES":
      return {
        ...state,
        concurrentStructures: action.payload,
      };
    case "RESET_CONCURRENT_STRUCTURES":
      return {
        ...state,
        concurrent_structures: [],
      };
    case "SET_EXTERNAL_PROCESSORS":
      return {
        ...state,
        externalProcessors: action.payload,
      };
    case "RESET_EXTERNAL_PROCESSORS":
      return {
        ...state,
        externalProcessors: [],
      };
    case "SET_CONTROLLERS":
      return {
        ...state,
        controllers: action.payload,
      };
    case "RESET_CONTROLLERS":
      return {
        ...state,
        controllers: [],
      };
    case "SET_INTERNAL_STRUCTURES":
      return {
        ...state,
        internalStructures: action.payload,
      };
    case "RESET_INTERNAL_STRUCTURES":
      return {
        ...state,
        internalStructures: [],
      };
    case "SET_EXTRA_EU_TRANSFER":
      return {
        ...state,
        extraEuTransfer: action.payload,
      };
    case "RESET_EXTRA_EU_TRANSFER":
      return {
        ...state,
        extraEuTransfer: [],
      };
    case "SET_DATA_PROTECTION_OFFICERS":
      return {
        ...state,
        dataProtectionOfficers: action.payload,
      };
    case "RESET_DATA_PROTECTION_OFFICERS":
      return {
        ...state,
        dataProtectionOfficers: [],
      };
    default:
      return state;
  }
};

export default processingState;
