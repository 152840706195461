import React,{ useState,useEffect } from "react";
import Modals from "../components/modals/Modals";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import HomeHeader from "../components/HomeHeader";
import GeneratePDF from "../components/reportGenerator";
import TableDisplay from "../components/TableDisplay";
import CustomButton from "../components/CustomButton";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useWindowSize from "../utils";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDprRegistries } from "../api";
// Remove it! -- Alle.
//import { setRegistryList,resetRegistry,setError } from "../actions";
import { setDprRegistryList,resetDprRegistry,setError } from "../actions";

import { useStyles } from "../styles/ProcessingRegistries.style";
import { useStylesGlobal } from "../styles/Global.style";
import DprProcessingRegistriesModal from "../components/modals/DprProcessingRegistriesModal";

function DprProcessingRegistries ({ dprRegistryList,stateForPDF }) {

  const classes = useStyles();
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [searchValue,setSearchValue] = useState("");

  const handleClose = () => {
    dispatch(resetDprRegistry());
    setOpen(false);
  };

  const handleExport = (e) => {
    e.preventDefault();
    GeneratePDF("dpr_processing_registries",dprRegistryList.results,t,stateForPDF);
  };

  const searchOnChange = (e) => {
    setSearchValue(e.target.value);

    getDprRegistries(null,null,null,e.target.value)
      .then((data) => dispatch(setDprRegistryList(data)))
      .catch((err) => dispatch(setError(err)));
  };

  useEffect(() => {
    getDprRegistries()
      .then((data) => {
        dispatch(setDprRegistryList(data))
      })
      .catch((err) => dispatch(setError(err)));
  },[dispatch]);


  return (
    <div className={classesGlobal.container}>
      <HomeHeader value="Processing Registries" />
      {/*<Modals value="dpr_processing_registry" open={open} handleClose={handleClose} />*/}
      {open &&
        <DprProcessingRegistriesModal value="dpr_processing_registry" open={open} handleClose={handleClose} />
      }

      <div className={width > 500 ? classes.inner : classes.innerMobile}>
        <CustomButton
          name="ADD REGISTRY"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setOpen(!open)}
        />

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder={t("Search…")}
            value={searchValue}
            classes={{ root: classes.inputRoot,input: classes.inputInput }}
            onChange={(e) => searchOnChange(e)}
          />
        </div>

        <CustomButton
          name="EXPORT PDF"
          startIcon={<SystemUpdateAltIcon />}
          onClick={(e) => handleExport(e)}
        />
      </div>

      <TableDisplay value={"dpr_processing_registry"} />
    </div>
  );
}

const mapStateProps = (state) => ({
  dprRegistryList: state.dprRegistryState.dprRegistryList,
  stateForPDF: state,
});

export default connect(mapStateProps,{})(DprProcessingRegistries);
