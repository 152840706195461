import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  label: {
    fontSize: "15px",
    color: `${theme.palette.dark_gray.main}`,
  },
  form: {
    minWidth: "100%",
    backgroundColor: "white",
    maxHeight: "80%",
    marginTop: "5px",
    border: "1px solid inherit",
    borderRadius: "10px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": { backgroundColor: "none" },
  },
}));
