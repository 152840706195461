import React from "react";
import HomeHeader from "../components/HomeHeader";
import CustomButton from "../components/CustomButton";
import PreviewStep from "../components/steps/PreviewStep";
import PreviewDataController from "../components/steps/PreviewDataController";
import PreviewDpo from "../components/steps/PreviewDpo";
import { history } from "../store";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetDataController } from "../actions/dataControllerActions";
import { resetTreatment } from "../actions/treatmentActions";
import { resetDpo } from "../actions/dpoActions";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import GeneratePDF from "../components/reportGenerator";

import { useStyle } from "../styles/DisplayInfo.style";
import { resetDataProcessor } from "../actions";
import PreviewDataProcessor from "../components/steps/PreviewDataProcessor";

function DisplayInfo ({
  selectedTreatment,
  selectedDataController,
  selectedDataProcessor,
  selectedDpo,
  selectedRegistry,
  stateForPDF,
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBack = () => history.goBack();
  const getFullNameDpo = (obj) => {
    let res = "";
    if (!!obj && obj !== undefined) {
      if (!!obj.name) res += ` ${obj.name}`;
      if (!!obj.family_name) res += ` ${obj.family_name}`;
      if (!!obj.company_name) {
        if (res !== "") res += ",";
        res += ` ${obj.company_name}`;
      }
    }
    return res;
  };
  const getHeader = () => {
    let val = "";
    if (Object.keys(selectedTreatment).length > 0)
      val = selectedTreatment?.processing_name;
    if (Object.keys(selectedDataController).length > 0)
      val = selectedDataController?.name;
    if (Object.keys(selectedDataProcessor).length > 0)
      val = selectedDataProcessor?.name;
    if (Object.keys(selectedDpo).length > 0) val = getFullNameDpo(selectedDpo);

    return val;
  };
  const handleExport = async (e,value,k) => {
    e.preventDefault();
    GeneratePDF(k,[value],t,stateForPDF);
  };

  React.useEffect(() => {
    if (
      Object.keys(selectedTreatment).length === 0 &&
      Object.keys(selectedDataController).length === 0 &&
      Object.keys(selectedDataProcessor).length === 0 &&
      Object.keys(selectedDpo).length === 0
    ) {
      history.goBack();
    }

    return () => {
      dispatch(resetDataController());
      dispatch(resetDataProcessor());
      dispatch(resetTreatment());
      dispatch(resetDpo());
    };
  },[
    dispatch,
    selectedTreatment,
    selectedDataController,
    selectedDataProcessor,
    selectedDpo
  ]);


  return (
    <div className={classes.container}>
      <HomeHeader value={getHeader()} />
      <div className={classes.backButton}>
        <CustomButton
          name="BACK"
          value="back"
          isIcon={true}
          onClick={() => handleBack()}
        />
        {Object.keys(selectedTreatment).length > 0 && (
          <CustomButton
            name="EXPORT PDF"
            startIcon={<SystemUpdateAltIcon />}
            onClick={(e) => handleExport(e,selectedTreatment,"processing")}
          />
        )}
        {Object.keys(selectedDataController).length > 0 && (
          <CustomButton
            name="EXPORT PDF"
            startIcon={<SystemUpdateAltIcon />}
            onClick={(e) =>
              handleExport(e,selectedDataController,"data_controller")
            }
          />
        )}
        {Object.keys(selectedDataProcessor).length > 0 && (
          <CustomButton
            name="EXPORT PDF"
            startIcon={<SystemUpdateAltIcon />}
            onClick={(e) =>
              handleExport(e,selectedDataProcessor,"data_processor")
            }
          />
        )}
        {Object.keys(selectedDpo).length > 0 && (
          <CustomButton
            name="EXPORT PDF"
            startIcon={<SystemUpdateAltIcon />}
            onClick={(e) =>
              handleExport(e,selectedDpo,"data_protection_officer")
            }
          />
        )}
      </div>

      {Object.keys(selectedTreatment).length > 0 && (
        <div className={classes.treatmentPreview}>
          <PreviewStep value={selectedTreatment} />
        </div>
      )}
      {Object.keys(selectedDataController).length > 0 && (
        <div className={classes.treatmentPreview}>
          <PreviewDataController value={selectedDataController} />
        </div>
      )}
      {Object.keys(selectedDataProcessor).length > 0 && (
        <div className={classes.treatmentPreview}>
          <PreviewDataProcessor value={selectedDataProcessor} />
        </div>
      )}
      {Object.keys(selectedDpo).length > 0 && (
        <div className={classes.treatmentPreview}>
          <PreviewDpo value={selectedDpo} />
        </div>
      )}
    </div>
  );
}


const mapStateProps = (state) => ({
  selectedTreatment: state.treatmentState.selectedTreatment,
  selectedDataController: state.dataControllerState.selectedDataController,
  selectedDataProcessor: state.dataProcessorState.selectedDataProcessor,
  selectedDpo: state.dpoState.selectedDpo,
  selectedRegistry: state.registryState.selectedRegistry,

  stateForPDF: state,
});

export default connect(mapStateProps,{})(DisplayInfo);
