import createReducer from './reducers'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import "regenerator-runtime/runtime";

export const history = createBrowserHistory({
    basename: window.BASE_NAME
});
const middleware = [thunk, routerMiddleware(history)];
const initialState = {};

const reducer = createReducer(history);
const enhancer = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(reducer, initialState, enhancer);
