export const setDpoList = (payload) => {
    return {
        type: 'SET_DPO_LIST',
        payload: payload,
    }
}

export const resetDpoList = () => {
    return {
        type: 'RESET_DPO_LIST',
    }
}

export const setDpo = (payload) => {
    return {
        type: 'SET_DPO',
        payload: payload,
    }
}

export const resetDpo = () => {
    return {
        type: 'RESET_DPO',
    }
}