import React,{ useState,useEffect } from "react";
import Box from "../components/Box";
import DcrContainer from "../components/DcrContainer";
import CustomButton from "../components/CustomButton";
import HomeHeader from "../components/HomeHeader";
import { history } from "../store";
import { useTranslation } from "react-i18next";
import { connect,useDispatch } from "react-redux";
import useWindowSize from "../utils";
import {
  getProcessingAdvancedDataTypeApi,
  getProcessingDataTypeApi,
  getProcessingArtNineApi,
  getProcessingArtSixApi,
  getProcessingBasicDataTypeApi,
  getProcessingBasicTypeApi,
  getProcessingDataSubjectApi,
  getProcessingDataWarehouseApi,
  getProcessingEuTransferApi,
  getProcessingOrgMeasuresApi,
  getProcessingPurposeApi,
  getProcessingStorageLocationApi,
  // getProcessingStorageMethodApi,
  getProcessingStorageSupportApi,
  getProcessingTecMeasuresApi,
  getProcessingWarrantyApi,
  getTreatments,
  updateTreatment,
  getExtraEuTransfers,
  getProcessingAdvancedTypeApi,
  updateDprTreatment,
  getDprTreatments,
  getDprExtraEuTransfers,
  updateDcrTreatment,
  getDcrTreatments,
  getDcrExtraEuTransfers,
} from "../api";
import {
  getProcessingAdvancedDataType,
  getProcessingDataType,
  getProcessingArtSix,
  getProcessingArtNine,
  getProcessingBasicDataType,
  getProcessingBasicType,
  getProcessingDataSubject,
  getProcessingDataWarehouse,
  getProcessingEuTransfer,
  getProcessingOrgMeasures,
  getProcessingPurpose,
  getProcessingStorageLocation,
  // getProcessingStorageMethod,
  getProcessingStorageSupport,
  getProcessingTecMeasures,
  setError,
  getProcessingWarranty,
  setExtraEuTransfer,
  resetExtraEuTransfer,
  setTreatment,
  setTreatmentsList,
  getProcessingAdvancedType,
  resetDprExtraEuTransfer,
} from "../actions";
import {
  riskLevel,
  sysAdmin,
  manyToMany,
  oneToMany,
  addNewOption,
  booleans,
  othersToAdd,
} from "../api";
import { useStyle } from "../styles/Treatment.style";



function DcrTreatment ({ selectedTreatment,dcrSelectedRegistry }) {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const steps = [
    "General Info",
    "Purpose & Legal Basis",
    "Processing Type",
    "Recipient Transfers",
    "Storage",
    "External Processors",
    "Measures",
    "Preview",
  ];
  const [step,setStep] = useState(0);
  const [isStartPage,setIsStartPage] = useState(true);
  const [data,setData] = useState(selectedTreatment);

  const handleSave = (selectedTreatment,dcrSelectedRegistry) => {
    updateDcrTreatment(selectedTreatment.id,dcrSelectedRegistry.id,data)
      .then((data) => {
        dispatch(setTreatment(data));
        getDcrTreatments(dcrSelectedRegistry.id)
          .then((data1) => dispatch(setTreatmentsList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));

    setIsStartPage(true);
    setData({});
  };

  const handleChange = (prop) => (event,checked,str) => {
    if (manyToMany.some((p) => p === prop)) {
      setData({
        ...data,
        [prop]: checked,
      });
    } else if (booleans.some((p) => p === prop)) {
      setData({
        ...data,
        [prop]: !data[prop],
      });
    } else if (othersToAdd.some((p) => p === prop)) {
      setData({
        ...data,
        [prop]: str,
      });
    } else {
      setData({ ...data,[prop]: event.target.value });
    }
  };

  const getBody = (index) => {
    switch (index) {
      case 0:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>1. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 1:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>2. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 2:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>3. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 3:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>4. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={{ ...data,registry_id: dcrSelectedRegistry.id }}
              handleChange={handleChange}
            />
          </div>
        );
      case 4:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>5. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 5:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>6. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 6:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>7. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
              <CustomButton
                name="NEXT"
                onClick={() => {
                  setStep(index + 1);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      case 7:
        return (
          <div
            className={
              width > 500
                ? classes.innerContainer
                : classes.innerContainerMobile
            }
          >
            <h2>8. {t(steps[index])}</h2>
            <div className={width > 500 ? {} : classes.innerButton}>
              <CustomButton
                name="BACK TO BOX"
                value="back"
                isIcon={true}
                onClick={() => {
                  setIsStartPage(!isStartPage);
                }}
              />
              <CustomButton
                name="SAVE"
                onClick={() => {
                  handleSave(selectedTreatment,dcrSelectedRegistry);
                }}
              />
            </div>

            <DcrContainer
              handle={false}
              activeStep={step}
              treatment={data}
              handleChange={handleChange}
            />
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    setData(selectedTreatment);
    dispatch(resetExtraEuTransfer());
    getDcrExtraEuTransfers(selectedTreatment.id,dcrSelectedRegistry.id)
      .then((data) => dispatch(setExtraEuTransfer(data)))
      .catch((err) => dispatch(setError(err)));
  },[selectedTreatment,dispatch,isStartPage]);

  return (
    <div className={classes.container}>
      <HomeHeader value={selectedTreatment.processing_name} />
      <div style={{ display: "flex" }}>
        <CustomButton
          name="BACK TO REGISTRY"
          value="back"
          onClick={() => history.goBack()}
        />
      </div>

      <div className={width < 500 ? {} : classes.inner}>
        {isStartPage
          ? steps.map((treatment,index) => {
            return (
              <div style={{ margin: "30px" }}>
                <Box
                  value={treatment}
                  onClick={() => {
                    setIsStartPage(!isStartPage);
                    setStep(index);
                  }}
                />
              </div>
            );
          })
          : getBody(step)}
      </div>
    </div>
  );
}

const mapStateProps = (state) => ({
  selectedTreatment: state.treatmentState.selectedTreatment,
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
});

export default connect(mapStateProps,{})(DcrTreatment);
