import React,{ useState } from "react";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDirectDataProtectionOfficer } from "../../api";
import {
  setError,
  resetDpo,
  setDpoList,
} from "../../actions";
import {
  deleteDirectDataProtectionOfficer,
  addDirectDataProtectionOfficer,
  getDirectDataProtectionOfficers,
} from "../../api";

import { useStyles } from "../../styles/Modals.style";
import NewDpoModal from "./NewDpoModal";
import DeleteModal from "./DeleteModal";



function DpoModal ({
  handleClose,
  open,
  selectedDpo,
  value = null
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [optionData,setOptionData] = useState({});

  const handleOptionChange = (prop) => (event) => {
    setOptionData({
      ...selectedDpo,
      ...optionData,
      [prop]: event.target.value,
    });
  };

  const handleConfirmModal = () => {
    switch (value) {
      case "update":
        updateDirectDataProtectionOfficer(selectedDpo.id,optionData)
          .then((data) => {
            getDirectDataProtectionOfficers()
              .then((data1) => dispatch(setDpoList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();

        break;
      case "delete":
        // First close the modal.
        handleClose();

        deleteDirectDataProtectionOfficer(selectedDpo.id)
          .then((data) => {
            dispatch(resetDpo());
            getDirectDataProtectionOfficers()
              .then((data1) => dispatch(setDpoList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;

      default:
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });

        handleClose();

        break;
    }
  };

  const getBodyModal = (value) => {
    switch (value) {
      case "update":
        return (
          <NewDpoModal
            value={value}
            data={{ ...(selectedDpo || null) }}
            handleChange={handleOptionChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
      case "delete":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
      default:
        return (
          <NewDpoModal
            data={{ ...optionData }}
            handleChange={handleOptionChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
    }
  }


  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <>
          {getBodyModal(value)}
        </>
      </Modal>
    </div>
  );
}

const mapStateProps = (state,dispatch) => ({
  //user: state.authState.user,
  selectedDpo: state.dpoState.selectedDpo,
});

export default connect(mapStateProps,{})(DpoModal);
