import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { history } from "../../store"
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTreatment,resetDataController,resetDpo } from "../../actions";
import { DataControllerProcessingActivity,DataProcessorProcessingActivity } from "../../nxprivacy";

import { useStyles } from "../../styles/TableDisplay.style";

function TreatmentBodyTab ({ row,value,handleClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showInformation = () => {

    dispatch(setTreatment(row))
    dispatch(resetDataController())
    dispatch(resetDpo())

    if (row instanceof DataControllerProcessingActivity) {
      history.push("/dcr/processing-registries/registry/display")
    } else if (row instanceof DataProcessorProcessingActivity) {
      history.push("/dpr/processing-registries/registry/display")
    }
  }

  return (
    <TableBody key={row.id}>
      <TableRow className={classes.clickableRow} key={row.id}>
        <TableCell component="th" scope="row" align="left" onClick={() => showInformation()}>
          {row.internal_identifier}
        </TableCell>
        <TableCell align="center" onClick={() => showInformation()}>{row.is_enabled ? t("YES") : t("NO")}</TableCell>
        <TableCell align="right" onClick={() => showInformation()}>{row.processing_name}</TableCell>
        <TableCell align="right" style={{ width: "30px" }}>
          <IconButton
            color="inherit"
            className={classes.iconButton}
            onClick={(e) => handleClick(e,row,value)}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default TreatmentBodyTab;
