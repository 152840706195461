import React,{ useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";

import { setUserLanguage } from "../actions";
import Flag from "react-world-flags";
import { useStyles } from "../styles/SelectLanguage.style";

export function SelectLanguage ({ languages,setUserLanguage }) {
  const language = localStorage.getItem("language") || "en";
  const classes = useStyles();
  //const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang,setLang] = useState(language);

  const handleChange = (language) => {
    setLang(language);
    setUserLanguage(language);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  },[i18n,language,lang]);
  useEffect(() => {
    setUserLanguage(language);
  },[]);
  return (
    <FormControl className={classes.formControl}>
      <Select
        value={lang}
        className={classes.selectEmpty}
        inputProps={{ classes: { icon: classes.icon } }}
      >
        {languages.length !== 0 &&
          languages.map((lang) => {
            let flag = "";
            lang === "en"
              ? (flag = "gb")
              : lang === "it"
                ? (flag = "it")
                : (flag = "");
            return (
              <MenuItem
                onClick={(e) => handleChange(lang)}
                key={lang}
                value={lang}
              >
                <Flag code={flag} height="16" style={{ marginLeft: 3 }} />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

const mapStateToProps = (state) => ({
  languages: state.authState.languages,
  language: state.authState.language,
});

export default connect(mapStateToProps,{ setUserLanguage })(SelectLanguage);
