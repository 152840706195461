import React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import { useStyles } from "../styles/CustomButton.style";

function CustomButton (props) {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <Button
      {...props}
      className={props.value === "back" ? classes.buttonBack : classes.button}
      onClick={props.onClick} >
      {props.isIcon
        ? (props.value === "back" ? <ArrowBackIcon className={classes.iconBack} /> : <ArrowForwardIcon className={classes.iconNext} />)
        : t(props.name)}
    </Button>
  )
}

export default CustomButton;
