import { createTheme } from "@material-ui/core/styles";

const typography = {
  fontFamily: ["Inder", "Saira Extra Condensed"],
};

const palette = {
  primary: { main: "#007A85", dark: "#00555A", light: "#009caa" },
  orange: { main: "#F7931E" },
  black: { main: "#000000" },
  light_gray: { main: "#C8C7CC" },
  dark_gray: { main: "#8A8A8F" },
  white: { main: "#EFEFF4" },
};

let mainLayout = {};

const topBar = {
  height: 70,
  iconBorder: "1px solid #345cae",
};

const bottomTopBar = {
  topAnchor: topBar.height,
  height: 48,
};

const leftSidebar = {
  openedWidth: 250,
  closedWidth: 0,
  height: `calc(100% - ${topBar.height + bottomTopBar.height}px)`,
  topAnchor: topBar.height + bottomTopBar.height,
};

const mainContainer = {
  topAnchor: topBar.height + bottomTopBar.height,
  leftAnchor: leftSidebar.closedWidth,
  width: `calc(100% - ${leftSidebar.closedWidth}px)`,
  height: `calc(100% - ${topBar.height}px)`,
};

mainLayout = {
  topBar,
  bottomTopBar,
  leftSidebar,
  mainContainer,
};

const theme = createTheme({
  typography,
  palette,
  mainLayout,
});

export default theme;
