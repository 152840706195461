import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableBody from '@material-ui/core/TableBody';
import { history } from "../../store"
import { useDispatch } from "react-redux";
import { setDpo,resetDataController,resetTreatment } from "../../actions";

import { useStyles } from "../../styles/TableDisplay.style";

function DpoBodyTab ({ row,value,handleClick }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const showInformation = () => {
        dispatch(setDpo(row))
        dispatch(resetDataController())
        dispatch(resetTreatment())

        history.push("/dpo/display")
    }

    return (
        <TableBody key={row.id}>
            <TableRow className={classes.clickableRow} key={row.id}>
                <TableCell component="th" scope="row" onClick={() => showInformation()}>{row.name}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.family_name}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.company_name}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.email}</TableCell>
                <TableCell align="right" style={{ width: "30px" }}>
                    <IconButton color="inherit" className={classes.iconButton} onClick={(e) => handleClick(e,row,value)}><MoreVertIcon /></IconButton>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

export default DpoBodyTab;
