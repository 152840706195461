export const doLogin = (payload) => {
  return {
    type: 'DO_LOGIN',
    payload: payload,
  }
}

export const setAuth = (payload) => {
  return {
    type: 'SET_AUTH',
    payload: payload,
  }
}

export const failAuthCheck = (err) => {
  return {
    type: 'FAIL_AUTH_CHECK',
    payload: {
      err: err,
    },
  }
}

export const resetAuth = () => {
  return {
    type: 'RESET_AUTH',
  }
}

export const setUserLanguage = (lang) => {
  return {
    type: 'SET_USER_LANGUAGE',
    payload: {language: lang},
  }
}

export const setSidebarBehaviour = (sidebarOpen) => {
  return {
    type: 'SET_SIDEBAR_BEHAVIOUR',
    payload: {sidebarOpen: sidebarOpen},
  }
}