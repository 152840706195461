import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SelectOption from "../SelectOption";
import Grid from "@material-ui/core/Grid";

import { useStylesGlobal } from "../../styles/Global.style";

function MeasuresStep({ handle, value, handleChange }) {
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && <h2>{t("Measures")}</h2>}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangePanel("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded === "panel1"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>
            {t("Technical Measures")}
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="technical_measures"
                value="technical_measures"
                label={`* ${t("TECHNICAL MEASURES")}`}
                select={value.technical_measures}
                handle={handleChange("technical_measures")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_technical_measures"
                name="OTHER TECHNICAL MEASURES"
                value={value.other_technical_measures}
                handle={handleChange("other_technical_measures")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangePanel("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={
            expanded === "panel2"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>
            {t("Organizational Measures")}
          </h1>
        </AccordionSummary>
        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="organizational_measures"
                value="organizational_measures"
                label={`* ${t("ORGANIZATIONAL MEASURES")}`}
                select={value.organizational_measures}
                handle={handleChange("organizational_measures")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_organizational_measures"
                name="OTHER ORGANIZATIONAL MEASURES"
                value={value.other_organizational_measures}
                handle={handleChange("other_organizational_measures")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChangePanel("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          style={
            expanded === "panel3"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>
            {t("System Admin and Instructions")}
          </h1>
        </AccordionSummary>

        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    field="internal_instructions"
                    color="primary"
                    checked={value.internal_instructions}
                    onChange={handleChange("internal_instructions")}
                    name="INTERNAL INSTRUCTIONS"
                  />
                }
                label={t("INTERNAL INSTRUCTIONS")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="system_administrator"
                value="system_administrator"
                label={`* ${t("SYSTEM ADMINISTRATOR")}`}
                select={value.system_administrator}
                handle={handleChange("system_administrator")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChangePanel("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          style={
            expanded === "panel4"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Risk Level")}</h1>
        </AccordionSummary>

        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="risk_level"
                value="risk_level"
                label={`* ${t("RISK LEVEL")}`}
                select={value.risk_level}
                handle={handleChange("risk_level")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    field="pia_needed"
                    color="primary"
                    checked={value.pia_needed}
                    onChange={handleChange("pia_needed")}
                    name="PIA NEEDED"
                  />
                }
                label={t("PIA NEEDED")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MeasuresStep;
