import React,{ useEffect,useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import HomeHeader from "../components/HomeHeader";
import GeneratePDF from "../components/reportGenerator";
import TableDisplay from "../components/TableDisplay";
import CustomButton from "../components/CustomButton";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useWindowSize from "../utils";
import { history } from "../store";
import { useTranslation } from "react-i18next";
import { connect,useDispatch } from "react-redux";
import {
  getProcessingAdvancedDataType,
  getProcessingAdvancedType,
  getProcessingArtNine,
  getProcessingArtSix,
  getProcessingBasicDataType,
  getProcessingBasicType,
  getProcessingDataSubject,
  getProcessingDataType,
  getProcessingDataWarehouse,
  getProcessingEuTransfer,
  getProcessingOrgMeasures,
  getProcessingPurpose,
  getProcessingStorageLocation,
  // getProcessingStorageMethod,
  getProcessingStorageSupport,
  getProcessingTecMeasures,
  getProcessingWarranty,
  resetTreatment,
  setDcrConcurrentStructures,
  setError,
  setExternalProcessors,
  setDcrInternalStructures,
  setTreatmentsList,
  //setDcrTreatmentsList,
  //resetDcrTreatment,
  setInternalStructures,
  setConcurrentStructures,
  resetExternalProcessors,
  resetControllers,
  resetProcessingAdvancedDataType,
  resetProcessingAdvancedType,
  resetProcessingDataType,
  resetProcessingArtSix,
  resetProcessingArtNine,
  resetProcessingBasicDataType,
  resetProcessingBasicType,
  resetProcessingDataSubject,
  resetProcessingDataWarehouse,
  resetProcessingEuTransfer,
  resetProcessingOrgMeasures,
  resetProcessingPurpose,
  resetProcessingStorageLocation,
  resetProcessingStorageSupport,
  resetProcessingTecMeasures,
  resetProcessingWarranty,
  resetConcurrentStructures,
  resetInternalStructures,
  resetExtraEuTransfer,
  resetDataProtectionOfficers,

} from "../actions";
import {
  getDcConcurrentStructures,
  // getExtraEuTransfers,
  getDcInternalStructures,
  getProcessingAdvancedDataTypeApi,
  getProcessingAdvancedTypeApi,
  getProcessingArtNineApi,
  getProcessingArtSixApi,
  getProcessingBasicDataTypeApi,
  getProcessingBasicTypeApi,
  getProcessingDataSubjectApi,
  getProcessingDataTypeApi,
  getProcessingDataWarehouseApi,
  getProcessingEuTransferApi,
  getProcessingOrgMeasuresApi,
  getProcessingPurposeApi,
  getProcessingStorageLocationApi,
  // getProcessingStorageMethodApi,
  getProcessingStorageSupportApi,
  getProcessingTecMeasuresApi,
  getProcessingWarrantyApi,
  getDcrTreatments,
  getDcExternalProcessors,
  getDcrProcessingAdvancedDataTypeApi,
} from "../api";

import { useStylesGlobal } from "../styles/Global.style";
import { useStyles } from "../styles/Registry.style";

function DcrRegistry ({ dcrSelectedRegistry,treatmentList,stateForPDF }) {
  const { width } = useWindowSize();
  const classes = useStyles();
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue,setSearchValue] = useState("");

  const handleExport = async (e) => {
    e.preventDefault();
    GeneratePDF("processings_preview",treatmentList.results,t,stateForPDF);
  };

  const searchOnChange = (e) => {
    setSearchValue(e.target.value);

    getDcrTreatments(dcrSelectedRegistry.id,null,null,null,e.target.value)
      .then((data) => dispatch(setTreatmentsList(data)))
      .catch((err) => dispatch(setError(err)));
  };

  // useEffect(() => {

  //   dispatch(resetDprTreatment());

  //   if (Object.keys(dcrSelectedRegistry).length === 0) {
  //     history.goBack();
  //   }
  // }, [dcrSelectedRegistry, dispatch]);

  useEffect(() => {
    dispatch(resetProcessingAdvancedDataType())
    dispatch(resetProcessingAdvancedType())
    dispatch(resetProcessingDataType())
    dispatch(resetProcessingArtSix())
    dispatch(resetProcessingArtNine())
    dispatch(resetProcessingBasicDataType())
    dispatch(resetProcessingBasicType())
    dispatch(resetProcessingDataSubject())
    dispatch(resetProcessingDataWarehouse())
    dispatch(resetProcessingEuTransfer())
    dispatch(resetProcessingOrgMeasures())
    dispatch(resetProcessingPurpose())
    dispatch(resetProcessingStorageLocation())
    dispatch(resetProcessingStorageSupport())
    dispatch(resetProcessingTecMeasures())
    dispatch(resetProcessingWarranty())
    dispatch(resetConcurrentStructures())
    dispatch(resetExternalProcessors())
    dispatch(resetControllers())
    dispatch(resetInternalStructures())
    dispatch(resetExtraEuTransfer())
    dispatch(resetDataProtectionOfficers())

    getDcrTreatments(dcrSelectedRegistry.id)
      .then((data) => dispatch(setTreatmentsList(data)))
      .catch((err) => dispatch(setError(err)));

    getDcConcurrentStructures(dcrSelectedRegistry.data_controller)
      .then((data) => dispatch(setConcurrentStructures(data)))
      .catch((err) => dispatch(setError(err)));

    getDcInternalStructures(dcrSelectedRegistry.data_controller)
      .then((data) => dispatch(setInternalStructures(data)))
      .catch((err) => dispatch(setError(err)));

    getDcExternalProcessors(dcrSelectedRegistry.data_controller)
      .then((data) => dispatch(setExternalProcessors(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingAdvancedDataTypeApi()
      .then((data) => dispatch(getProcessingAdvancedDataType(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingAdvancedTypeApi()
      .then((data) => dispatch(getProcessingAdvancedType(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingDataTypeApi()
      .then((data) => dispatch(getProcessingDataType(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingArtNineApi()
      .then((data) => dispatch(getProcessingArtNine(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingArtSixApi()
      .then((data) => dispatch(getProcessingArtSix(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingBasicDataTypeApi()
      .then((data) => dispatch(getProcessingBasicDataType(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingBasicTypeApi()
      .then((data) => dispatch(getProcessingBasicType(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingDataSubjectApi()
      .then((data) => dispatch(getProcessingDataSubject(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingDataWarehouseApi()
      .then((data) => dispatch(getProcessingDataWarehouse(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingEuTransferApi()
      .then((data) => dispatch(getProcessingEuTransfer(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingOrgMeasuresApi()
      .then((data) => dispatch(getProcessingOrgMeasures(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingPurposeApi()
      .then((data) => dispatch(getProcessingPurpose(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingStorageLocationApi()
      .then((data) => dispatch(getProcessingStorageLocation(data)))
      .catch((err) => dispatch(setError(err)));

    // getProcessingStorageMethodApi()
    //   .then((data) => dispatch(getProcessingStorageMethod(data)))
    //   .catch((err) => dispatch(setError(err)));

    getProcessingStorageSupportApi()
      .then((data) => dispatch(getProcessingStorageSupport(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingTecMeasuresApi()
      .then((data) => dispatch(getProcessingTecMeasures(data)))
      .catch((err) => dispatch(setError(err)));

    getProcessingWarrantyApi()
      .then((data) => dispatch(getProcessingWarranty(data)))
      .catch((err) => dispatch(setError(err)));

    dispatch(resetTreatment());
    if (Object.keys(dcrSelectedRegistry).length === 0) {
      history.goBack();
    }
  },[dispatch,dcrSelectedRegistry]);

  return (
    <div className={classesGlobal.container}>
      <HomeHeader value={dcrSelectedRegistry?.name} />

      <div className={width > 500 ? classes.inner : classes.innerMobile}>
        <CustomButton
          name="ADD ACTIVITY"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() =>
            history.push("/dcr/processing-registries/add-new")
          }
        />

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder="Search…"
            value={searchValue}
            classes={{ root: classes.inputRoot,input: classes.inputInput }}
            onChange={(e) => searchOnChange(e)}
          />
        </div>

        <CustomButton
          name="EXPORT PDF"
          startIcon={<SystemUpdateAltIcon />}
          onClick={(e) => handleExport(e)}
        />
      </div>

      <TableDisplay value={"registry"} />
    </div>
  );
}

const mapStateProps = (state) => ({
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
  treatmentList: state.treatmentState.treatmentList,
  stateForPDF: state,
});

export default connect(mapStateProps,{})(DcrRegistry);
