const initialState = {
    showError: false,
    err: null,
    errors: {}
}

const errorState = (state = initialState,action) => {
    switch (action.type) {
        case 'SET_ERROR':
            const messages = action.payload?.response?.text?.replace(/{|}|\[|\]|"/gm,"").split(",")
            let errors = {}
            messages?.map((e) => {
                let _t = e.split(":")
                return errors[_t[0]] = _t[1]
            })
            return {
                ...state,
                showError: true,
                error: action.payload,
                errors: errors
            }
        case 'RESET_ERROR':
            return {
                ...state,
                showError: false,
                error: {},
                errors: {}
            }
        default:
            return state
    }
}

export default errorState
