import { makeStyles } from "@material-ui/core/styles";

export const useStylesGlobal = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "20px",
    marginBottom: "85px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  input: {
    display: "flex",
    flexDirection: "row",
  },
  stepContainer: {
    height: "100%",
    width: "100%",
  },
  colorHeadingMain: {
    color: theme.palette.primary.main,
  },
  colorHeadingDark: {
    color: theme.palette.primary.dark,
  },
  colorHeadingLight: {
    color: theme.palette.primary.light,
  },
}));
