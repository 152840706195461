import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import DeleteModal from "./DeleteModal";
import NewOptionModal from "./NewOptionModal";
import NewRegistryModal from "./NewRegistryModal";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { history } from "../../store";
import {
  getDcrRegistries,
  addDcrRegistry,
  updateDcrRegistry,
  deleteDcrRegistry,
  addDirectDataProtectionOfficer,
  getDirectDataProtectionOfficers,
  addDcConcurrentStructure,
  getDcConcurrentStructures,
  addDcExternalProcessor,
  getDcExternalProcessors,
  addDcInternalStructure,
  getDcInternalStructures,
} from "../../api";
import {
  resetDcrRegistry,
  setError,
  setDcrRegistryList,
  setDataProtectionOfficers,
  resetDpo,
  setDpoList,
  setDcrConcurrentStructures,
  setConcurrentStructures,
  setDcrExternalProcessors,
  setInternalStructures,
  setExternalProcessors,
} from "../../actions";
import { useStyles } from "../../styles/Modals.style";



function DcrProcessingRegistriesModal({
  value,
  handleClose,
  open,
  dcrSelectedRegistry,
  selectedDpo,
  name = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState(dcrSelectedRegistry || { data_controller: "" });

  const [optionData, setOptionData] = useState({});

  const handleRegistryChange = (prop) => (event) => {
    if (prop === "data_controller") {
      setOptionData({ ...optionData, [prop]: event.target.value });
    }
    setData({ ...data, [prop]: event.target.value });
  };

  const handleOptionChange = (prop) => (event) => {
    if (prop === "data_controller") {
      setData({ ...data, [prop]: event.target.value });
    }
    setOptionData({
      ...selectedDpo,
      ...optionData,
      [prop]: event.target.value,
    });
  };


  // const callDataProtectionOfficers = () => {
  //   console.log("here we are")

  //   if (open) {
  //     getDirectDataProtectionOfficers()
  //       .then((data) => dispatch(setDataProtectionOfficers(data)))
  //       .catch((err) => dispatch(resetDpo()));
  //   }
  // };
  useEffect(() => {

    if (open) {
      getDirectDataProtectionOfficers()
        .then((data) => dispatch(setDataProtectionOfficers(data)))
        .catch((err) => dispatch(resetDpo()));
    }

  }, [open]);

  const addNewOption = (name, value) => {
    switch (name) {
      case "concurrent_structures" || "concurrent structures":
        addDcConcurrentStructure(dcrSelectedRegistry.data_controller, optionData)
          .then((data1) => {
            getDcConcurrentStructures(data.data_controller)
              .then((data2) => dispatch(setConcurrentStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "external_processors" || "external processors":
        addDcExternalProcessor(dcrSelectedRegistry.data_controller, optionData)
          .then((data1) => {
            getDcExternalProcessors(data.data_controller)
              .then((data2) => dispatch(setExternalProcessors(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "internal_reference_structure" || "internal reference structures":
        addDcInternalStructure(dcrSelectedRegistry.data_controller, optionData)
          .then((data1) => {
            getDcInternalStructures(data.data_controller)
              .then((data2) => dispatch(setInternalStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      case "data_protection_officers" || "data protection officers":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers(optionData.data_controller)
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err))
          });
        break;
      case "dpo":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      default:
        break;
    }
    setOptionData({
      data_controller: dcrSelectedRegistry.data_controller || "",
    });
  };


  const handleConfirmModal = () => {
    switch (value) {
      case "update":
        updateDcrRegistry(dcrSelectedRegistry.id, data)
          .then((data) => {
            getDcrRegistries()
              .then((data1) => { dispatch(setDcrRegistryList(data1)) })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;
      case "delete":
        // First close the modal
        handleClose();

        deleteDcrRegistry(dcrSelectedRegistry.id)
          .then((data) => {
            dispatch(resetDcrRegistry());
            getDcrRegistries()
              .then((data1) => dispatch(setDcrRegistryList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
      case "new_option":
        addNewOption(name, value);
        /*
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });

        setOptionData({
          data_controller: dcrSelectedRegistry.data_controller || "",
        });
        */
        handleClose();
        break;
      default:
        addDcrRegistry(data)
          .then((data) => {
            getDcrRegistries()
              .then((data1) => {
                dispatch(setDcrRegistryList(data1))
              })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;
    }
  };

  const getBodyModal = (value) => {

    // callDataProtectionOfficers()

    switch (value) {
      case "dcr_processing_registry":
        //callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={value}
            data={{ ...(dcrSelectedRegistry || "") }}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDcrRegistry());
              handleClose();
            }}
          />
        )
      case "update":
        //callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={`${value}_dcr_processing_registry`}
            data={{ ...(dcrSelectedRegistry || "") }}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDcrRegistry());
              handleClose();
            }}
          ></NewRegistryModal>
        );
      case "delete":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        );
      case "new_option":
        const isProtectionOfficers =
          name === "data_protection_officers" ? true : false;

        switch (history.location.pathname) {
          case "/dcr/processing-registries":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_controller: data?.data_controller || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dcr/processing-registries/add-new":
            return (
              <NewOptionModal
                name={name}
                data={{
                  ...optionData,
                  data_controller: data?.data_controller || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dcr/processing-registries/registry/processing":
            return (
              <NewOptionModal
                name={name}
                data={{
                  ...optionData,
                  data_controller: data?.data_controller || "",
                }}
                isProtectionOfficers={isProtectionOfficers}
                //isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          default:
            break;
        }
      default:
        break;
    }
  };



  useEffect(() => {

    setData(dcrSelectedRegistry);

  }, [dcrSelectedRegistry]);



  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <>{getBodyModal(value)}</>
      </Modal>
    </div>
  );
}

const mapStateProps = (state, dispatch) => ({
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
  selectedDpo: state.dpoState.selectedDpo,
});

export default connect(mapStateProps, {})(DcrProcessingRegistriesModal);
