import React,{ useEffect,useState } from "react";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { resetAuth,setError } from "../../actions";
import { useDispatch } from "react-redux";
import { history } from "../../store";
import { connect } from "react-redux";
import { logout } from "../../api";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useStyles } from "../../styles/BottomNavbar.style";
import { useLocation } from "react-router-dom";
import { breadcrumbs } from "../../App";
import useWindowSize from "../../utils";

function BottomNavbar ({ open,handleDrawer,setOpen,language }) {
  let location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [breadcrumbsComp,setBreadcrumbsComp] = useState(null);

  const handleSidebar = () => {
    setOpen(!open);
    handleDrawer();
  };
  const handleLogout = () => {
    setOpen(false);
    logout()
      .then((data) => {
        dispatch(resetAuth());
        history.push("/");
      })
      .catch((err) => setError(err));
  };

  const getBreacrumbs = (path) => {
    let arr = [];
    arr = breadcrumbs[path];
    let pathWithoutFirst = path.substring(1);
    let pathComponents = pathWithoutFirst.split("/");
    let links = arr.map((e,i) => (
      <Link
        component="button"
        color="inherit"
        // href={`/${pathComponents.slice(0, i + 1).join("/")}`}
        onClick={() => {
          history.push(`/${pathComponents.slice(0,i + 1).join("/")}`);
        }}
        key={`link-#${i}`}
      >
        {t(e)}
      </Link>
    ));

    return (
      <Breadcrumbs aria-label="breadcrumb" style={{ color: "white" }} key={Math.floor(Math.random() * 5000)}>
        {links}
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    setBreadcrumbsComp(getBreacrumbs(location.pathname));
  },[location.pathname]);

  useEffect(() => {
    setBreadcrumbsComp(getBreacrumbs(location.pathname));
  },[language]);
  useEffect(() => {
    setBreadcrumbsComp(getBreacrumbs(location.pathname));
  },[]);
  useEffect(() => {
  },[breadcrumbsComp]);

  return (
    <div className={classes.container}>
      <AppBar position="fixed" className={classes.container}>
        <Toolbar variant="dense">
          <IconButton className={classes.toolbar} onClick={handleSidebar}>
            {!!open ? (
              <ChevronLeftIcon className={classes.icon} />
            ) : (
              <MenuIcon className={classes.icon} />
            )}
          </IconButton>
          {!!breadcrumbsComp && width > 550 && breadcrumbsComp}
          <div className={classes.right} onClick={handleLogout}>
            <ExitToAppIcon size="large" className={classes.icon} />
            <p style={{ marginLeft: "10px" }}>{t("LOGOUT")}</p>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateProps = (state) => ({
  user: state.authState.user,
  open: state.authState.sidebarOpen,
});

export default connect(mapStateProps,{})(BottomNavbar);
