import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import Modals from "./modals/Modals";
import DcrProcessingRegistriesModal from "./modals/DcrProcessingRegistriesModal";
import DprProcessingRegistriesModal from "./modals/DprProcessingRegistriesModal";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect,useDispatch } from "react-redux";
import { CountryEnum } from "../nxprivacy";
import { useTranslation } from "react-i18next";
import { getCountryValue } from "../utils";
import {
  riskLevel,
  sysAdmin,
  manyToMany,
  //oneToMany,
  addNewOption,
  getDirectDataProtectionOfficers,
} from "../api";
import { useStyles } from "../styles/SelectOption.style";
import { resetDcrRegistry, resetDpo, resetDprRegistry, setDataProtectionOfficers } from "../actions";
import { history } from "../store";
import NewOptionModal from "./modals/NewOptionModal";

const country = new CountryEnum();

function SelectOption({
  dataControllerList,
  dataProcessorList,
  processingOptions,
  label,
  field,
  value,
  handle,
  select,
  errors,
  language,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //- DCR Registry.
  const [newOptionDcrRegistryOpen, setNewOptionDcrRegistryOpen] = useState(false);
  //- DPR Registry.
  const [newOptionDprRegistryOpen, setNewOptionDprRegistryOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [state, setState] = useState(select || "");
  const [checked, setChecked] = useState(
    !!select && select.constructor === Array ? select : []
  );

  //- DCR Registry.
  const handleNewOptionDcrRegistryModal = () => {
    //dispatch(resetDcrRegistry())
    //setNewOptionDcrRegistryOpen(!newOptionDcrRegistryOpen)
    setNewOptionDcrRegistryOpen(false)
  }

  //- DPR Registry.
  const handleNewOptionDprRegistryModal = () => {
    //dispatch(resetDprRegistry())
    //setNewOptionDprRegistryOpen(!newOptionDprRegistryOpen)
    setNewOptionDprRegistryOpen(false)
  }


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    
    currentIndex === -1
      ? newChecked.push(value)
      : newChecked.splice(currentIndex, 1);
    
    setChecked(newChecked);
  };

  const handleState = (e) => {
    e.preventDefault();
    setState(e.target.value);
    if (manyToMany.some((itm) => itm === value)) {
      
      handle(e, [...e.target.value]);
    } else {
      handle(e);
    }
    // if (checked.some((item) => item === e.target.value)) {

    //   const newList = checked.filter((item) => item !== e.target.value);
    //   handle(e, newList);
    // } else {
    //   handle(e, [...checked, e.target.value]);
    // }
  };

  const getTranslatedContent = (val) => {
    // console.log("getTranslated", val, val.name);
    if (val?.name !== undefined) return val.name;
    else {
      if (language === "en" && val?.eng !== undefined) return val.eng;
      else if (language === "it" && val?.ita !== undefined) return val.ita;
    }
    return "";
  };
  const getFullNameDpo = (obj) => {
    let res = "";
    if (!!obj && obj !== undefined) {
      if (!!obj.name) res += ` ${obj.name}`;
      if (!!obj.family_name) res += ` ${obj.family_name}`;
      if (!!obj.company_name) {
        if (res !== "") res += ",";
        res += ` ${obj.company_name}`;
      }
    }
    return res;
  };

  const getOptions = (value) => {

    switch (value) {
      //ONETOMANY
      case "country":
        return Object.keys(country).map((key) => (
          <MenuItem value={getCountryValue(key)} key={key}>
            {getCountryValue(key)}
          </MenuItem>
        ));

      case "warranty":
        return processingOptions.warranty?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {getTranslatedContent(elm)}
            </MenuItem>
          );
        });

      case "data_controller":
        return dataControllerList?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {elm.name}
            </MenuItem>
          );
        });

      case "data_processor":
        return dataProcessorList?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {elm.name}
            </MenuItem>
          );
        });

      case "art6_gdpr_base":
        return processingOptions.artSix?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {getTranslatedContent(elm)}
            </MenuItem>
          );
        });

      case "art9_gdpr_base":
        return processingOptions.artNine?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {getTranslatedContent(elm)}
            </MenuItem>
          );
        });

      // case "internal_instructions":
      //   return processingOptions.internalInstruction?.results?.map((elm) => {
      //     return (
      //       <MenuItem value={elm.id} key={elm.id}>
      //         {getTranslatedContent(elm)}
      //       </MenuItem>
      //     );
      //   });

      case "system_administrator":
        return processingOptions.systemAdmin?.map((elm) => {
          return (
            <MenuItem value={elm} key={elm}>
              {t(sysAdmin[elm])}
            </MenuItem>
          );
        });

      case "risk_level":
        return processingOptions.riskLevel?.map((elm) => {
          return (
            <MenuItem value={elm} key={elm}>
              {/* {elm} */}
              {t(riskLevel[elm])}
            </MenuItem>
          );
        });

      case "data_protection_officers":
        return processingOptions.dataProtectionOfficers?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {getFullNameDpo(elm)}
            </MenuItem>
          );
        });

      case "internal_reference_structure":
        return processingOptions.internalStructures?.results?.map((elm) => {
          return (
            <MenuItem value={elm.id} key={elm.id}>
              {elm.name}
            </MenuItem>
          );
        });

      //MANYTOMANY
      case "processing_purposes":
        return processingOptions.purpose?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "data_subjects":
        return processingOptions.dataSubject?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "concurrent_structures":
        return processingOptions.concurrentStructures?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={elm.name}
              />
            </MenuItem>
          );
        });
      case "processing_types":
        return processingOptions.basicType?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "advanced_processing_types":
        return processingOptions.advancedType?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "basic_data_types":
        return processingOptions.basicDataType?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "special_data_categories":
        return processingOptions.advancedDataType?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "eu_transfers":
        return processingOptions.euTransfer?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "storage_supports":
        return processingOptions.storageSupport?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "storage_locations":
        return processingOptions.storageLocation?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "data_warehouses":
        return processingOptions.dataWarehouse?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "external_processors":
        return processingOptions.externalProcessors?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={elm.name}
              />
            </MenuItem>
          );
        });
      case "controllers":
        return processingOptions.controllers?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={elm.name}
              />
            </MenuItem>
          );
        });
      case "technical_measures":
        return processingOptions.tecMeasures?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      case "organizational_measures":
        return processingOptions.orgMeasures?.results?.map((elm) => {
          return (
            <MenuItem
              value={elm.id}
              key={elm.id}
              onClick={handleToggle(elm.id)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.indexOf(elm.id) !== -1}
                    tabIndex={-1}
                  />
                }
                label={getTranslatedContent(elm)}
              />
            </MenuItem>
          );
        });
      default:
        break;
    }
  };

  const manageModal = (e) => {
    e.preventDefault();
    setOpen(true);

    if (
      history.location.pathname === "/dcr/processing-registries" ||
      history.location.pathname === "/dcr/processing-registries/registry/processing" ||
      history.location.pathname === "/dcr/processing-registries/add-new"
    ) {
      setNewOptionDcrRegistryOpen(!newOptionDcrRegistryOpen)
      //setNewOptionDcrRegistryOpen(true)
    } else if (
      history.location.pathname === "/dpr/processing-registries" ||
      history.location.pathname === "/dpr/processing-registries/registry/processing" ||
      history.location.pathname === "/dpr/processing-registries/add-new"
    ) {
      setNewOptionDprRegistryOpen(!newOptionDprRegistryOpen)
      //setNewOptionDprRegistryOpen(true)
    }
  };

  /*
  const getConcatenate = () => {
    return checked
      .reduce((s,elm) => {
        const get = () => {
          switch (value) {
            case "concurrent_structures":
              return Object.values(
                processingOptions?.concurrentStructures?.results
              ).find((itm) => itm.id === elm);
            case "processing_purposes":
              return Object.values(processingOptions?.purpose?.results).find(
                (itm) => itm.id === elm
              );
            case "processing_types":
              return Object.values(processingOptions?.basicType?.results).find(
                (itm) => itm.id === elm
              );
            case "advanced_processing_types":
              return Object.values(
                processingOptions?.advancedType?.results
              ).find((itm) => itm.id === elm);
            case "data_subjects":
              return Object.values(
                processingOptions?.dataSubject?.results
              ).find((itm) => itm.id === elm);
            case "basic_data_types":
              return Object.values(
                processingOptions?.basicDataType?.results
              ).find((itm) => itm.id === elm);
            case "eu_transfers":
              return Object.values(processingOptions?.euTransfer?.results).find(
                (itm) => itm.id === elm
              );
            case "storage_supports":
              return Object.values(
                processingOptions?.storageSupport?.results
              ).find((itm) => itm.id === elm);
            case "storage_locations":
              return Object.values(
                processingOptions?.storageLocation?.results
              ).find((itm) => itm.id === elm);
            case "data_warehouses":
              return Object.values(
                processingOptions?.dataWarehouse?.results
              ).find((itm) => itm.id === elm);
            case "technical_measures":
              return Object.values(
                processingOptions?.tecMeasures?.results
              ).find((itm) => itm.id === elm);
            case "organizational_measures":
              return Object.values(
                processingOptions?.orgMeasures?.results
              ).find((itm) => itm.id === elm);
            case "external_processors":
              return Object.values(
                processingOptions?.externalProcessors?.results
              ).find((itm) => itm.id === elm);
            case "special_data_categories":
              return Object.values(
                processingOptions?.advancedDataType?.results
              ).find((itm) => itm.id === elm);
            default:
              break;
          }
        };

        const x = get();
        return value === "concurrent_structures" ||
          value === "external_processors"
          ? s + x?.name + ", "
          : language === "en"
            ? s + x.eng + ", "
            : s + x.ita + ", ";
      },"")
      .slice(0,-2);
  };
  */

  /*
  const getArrayValues = () => {
    return checked.map((elm) => {
      switch (value) {
        case "concurrent_structures":
          return Object.values(
            processingOptions?.concurrentStructures?.results
          ).find((itm) => itm.id === elm);
        case "processing_purposes":
          return Object.values(processingOptions?.purpose?.results).find(
            (itm) => itm.id === elm
          );
        case "processing_types":
          return Object.values(processingOptions?.basicType?.results).find(
            (itm) => itm.id === elm
          );
        case "advanced_processing_types":
          return Object.values(processingOptions?.advancedType?.results).find(
            (itm) => itm.id === elm
          );
        case "data_subjects":
          return Object.values(processingOptions?.dataSubject?.results).find(
            (itm) => itm.id === elm
          );
        case "basic_data_types":
          return Object.values(processingOptions?.basicDataType?.results).find(
            (itm) => itm.id === elm
          );
        case "eu_transfers":
          return Object.values(processingOptions?.euTransfer?.results).find(
            (itm) => itm.id === elm
          );
        case "storage_supports":
          return Object.values(processingOptions?.storageSupport?.results).find(
            (itm) => itm.id === elm
          );
        case "storage_locations":
          return Object.values(
            processingOptions?.storageLocation?.results
          ).find((itm) => itm.id === elm);
        case "data_warehouses":
          return Object.values(processingOptions?.dataWarehouse?.results).find(
            (itm) => itm.id === elm
          );
        case "technical_measures":
          return Object.values(processingOptions?.tecMeasures?.results).find(
            (itm) => itm.id === elm
          );
        case "organizational_measures":
          return Object.values(processingOptions?.orgMeasures?.results).find(
            (itm) => itm.id === elm
          );
        case "external_processors":
          return Object.values(
            processingOptions?.externalProcessors?.results
          ).find((itm) => itm.id === elm);
        case "special_data_categories":
          return Object.values(
            processingOptions?.advancedDataType?.results
          ).find((itm) => itm.id === elm);
        default:
          break;
      }
    });
  };
  */

  const renderArrayValues = (selected) => {
    let res = [];
    try {
      res = selected.map((elm) => {
        let obj = null;
        switch (value) {
          case "concurrent_structures":
            obj = Object.values(
              processingOptions?.concurrentStructures?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          case "processing_purposes":
            obj = Object.values(processingOptions?.purpose?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "processing_types":
            obj = Object.values(processingOptions?.basicType?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "advanced_processing_types":
            obj = Object.values(processingOptions?.advancedType?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "data_subjects":
            obj = Object.values(processingOptions?.dataSubject?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "basic_data_types":
            obj = Object.values(processingOptions?.basicDataType?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "eu_transfers":
            obj = Object.values(processingOptions?.euTransfer?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "storage_supports":
            obj = Object.values(
              processingOptions?.storageSupport?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          case "storage_locations":
            obj = Object.values(
              processingOptions?.storageLocation?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          case "data_warehouses":
            obj = Object.values(processingOptions?.dataWarehouse?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "technical_measures":
            obj = Object.values(processingOptions?.tecMeasures?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "organizational_measures":
            obj = Object.values(processingOptions?.orgMeasures?.results).find(
              (itm) => itm.id === elm
            );
            return getTranslatedContent(obj);
          case "external_processors":
            obj = Object.values(
              processingOptions?.externalProcessors?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          case "controllers":
            obj = Object.values(
              processingOptions?.controllers?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          case "special_data_categories":
            obj = Object.values(
              processingOptions?.advancedDataType?.results
            ).find((itm) => itm.id === elm);
            return getTranslatedContent(obj);
          default:
            break;
        }
      });
    } catch (e) {
      console.log("ERROR in RENDER MULTIPLE", value, e);
    }
    // console.log("getArrayValues", res);
    return res;
  };
  const renderSingleValue = (selected) => {
    let obj = null;
    let res = "";
    try {
      switch (value) {
        case "country":
          res = getCountryValue(selected);
          break;
        case "warranty":
          obj = Object.values(processingOptions.warranty?.results).find(
            (itm) => itm.id === selected
          );
          res = getTranslatedContent(obj);
          break;
        case "data_controller":
          obj = Object.values(dataControllerList?.results).find(
            (itm) => itm.id === selected
          );
          res = getTranslatedContent(obj);
          break;
        case "data_processor":
          obj = Object.values(dataProcessorList?.results).find(
            (itm) => itm.id === selected
          );
          res = getTranslatedContent(obj);
          break;
        case "art6_gdpr_base":
          obj = Object.values(processingOptions.artSix?.results).find(
            (itm) => itm.id === selected
          );
          res = getTranslatedContent(obj);
          break;
        case "art9_gdpr_base":
          obj = Object.values(processingOptions.artNine?.results).find(
            (itm) => itm.id === selected
          );
          res = getTranslatedContent(obj);
          break;
        // case "internal_instructions":
        //   obj = Object.values(
        //     processingOptions.internalInstruction?.results
        //   ).find((itm) => itm.id === selected);
        //   res = getTranslatedContent(obj);
        //   break;
        case "system_administrator":
          res = t(sysAdmin[selected]);
          break;
        case "risk_level":
          res = t(riskLevel[selected]);
          break;
        case "data_protection_officers":
          obj = Object.values(
            processingOptions.dataProtectionOfficers?.results
          ).find((itm) => itm.id === selected);
          res = getTranslatedContent(obj);
          break;
        case "internal_reference_structure":
          obj = Object.values(
            processingOptions.internalStructures?.results
          ).find((itm) => itm.id === selected);
          res = getTranslatedContent(obj);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("ERROR in RENDER SINGLE", value, e);
    }
    // console.log("getSingleValue", value, obj, res);
    return res;
  };

  const getSelectValue = (str) => {
    if (manyToMany.some((e) => value === e)) {
      // return checked.length > 0 ? getConcatenate(str) : [];
      // let x = getArrayValues();
      // console.log(checked);
      return checked;
    }
    // console.log("YY", str, value, checked);

    return str;
  };

  const getFirstMenuItem = (value) => {
    if (addNewOption.some((e) => value === e)) {
      return (
        <MenuItem value={null} onClick={(e) => manageModal(e)}>
          {t("Add new option")}
        </MenuItem>
      );
    }
    // return (
    //   <MenuItem value={null}>
    //     <em>None</em>
    //   </MenuItem>
    // );
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.container}
      error={!!errors[field]}
    >
      {/*
      <Modals
        value="new_option"
        name={value}
        open={open}
        handleClose={handleClose}
      />
      */}
      {/* DCR Regsitry */}
      {newOptionDcrRegistryOpen &&
        <DcrProcessingRegistriesModal
          value="new_option"
          name={value}
          open={newOptionDcrRegistryOpen}
          handleClose={handleNewOptionDcrRegistryModal}
        />
      }

      {/* DPR Regsitry */}
      {newOptionDprRegistryOpen &&
        <DprProcessingRegistriesModal
          value="new_option"
          name={value}
          open={newOptionDprRegistryOpen}
          handleClose={handleNewOptionDprRegistryModal}
        />
      }


      <label htmlFor={label} className={classes.label}>
        {label.toUpperCase()}
      </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          labelId={`label-simple-select-${Date.now()}`}
          id={`simple-select-${Date.now()}`}
          value={getSelectValue(state)}
          renderValue={
            manyToMany.some((e) => value === e)
              ? (selected) => renderArrayValues(selected).join(", ")
              : (selected) => renderSingleValue(selected)
          }
          onChange={(e) => handleState(e)}
          className={classes.form}
          multiple={manyToMany.some((e) => value === e)}
        >
          {getFirstMenuItem(value)}
          {value === "data_protection_officers" && (
            <MenuItem value={null} key="no">
              No DPO
            </MenuItem>
          )}
          {getOptions(value)}
        </Select>
      </div>
      <FormHelperText id={`${field}-error`}>
        {!!errors[field] && errors[field]}
      </FormHelperText>
    </FormControl>
  );
}

const mapStateProps = (state) => ({
  dataControllerList: state.dataControllerState.dataControllerList,
  dataProcessorList: state.dataProcessorState.dataProcessorList,
  processingOptions: state.processingState,
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
  language: state.authState.language,
});

export default connect(mapStateProps, {})(SelectOption);
