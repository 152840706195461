import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectOption from "../SelectOption";
import Grid from "@material-ui/core/Grid";


import { useStylesGlobal } from "../../styles/Global.style";

function StorageStep({ handle, value, handleChange }) {
  const expanded = true
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && <h2>{t("Storage")}</h2>}

      <Accordion expanded={expanded} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Storage")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="storage_supports"
                value="storage_supports"

                label={`* ${t("STORAGE SUPPORTS")}`}
                select={value.storage_supports}
                handle={handleChange("storage_supports")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_storage_supports"
                name="OTHER STORAGE"
                value={value.other_storage_supports}
                handle={handleChange("other_storage_supports")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="instrument_description"
                name="INSTRUMENT DESCRIPTION"
                value={value.instrument_description}
                handle={handleChange("instrument_description")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="interconnection_type"
                name="INTERCONNECTION TYPE"
                value={value.interconnection_type}
                handle={handleChange("interconnection_type")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="storage_period"
                name="STORAGE PERIOD"
                value={value.storage_period}
                handle={handleChange("storage_period")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="storage_locations"
                value="storage_locations"

                label={`* ${t("STORAGE MEDIUM")}`}
                select={value.storage_locations}
                handle={handleChange("storage_locations")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="storage_locations_description"
                name="STORAGE MEDIUM DESCRIPTION"
                value={value.storage_locations_description}
                handle={handleChange("storage_locations_description")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="processing_software"
                name="PROCESSING SOFTWARE"
                value={value.processing_software}
                handle={handleChange("processing_software")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="data_warehouses"
                value="data_warehouses"
                label={`* ${t("DATA WAREHOUSE")}`}
                select={value.data_warehouses}
                handle={handleChange("data_warehouses")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default StorageStep;
