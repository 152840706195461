import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  mainContainer: {
    display: "flex",
    top: theme.mainLayout.mainContainer.topAnchor,
    left: theme.mainLayout.mainContainer.leftAnchor,
    position: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPositionY: "center",
    overflow: "scroll",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#efeff4",
    height: "100%",
    width: "100%",
    overflowY: "auto"
  },
}));
