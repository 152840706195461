import React,{ useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

import CustomButton from "./CustomButton";
import InfoStep from "./steps/InfoStep";
import ExternalStep from "./steps/ExternalStep";
import ControllersStep from "./steps/ControllersStep";
import MeasuresStep from "./steps/MeasuresStep";
import PurposesStep from "./steps/PurposesStep";
import StorageStep from "./steps/StorageStep";
import TransfersStep from "./steps/TransfersStep";
import ProcessingStep from "./steps/ProcessingStep";
import PreviewStep from "./steps/PreviewStep";
import { connect,useDispatch } from "react-redux";
import { history } from "../store";
import { setTreatmentsList,setExtraEuTransfer,setError, setControllers } from "../actions";
import {
  addTreatment,
  getTreatments,
  getExtraEuTransfers,
  deleteExtraEuTransfer,
  addExtraEuTransfer,
  deleteDprExtraEuTransfer,
  getDprExtraEuTransfers,
  addDprTreatment,
  getDprTreatments,
  addDprExtraEuTransfer,
  deleteDpController,
  getDpControllers,
} from "../api";

import { useStyles } from "../styles/Container.style";


function DprContainer ({
  treatment,
  handleChange,
  handleBack = null,
  handleNext,
  activeStep,
  handle = true,
  processingExtraEu,
  processingControllers
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [extraEu,setExtraEu] = useState([]);
  const [controllersArr,setControllersArr] = useState([]);
  const [processingId,setProcessingId] = useState(treatment.id);

  const getFunction = (step) => handleBack(step);
  const handleExtraEu = (data) => setExtraEu([...extraEu,data]);
  const handleControllers = (data) => setControllersArr([...controllersArr,data]);

  const handleDelExtraEu = (data) => {
    const newExtraEu = extraEu.filter((obj) => obj !== data);
    setExtraEu(newExtraEu);
  };
  const handleDelControllers = (data) => {
    const newControllersArr = controllersArr.filter((obj) => obj !== data);
    setControllersArr(newControllersArr);
  };
  const handleDeleteExtraEu = (euTransfer) => {
    deleteDprExtraEuTransfer(euTransfer.id,treatment.id,treatment.registry_id)
      .then((data) => {
        getDprExtraEuTransfers(treatment.id,treatment.registry_id)
          .then((data) => dispatch(setExtraEuTransfer(data)))
          .catch((err) => setError(err));
      })
      .catch((err) => setError(err));
  };
  const handleDeleteControllers = (controller, data_processor) => {
    deleteDpController(data_processor.id,controller.id)
      .then((data) => {
        getDpControllers(data_processor.id)
          .then((data) => dispatch(setControllers(data)))
          .catch((err) => setError(err));
      })
      .catch((err) => setError(err));
  };
  const saveTreatment = () => {
    addDprTreatment(treatment.registry_id,treatment)
      .then((data) => {
        setProcessingId(data.id);

        history.goBack();
        getDprTreatments(treatment.registry_id)
          .then((data1) => dispatch(setTreatmentsList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
  };

  function getStepContent (stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <InfoStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
          />
        );
      case 1:
        return (
          <PurposesStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
          />
        );
      case 2:
        return (
          <ProcessingStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
          />
        );
      case 3:
        return (
          <TransfersStep
            handle={handle}
            extraEu={extraEu}
            handleChange={handleChange}
            handleDeleteExtraEu={handleDeleteExtraEu}
            handleExtraEu={handleExtraEu}
            delExtraEu={handleDelExtraEu}
            value={treatment}
            processing={processingExtraEu}
          />
        );
      case 4:
        return (
          <StorageStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
          />
        );
      case 5:
        return (
          <ControllersStep
            handle={handle}
            extraEu={controllersArr}
            handleChange={handleChange}
            handleDeleteExtraEu={handleDeleteControllers}
            handleExtraEu={handleControllers}
            delExtraEu={handleDelControllers}
            value={treatment}
            processing={processingControllers}
          />
        );
      case 6:
        return (
          <MeasuresStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
          />
        );
      case 7:
        return (
          <PreviewStep
            handle={handle}
            handleChange={handleChange}
            value={treatment}
            extraEuTransfers={extraEu}
          />
        );
      default:
        return t("Unknown stepIndex");
    }
  }

  useEffect(() => {
    return () => {
      extraEu.map((value) => {
        addDprExtraEuTransfer(processingId,treatment.registry_id,value).catch(
          (err) => dispatch(setError(err))
        );
      });
    };
  },[dispatch,extraEu,processingId,treatment]);

  return (
    <div className={classes.container}>
      {getStepContent(activeStep)}

      {!!handleBack && (
        <div className={classes.inner}>
          <CustomButton
            name="NO"
            value="back"
            onClick={() => getFunction(activeStep)}
            isIcon={true}
          />

          {activeStep < 7 && (
            <CustomButton name="YES" onClick={handleNext} isIcon={true} />
          )}

          {activeStep === 7 && (
            <CustomButton name="SAVE" onClick={saveTreatment} />
          )}
        </div>
      )}
    </div>
  );
}

const mapStateProps = (state) => ({
  processingExtraEu: state.processingState.extraEuTransfer,
  processingControllers: state.processingState.controllers,

});

export default connect(mapStateProps,{})(DprContainer);
