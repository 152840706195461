import React,{ useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../components/Input";
import CustomButton from "../components/CustomButton";
import HomeHeader from "../components/HomeHeader";
import { history } from "../store";
import { connect } from "react-redux";
import { addDataController,getDataControllers } from "../api";
import { useDispatch } from "react-redux";
import { setDataControllerList,setError } from "../actions";
import Modals from "../components/modals/Modals";
import DpoModal from "../components/modals/DpoModal";
import SelectOption from "../components/SelectOption";
import useWindowSize from "../utils";

import { useStyles } from "../styles/NewDataController.style";
import { useStylesGlobal } from "../styles/Global.style";

const initState = {
  name: "",
  email: "",
  street_name: "",
  street_number: "",
  city: "",
  postal_code: "",
  country: "",
  vat_number: "",
  phone_number: "",
};

function NewRegistry () {
  const classes = useStyles();
  const { t } = useTranslation();
  const classesGlobal = useStylesGlobal();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const [openCancel,setOpenCancel] = useState(false);
  const [openConfirm,setOpenConfirm] = useState(false);
  const [openNewProtectionOfficer,setOpenNewProtectionOfficer] = useState(false);
  const [registry,setRegistry] = useState(initState);

  const handleCancelModal = () => setOpenCancel(!openCancel);

  const handleNewProtectionOfficerModal = () => {
    setOpenNewProtectionOfficer(!openNewProtectionOfficer);
    history.push("/dcr/data-controller");
  }

  const handleConfirmModal = () => {
    if (!openConfirm) {
      setOpenConfirm(!openConfirm);
    } else {
      addNewDataController()
      setOpenConfirm(!openConfirm);
    }
  }

  const handleConfirm = () => {
    setOpenConfirm(!openConfirm);
    addNewDataController(true)
    setOpenNewProtectionOfficer(true)
  }

  const handleChange = (prop) => (event) =>
    setRegistry({ ...registry,[prop]: event.target.value });

  const addNewDataController = (confirm = false) => {
    addDataController(registry)
      .then((data) => {
        if (!confirm) {
          history.push("/dcr/data-controller");
        }
        getDataControllers()
          .then((data1) => dispatch(setDataControllerList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
  };

  return (
    <div className={classesGlobal.container}>
      <HomeHeader value="New Data Controller" />

      {openCancel &&
        <Modals value="cancel" open={openCancel} handleClose={handleCancelModal} />
      }
      {openConfirm &&
        <Modals value="confirm" open={openConfirm} handleConfirm={handleConfirm} handleClose={handleConfirmModal} />
      }

      {openNewProtectionOfficer &&
        <DpoModal open={openNewProtectionOfficer} handleClose={handleNewProtectionOfficerModal} />
      }

      <div className={classes.inner}>
        <div className={width > 500 ? classes.input : classes.inner}>
          <CustomButton name="SAVE" onClick={handleConfirmModal} />
          <CustomButton name="CANCEL" value="back" onClick={handleCancelModal} />
        </div>

        <div className={width > 500 ? classes.input : classes.inner}>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={registry.name}
            handle={handleChange("name")}
            required
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={registry.email}
            handle={handleChange("email")}
          />
        </div>
        <div className={width > 500 ? classes.input : classes.inner}>
          <Input
            field="street_name"
            name={`${t("STREET NAME")}`}
            value={registry.street_name}
            handle={handleChange("street_name")}
          />
          <Input
            field="street_number"
            name={`${t("STREET NUMBER")}`}
            value={registry.street_number}
            handle={handleChange("street_number")}
          />
        </div>
        <div className={width > 500 ? classes.input : classes.inner}>
          <Input
            field="city"
            name={`${t("CITY")}`}
            value={registry.city}
            handle={handleChange("city")}
          />
          <Input
            field="postal_code"
            name={`${t("POSTAL CODE")}`}
            value={registry.postal_code}
            handle={handleChange("postal_code")}
          />
        </div>
        <div className={width > 500 ? classes.input : classes.inner}>
          <SelectOption
            field="country"
            value="country"

            label={`${t("COUNTRY")}`}
            select={registry.country}
            handle={handleChange("country")}
          />
          <Input
            field="vat_number"
            name={`${t("VAT NUMBER")}/${t("FISCAL CODE")}`}
            value={registry.vat_number}
            handle={handleChange("vat_number")}
          />
        </div>
        <div className={classes.input}>
          <Input
            field="phone_number"
            name={`${t("PHONE NUMBER")}`}
            value={registry.phone_number}
            handle={handleChange("phone_number")}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateProps = (state) => ({});

export default connect(mapStateProps,{})(NewRegistry);
