import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectOption from "../SelectOption";
import Grid from "@material-ui/core/Grid";

import { useStylesGlobal } from "../../styles/Global.style";

function PurposesStep({ handle, value, handleChange }) {
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && <h2>{t("Purposes and Legal Basis")}</h2>}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangePanel("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded === "panel1"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Purpose")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="processing_purposes"
                value="processing_purposes"
                label={`* ${t("PROCESSING PURPOSES")}`}
                select={value.processing_purposes}
                handle={handleChange("processing_purposes")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_processing_purposes"
                name="OTHER PROCESSING PURPOSES"
                value={value.other_processing_purposes}
                handle={handleChange("other_processing_purposes")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangePanel("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={
            expanded === "panel2"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Data Subject")}</h1>
        </AccordionSummary>
        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="data_subjects"
                value="data_subjects"
                label={`* ${t("DATA SUBJECT")}`}
                select={value.data_subjects}
                handle={handleChange("data_subjects")}
              />{" "}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                field="other_data_subjects"
                name="OTHER DATA SUBJECTS"
                value={value.other_data_subjects}
                handle={handleChange("other_data_subjects")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChangePanel("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded === "panel3"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Data Type")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="basic_data_types"
                value="basic_data_types"
                label={`* ${t("DATA TYPE")}`}
                select={value.basic_data_types}
                handle={handleChange("basic_data_types")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="special_data_categories"
                value="special_data_categories"
                label={`* ${t("SPECIAL DATA CATEGORIES")}`}
                select={value.special_data_categories}
                handle={handleChange("special_data_categories")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_data_types"
                name="OTHER DATA TYPE"
                value={value.other_data_types}
                handle={handleChange("other_data_types")}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Input
                field="data_collection_description"
                name="DATA COLLECTION DESCRIPTION"
                value={value.data_collection_description}
                handle={handleChange("data_collection_description")}
              />
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChangePanel("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          style={
            expanded === "panel4"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Legal Basis")}</h1>
        </AccordionSummary>
        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="art6_gdpr_base"
                value="art6_gdpr_base"
                label={`* ${t("ART. 6 GDPR BASE")}`}
                select={value.art6_gdpr_base}
                handle={handleChange("art6_gdpr_base")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="art9_gdpr_base"
                value="art9_gdpr_base"
                label={`${t("ART. 9 GDPR BASE")}`}
                select={value.art9_gdpr_base}
                handle={handleChange("art9_gdpr_base")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_gdpr_base"
                name="OTHER GDPR BASE"
                value={value.other_gdpr_base}
                handle={handleChange("other_gdpr_base")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PurposesStep;
