const initialState = {
    dataControllerList: {results: []},
    selectedDataController: {},
    err: null,
}

const dataControllerState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA_CONTROLLER_LIST':
            return {
                ...state,
                dataControllerList: action.payload,
            }
        case 'RESET_DATA_CONTROLLER_LIST':
            return {
                ...state,
                dataControllerList: {},
            }
        case 'FAIL_DATA_CONTROLLER_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DATA_CONTROLLER':
            return {
                ...state,
                selectedDataController: action.payload,
            }
        case 'RESET_DATA_CONTROLLER':
            return {
                ...state,
                selectedDataController: {},
            }
        case 'FAIL_DATA_CONTROLLER':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dataControllerState
