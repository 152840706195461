import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    flexDirection: "column",
    marginTop: "40px",
    display: "none"
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  delete: {
    position: "absolute",
    color: "gray",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1 ),
    backgroundColor: "#efeff4",
  },
}));
