import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslation } from "react-i18next";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0px",
        display: "flex",
        float: "left",
        minHeight: "90%",
    },
    stepper: {
        cursor: "pointer"
    },
    stepIconRoot: {
        color: "#ffc000",
    },
    stepIconRootCompete: {
        color: "inherit",
    }
}));

function LeftStepBar({ steps, activeStep, completed, handleStep }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handle = (index) => {
        if (index < activeStep) {
            handleStep(index)
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical" style={{ backgroundColor: 'transparent' }}>
                {steps.map((label, index) => (
                    <Step key={t(label)} onClick={() => handle(index)} >
                        <StepLabel
                            className={classes.stepper}
                            completed={completed[index]}
                            StepIconProps={{
                                classes: {
                                    root: index < activeStep && !completed[index]
                                        ? classes.stepIconRoot
                                        : classes.stepIconRootCompete
                                }
                            }}
                        >{t(label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>

        </div>
    )
}

export default LeftStepBar;
