import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%!important",
    height: "100%",
    borderRadius: "20px",
    backgroundColor: "#E8E8E8",
    marginBottom: "15%",
  },
  rootMobile: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    backgroundColor: "#E8E8E8",
    marginBottom: "15%",
    marginLeft: "0px",
    marginRight: "0px",
  },
  table: {
    backgroundColor: "white",
    alignItems: "center",
  },
  modal: {
    position: "absolute",
    width: 400,
    border: "2px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: "#efeff4",
  },
  headerTab: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    height: "70px",
  },
  leftHeaderTab: {
    marginLeft: "20px",
    fontSize: "30px",
  },
  rightHeaderTab: {
    display: "flex",
    alignItems: "center",
    float: "right",
    marginLeft: "auto",
    marginRight: "20px",
  },
  menu: {
    width: "200px",
    "&:hover": { color: "#007a85" },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "#007a85",
      color: "white",
    },
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": { backgroundColor: "rgb(0,122,133, 0.3)" },
  },
}));
