"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "AuthApi", {
  enumerable: true,
  get: function get() {
    return _AuthApi.default;
  }
});
Object.defineProperty(exports, "CountryEnum", {
  enumerable: true,
  get: function get() {
    return _CountryEnum.default;
  }
});
Object.defineProperty(exports, "DataController", {
  enumerable: true,
  get: function get() {
    return _DataController.default;
  }
});
Object.defineProperty(exports, "DataControllerConcurrentStructure", {
  enumerable: true,
  get: function get() {
    return _DataControllerConcurrentStructure.default;
  }
});
Object.defineProperty(exports, "DataControllerExternalProcessor", {
  enumerable: true,
  get: function get() {
    return _DataControllerExternalProcessor.default;
  }
});
Object.defineProperty(exports, "DataControllerInternalReferenceStructure", {
  enumerable: true,
  get: function get() {
    return _DataControllerInternalReferenceStructure.default;
  }
});
Object.defineProperty(exports, "DataControllerProcessingActivity", {
  enumerable: true,
  get: function get() {
    return _DataControllerProcessingActivity.default;
  }
});
Object.defineProperty(exports, "DataControllerProcessingActivityExtraEUTransfer", {
  enumerable: true,
  get: function get() {
    return _DataControllerProcessingActivityExtraEUTransfer.default;
  }
});
Object.defineProperty(exports, "DataControllerRegistriesApi", {
  enumerable: true,
  get: function get() {
    return _DataControllerRegistriesApi.default;
  }
});
Object.defineProperty(exports, "DataControllerRegistry", {
  enumerable: true,
  get: function get() {
    return _DataControllerRegistry.default;
  }
});
Object.defineProperty(exports, "DataControllersApi", {
  enumerable: true,
  get: function get() {
    return _DataControllersApi.default;
  }
});
Object.defineProperty(exports, "DataProcessor", {
  enumerable: true,
  get: function get() {
    return _DataProcessor.default;
  }
});
Object.defineProperty(exports, "DataProcessorConcurrentStructure", {
  enumerable: true,
  get: function get() {
    return _DataProcessorConcurrentStructure.default;
  }
});
Object.defineProperty(exports, "DataProcessorController", {
  enumerable: true,
  get: function get() {
    return _DataProcessorController.default;
  }
});
Object.defineProperty(exports, "DataProcessorInternalReferenceStructure", {
  enumerable: true,
  get: function get() {
    return _DataProcessorInternalReferenceStructure.default;
  }
});
Object.defineProperty(exports, "DataProcessorProcessingActivity", {
  enumerable: true,
  get: function get() {
    return _DataProcessorProcessingActivity.default;
  }
});
Object.defineProperty(exports, "DataProcessorProcessingActivityExtraEUTransfer", {
  enumerable: true,
  get: function get() {
    return _DataProcessorProcessingActivityExtraEUTransfer.default;
  }
});
Object.defineProperty(exports, "DataProcessorRegistriesApi", {
  enumerable: true,
  get: function get() {
    return _DataProcessorRegistriesApi.default;
  }
});
Object.defineProperty(exports, "DataProcessorRegistry", {
  enumerable: true,
  get: function get() {
    return _DataProcessorRegistry.default;
  }
});
Object.defineProperty(exports, "DataProcessorsApi", {
  enumerable: true,
  get: function get() {
    return _DataProcessorsApi.default;
  }
});
Object.defineProperty(exports, "DataProtectionOfficer", {
  enumerable: true,
  get: function get() {
    return _DataProtectionOfficer.default;
  }
});
Object.defineProperty(exports, "DataProtectionOfficersApi", {
  enumerable: true,
  get: function get() {
    return _DataProtectionOfficersApi.default;
  }
});
Object.defineProperty(exports, "Login", {
  enumerable: true,
  get: function get() {
    return _Login.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerConcurrentStructureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerConcurrentStructureList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerExternalProcessorList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerExternalProcessorList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerInternalReferenceStructureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerInternalReferenceStructureList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerProcessingActivityExtraEUTransferList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerProcessingActivityExtraEUTransferList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerProcessingActivityList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerProcessingActivityList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataControllerRegistryList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataControllerRegistryList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorConcurrentStructureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorConcurrentStructureList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorControllerList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorControllerList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorInternalReferenceStructureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorInternalReferenceStructureList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorProcessingActivityExtraEUTransferList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorProcessingActivityExtraEUTransferList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorProcessingActivityList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorProcessingActivityList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProcessorRegistryList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProcessorRegistryList.default;
  }
});
Object.defineProperty(exports, "PaginatedDataProtectionOfficerList", {
  enumerable: true,
  get: function get() {
    return _PaginatedDataProtectionOfficerList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityAdvancedDataTypeList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityAdvancedDataTypeList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityAdvancedTypeList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityAdvancedTypeList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityArtNineJuridicalList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityArtNineJuridicalList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityArtSixJuridicalList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityArtSixJuridicalList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityBasicDataTypeList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityBasicDataTypeList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityBasicTypeList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityBasicTypeList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityDataSubjectList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityDataSubjectList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityDatawarehouseList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityDatawarehouseList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityEUTransferList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityEUTransferList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityOrganizationalMeasureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityOrganizationalMeasureList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityPurposeList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityPurposeList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityStorageLocationList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityStorageLocationList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityStorageSupportList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityStorageSupportList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityTechnicalMeasureList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityTechnicalMeasureList.default;
  }
});
Object.defineProperty(exports, "PaginatedProcessingActivityWarrantyList", {
  enumerable: true,
  get: function get() {
    return _PaginatedProcessingActivityWarrantyList.default;
  }
});
Object.defineProperty(exports, "PasswordChange", {
  enumerable: true,
  get: function get() {
    return _PasswordChange.default;
  }
});
Object.defineProperty(exports, "PasswordReset", {
  enumerable: true,
  get: function get() {
    return _PasswordReset.default;
  }
});
Object.defineProperty(exports, "PasswordResetConfirm", {
  enumerable: true,
  get: function get() {
    return _PasswordResetConfirm.default;
  }
});
Object.defineProperty(exports, "PatchedDataController", {
  enumerable: true,
  get: function get() {
    return _PatchedDataController.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerConcurrentStructure", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerConcurrentStructure.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerExternalProcessor", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerExternalProcessor.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerInternalReferenceStructure", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerInternalReferenceStructure.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerProcessingActivity", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerProcessingActivity.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerProcessingActivityExtraEUTransfer", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerProcessingActivityExtraEUTransfer.default;
  }
});
Object.defineProperty(exports, "PatchedDataControllerRegistry", {
  enumerable: true,
  get: function get() {
    return _PatchedDataControllerRegistry.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessor", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessor.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorConcurrentStructure", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorConcurrentStructure.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorController", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorController.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorInternalReferenceStructure", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorInternalReferenceStructure.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorProcessingActivity", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorProcessingActivity.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorProcessingActivityExtraEUTransfer", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorProcessingActivityExtraEUTransfer.default;
  }
});
Object.defineProperty(exports, "PatchedDataProcessorRegistry", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProcessorRegistry.default;
  }
});
Object.defineProperty(exports, "PatchedDataProtectionOfficer", {
  enumerable: true,
  get: function get() {
    return _PatchedDataProtectionOfficer.default;
  }
});
Object.defineProperty(exports, "PatchedUser", {
  enumerable: true,
  get: function get() {
    return _PatchedUser.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityAdvancedDataType", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityAdvancedDataType.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityAdvancedType", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityAdvancedType.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityArtNineJuridical", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityArtNineJuridical.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityArtSixJuridical", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityArtSixJuridical.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityBasicDataType", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityBasicDataType.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityBasicType", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityBasicType.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityDataSubject", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityDataSubject.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityDatawarehouse", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityDatawarehouse.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityEUTransfer", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityEUTransfer.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityOrganizationalMeasure", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityOrganizationalMeasure.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityPurpose", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityPurpose.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityStorageLocation", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityStorageLocation.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityStorageSupport", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityStorageSupport.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityTechnicalMeasure", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityTechnicalMeasure.default;
  }
});
Object.defineProperty(exports, "ProcessingActivityWarranty", {
  enumerable: true,
  get: function get() {
    return _ProcessingActivityWarranty.default;
  }
});
Object.defineProperty(exports, "ProcessingAdvancedDataTypeApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingAdvancedDataTypeApi.default;
  }
});
Object.defineProperty(exports, "ProcessingAdvancedTypeApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingAdvancedTypeApi.default;
  }
});
Object.defineProperty(exports, "ProcessingArtNineApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingArtNineApi.default;
  }
});
Object.defineProperty(exports, "ProcessingArtSixApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingArtSixApi.default;
  }
});
Object.defineProperty(exports, "ProcessingBasicDataTypeApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingBasicDataTypeApi.default;
  }
});
Object.defineProperty(exports, "ProcessingBasicTypeApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingBasicTypeApi.default;
  }
});
Object.defineProperty(exports, "ProcessingDataSubjectApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingDataSubjectApi.default;
  }
});
Object.defineProperty(exports, "ProcessingDataWarehouseApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingDataWarehouseApi.default;
  }
});
Object.defineProperty(exports, "ProcessingEuTransferApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingEuTransferApi.default;
  }
});
Object.defineProperty(exports, "ProcessingOrgMeasuresApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingOrgMeasuresApi.default;
  }
});
Object.defineProperty(exports, "ProcessingPurposeApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingPurposeApi.default;
  }
});
Object.defineProperty(exports, "ProcessingStorageLocationApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingStorageLocationApi.default;
  }
});
Object.defineProperty(exports, "ProcessingStorageSupportApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingStorageSupportApi.default;
  }
});
Object.defineProperty(exports, "ProcessingTecMeasuresApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingTecMeasuresApi.default;
  }
});
Object.defineProperty(exports, "ProcessingWarrantyApi", {
  enumerable: true,
  get: function get() {
    return _ProcessingWarrantyApi.default;
  }
});
Object.defineProperty(exports, "RestAuthDetail", {
  enumerable: true,
  get: function get() {
    return _RestAuthDetail.default;
  }
});
Object.defineProperty(exports, "RiskLevelEnum", {
  enumerable: true,
  get: function get() {
    return _RiskLevelEnum.default;
  }
});
Object.defineProperty(exports, "SchemaApi", {
  enumerable: true,
  get: function get() {
    return _SchemaApi.default;
  }
});
Object.defineProperty(exports, "SystemAdministratorEnum", {
  enumerable: true,
  get: function get() {
    return _SystemAdministratorEnum.default;
  }
});
Object.defineProperty(exports, "Token", {
  enumerable: true,
  get: function get() {
    return _Token.default;
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User.default;
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _CountryEnum = _interopRequireDefault(require("./model/CountryEnum"));
var _DataController = _interopRequireDefault(require("./model/DataController"));
var _DataControllerConcurrentStructure = _interopRequireDefault(require("./model/DataControllerConcurrentStructure"));
var _DataControllerExternalProcessor = _interopRequireDefault(require("./model/DataControllerExternalProcessor"));
var _DataControllerInternalReferenceStructure = _interopRequireDefault(require("./model/DataControllerInternalReferenceStructure"));
var _DataControllerProcessingActivity = _interopRequireDefault(require("./model/DataControllerProcessingActivity"));
var _DataControllerProcessingActivityExtraEUTransfer = _interopRequireDefault(require("./model/DataControllerProcessingActivityExtraEUTransfer"));
var _DataControllerRegistry = _interopRequireDefault(require("./model/DataControllerRegistry"));
var _DataProcessor = _interopRequireDefault(require("./model/DataProcessor"));
var _DataProcessorConcurrentStructure = _interopRequireDefault(require("./model/DataProcessorConcurrentStructure"));
var _DataProcessorController = _interopRequireDefault(require("./model/DataProcessorController"));
var _DataProcessorInternalReferenceStructure = _interopRequireDefault(require("./model/DataProcessorInternalReferenceStructure"));
var _DataProcessorProcessingActivity = _interopRequireDefault(require("./model/DataProcessorProcessingActivity"));
var _DataProcessorProcessingActivityExtraEUTransfer = _interopRequireDefault(require("./model/DataProcessorProcessingActivityExtraEUTransfer"));
var _DataProcessorRegistry = _interopRequireDefault(require("./model/DataProcessorRegistry"));
var _DataProtectionOfficer = _interopRequireDefault(require("./model/DataProtectionOfficer"));
var _Login = _interopRequireDefault(require("./model/Login"));
var _PaginatedDataControllerConcurrentStructureList = _interopRequireDefault(require("./model/PaginatedDataControllerConcurrentStructureList"));
var _PaginatedDataControllerExternalProcessorList = _interopRequireDefault(require("./model/PaginatedDataControllerExternalProcessorList"));
var _PaginatedDataControllerInternalReferenceStructureList = _interopRequireDefault(require("./model/PaginatedDataControllerInternalReferenceStructureList"));
var _PaginatedDataControllerList = _interopRequireDefault(require("./model/PaginatedDataControllerList"));
var _PaginatedDataControllerProcessingActivityExtraEUTransferList = _interopRequireDefault(require("./model/PaginatedDataControllerProcessingActivityExtraEUTransferList"));
var _PaginatedDataControllerProcessingActivityList = _interopRequireDefault(require("./model/PaginatedDataControllerProcessingActivityList"));
var _PaginatedDataControllerRegistryList = _interopRequireDefault(require("./model/PaginatedDataControllerRegistryList"));
var _PaginatedDataProcessorConcurrentStructureList = _interopRequireDefault(require("./model/PaginatedDataProcessorConcurrentStructureList"));
var _PaginatedDataProcessorControllerList = _interopRequireDefault(require("./model/PaginatedDataProcessorControllerList"));
var _PaginatedDataProcessorInternalReferenceStructureList = _interopRequireDefault(require("./model/PaginatedDataProcessorInternalReferenceStructureList"));
var _PaginatedDataProcessorList = _interopRequireDefault(require("./model/PaginatedDataProcessorList"));
var _PaginatedDataProcessorProcessingActivityExtraEUTransferList = _interopRequireDefault(require("./model/PaginatedDataProcessorProcessingActivityExtraEUTransferList"));
var _PaginatedDataProcessorProcessingActivityList = _interopRequireDefault(require("./model/PaginatedDataProcessorProcessingActivityList"));
var _PaginatedDataProcessorRegistryList = _interopRequireDefault(require("./model/PaginatedDataProcessorRegistryList"));
var _PaginatedDataProtectionOfficerList = _interopRequireDefault(require("./model/PaginatedDataProtectionOfficerList"));
var _PaginatedProcessingActivityAdvancedDataTypeList = _interopRequireDefault(require("./model/PaginatedProcessingActivityAdvancedDataTypeList"));
var _PaginatedProcessingActivityAdvancedTypeList = _interopRequireDefault(require("./model/PaginatedProcessingActivityAdvancedTypeList"));
var _PaginatedProcessingActivityArtNineJuridicalList = _interopRequireDefault(require("./model/PaginatedProcessingActivityArtNineJuridicalList"));
var _PaginatedProcessingActivityArtSixJuridicalList = _interopRequireDefault(require("./model/PaginatedProcessingActivityArtSixJuridicalList"));
var _PaginatedProcessingActivityBasicDataTypeList = _interopRequireDefault(require("./model/PaginatedProcessingActivityBasicDataTypeList"));
var _PaginatedProcessingActivityBasicTypeList = _interopRequireDefault(require("./model/PaginatedProcessingActivityBasicTypeList"));
var _PaginatedProcessingActivityDataSubjectList = _interopRequireDefault(require("./model/PaginatedProcessingActivityDataSubjectList"));
var _PaginatedProcessingActivityDatawarehouseList = _interopRequireDefault(require("./model/PaginatedProcessingActivityDatawarehouseList"));
var _PaginatedProcessingActivityEUTransferList = _interopRequireDefault(require("./model/PaginatedProcessingActivityEUTransferList"));
var _PaginatedProcessingActivityOrganizationalMeasureList = _interopRequireDefault(require("./model/PaginatedProcessingActivityOrganizationalMeasureList"));
var _PaginatedProcessingActivityPurposeList = _interopRequireDefault(require("./model/PaginatedProcessingActivityPurposeList"));
var _PaginatedProcessingActivityStorageLocationList = _interopRequireDefault(require("./model/PaginatedProcessingActivityStorageLocationList"));
var _PaginatedProcessingActivityStorageSupportList = _interopRequireDefault(require("./model/PaginatedProcessingActivityStorageSupportList"));
var _PaginatedProcessingActivityTechnicalMeasureList = _interopRequireDefault(require("./model/PaginatedProcessingActivityTechnicalMeasureList"));
var _PaginatedProcessingActivityWarrantyList = _interopRequireDefault(require("./model/PaginatedProcessingActivityWarrantyList"));
var _PasswordChange = _interopRequireDefault(require("./model/PasswordChange"));
var _PasswordReset = _interopRequireDefault(require("./model/PasswordReset"));
var _PasswordResetConfirm = _interopRequireDefault(require("./model/PasswordResetConfirm"));
var _PatchedDataController = _interopRequireDefault(require("./model/PatchedDataController"));
var _PatchedDataControllerConcurrentStructure = _interopRequireDefault(require("./model/PatchedDataControllerConcurrentStructure"));
var _PatchedDataControllerExternalProcessor = _interopRequireDefault(require("./model/PatchedDataControllerExternalProcessor"));
var _PatchedDataControllerInternalReferenceStructure = _interopRequireDefault(require("./model/PatchedDataControllerInternalReferenceStructure"));
var _PatchedDataControllerProcessingActivity = _interopRequireDefault(require("./model/PatchedDataControllerProcessingActivity"));
var _PatchedDataControllerProcessingActivityExtraEUTransfer = _interopRequireDefault(require("./model/PatchedDataControllerProcessingActivityExtraEUTransfer"));
var _PatchedDataControllerRegistry = _interopRequireDefault(require("./model/PatchedDataControllerRegistry"));
var _PatchedDataProcessor = _interopRequireDefault(require("./model/PatchedDataProcessor"));
var _PatchedDataProcessorConcurrentStructure = _interopRequireDefault(require("./model/PatchedDataProcessorConcurrentStructure"));
var _PatchedDataProcessorController = _interopRequireDefault(require("./model/PatchedDataProcessorController"));
var _PatchedDataProcessorInternalReferenceStructure = _interopRequireDefault(require("./model/PatchedDataProcessorInternalReferenceStructure"));
var _PatchedDataProcessorProcessingActivity = _interopRequireDefault(require("./model/PatchedDataProcessorProcessingActivity"));
var _PatchedDataProcessorProcessingActivityExtraEUTransfer = _interopRequireDefault(require("./model/PatchedDataProcessorProcessingActivityExtraEUTransfer"));
var _PatchedDataProcessorRegistry = _interopRequireDefault(require("./model/PatchedDataProcessorRegistry"));
var _PatchedDataProtectionOfficer = _interopRequireDefault(require("./model/PatchedDataProtectionOfficer"));
var _PatchedUser = _interopRequireDefault(require("./model/PatchedUser"));
var _ProcessingActivityAdvancedDataType = _interopRequireDefault(require("./model/ProcessingActivityAdvancedDataType"));
var _ProcessingActivityAdvancedType = _interopRequireDefault(require("./model/ProcessingActivityAdvancedType"));
var _ProcessingActivityArtNineJuridical = _interopRequireDefault(require("./model/ProcessingActivityArtNineJuridical"));
var _ProcessingActivityArtSixJuridical = _interopRequireDefault(require("./model/ProcessingActivityArtSixJuridical"));
var _ProcessingActivityBasicDataType = _interopRequireDefault(require("./model/ProcessingActivityBasicDataType"));
var _ProcessingActivityBasicType = _interopRequireDefault(require("./model/ProcessingActivityBasicType"));
var _ProcessingActivityDataSubject = _interopRequireDefault(require("./model/ProcessingActivityDataSubject"));
var _ProcessingActivityDatawarehouse = _interopRequireDefault(require("./model/ProcessingActivityDatawarehouse"));
var _ProcessingActivityEUTransfer = _interopRequireDefault(require("./model/ProcessingActivityEUTransfer"));
var _ProcessingActivityOrganizationalMeasure = _interopRequireDefault(require("./model/ProcessingActivityOrganizationalMeasure"));
var _ProcessingActivityPurpose = _interopRequireDefault(require("./model/ProcessingActivityPurpose"));
var _ProcessingActivityStorageLocation = _interopRequireDefault(require("./model/ProcessingActivityStorageLocation"));
var _ProcessingActivityStorageSupport = _interopRequireDefault(require("./model/ProcessingActivityStorageSupport"));
var _ProcessingActivityTechnicalMeasure = _interopRequireDefault(require("./model/ProcessingActivityTechnicalMeasure"));
var _ProcessingActivityWarranty = _interopRequireDefault(require("./model/ProcessingActivityWarranty"));
var _RestAuthDetail = _interopRequireDefault(require("./model/RestAuthDetail"));
var _RiskLevelEnum = _interopRequireDefault(require("./model/RiskLevelEnum"));
var _SystemAdministratorEnum = _interopRequireDefault(require("./model/SystemAdministratorEnum"));
var _Token = _interopRequireDefault(require("./model/Token"));
var _User = _interopRequireDefault(require("./model/User"));
var _AuthApi = _interopRequireDefault(require("./api/AuthApi"));
var _DataControllerRegistriesApi = _interopRequireDefault(require("./api/DataControllerRegistriesApi"));
var _DataControllersApi = _interopRequireDefault(require("./api/DataControllersApi"));
var _DataProcessorRegistriesApi = _interopRequireDefault(require("./api/DataProcessorRegistriesApi"));
var _DataProcessorsApi = _interopRequireDefault(require("./api/DataProcessorsApi"));
var _DataProtectionOfficersApi = _interopRequireDefault(require("./api/DataProtectionOfficersApi"));
var _ProcessingAdvancedDataTypeApi = _interopRequireDefault(require("./api/ProcessingAdvancedDataTypeApi"));
var _ProcessingAdvancedTypeApi = _interopRequireDefault(require("./api/ProcessingAdvancedTypeApi"));
var _ProcessingArtNineApi = _interopRequireDefault(require("./api/ProcessingArtNineApi"));
var _ProcessingArtSixApi = _interopRequireDefault(require("./api/ProcessingArtSixApi"));
var _ProcessingBasicDataTypeApi = _interopRequireDefault(require("./api/ProcessingBasicDataTypeApi"));
var _ProcessingBasicTypeApi = _interopRequireDefault(require("./api/ProcessingBasicTypeApi"));
var _ProcessingDataSubjectApi = _interopRequireDefault(require("./api/ProcessingDataSubjectApi"));
var _ProcessingDataWarehouseApi = _interopRequireDefault(require("./api/ProcessingDataWarehouseApi"));
var _ProcessingEuTransferApi = _interopRequireDefault(require("./api/ProcessingEuTransferApi"));
var _ProcessingOrgMeasuresApi = _interopRequireDefault(require("./api/ProcessingOrgMeasuresApi"));
var _ProcessingPurposeApi = _interopRequireDefault(require("./api/ProcessingPurposeApi"));
var _ProcessingStorageLocationApi = _interopRequireDefault(require("./api/ProcessingStorageLocationApi"));
var _ProcessingStorageSupportApi = _interopRequireDefault(require("./api/ProcessingStorageSupportApi"));
var _ProcessingTecMeasuresApi = _interopRequireDefault(require("./api/ProcessingTecMeasuresApi"));
var _ProcessingWarrantyApi = _interopRequireDefault(require("./api/ProcessingWarrantyApi"));
var _SchemaApi = _interopRequireDefault(require("./api/SchemaApi"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }