import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: "10px",
    padding: "10px",
  },
  selectEmpty: {
    color: theme.palette.white.main,
    "&:before": { borderColor: "white" },
    "&:after": { borderColor: "white" },
  },
  icon: {
    fill: "white",
  },
}));
