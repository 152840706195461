export const setDataControllerList = (payload) => {
    return {
        type: 'SET_DATA_CONTROLLER_LIST',
        payload: payload,
    }
}

export const resetDataControllerList = () => {
    return {
        type: 'RESET_DATA_CONTROLLER_LIST',
    }
}

export const failUploadDataControllerList = (err) => {
    return {
        type: 'FAIL_DATA_CONTROLLER_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDataController = (payload) => {
    return {
        type: 'SET_DATA_CONTROLLER',
        payload: payload,
    }
}

export const resetDataController = () => {
    return {
        type: 'RESET_DATA_CONTROLLER',
    }
}

export const failUploadDataController = (err) => {
    return {
        type: 'FAIL_DATA_CONTROLLER',
        payload: {
            err: err,
        },
    }
}