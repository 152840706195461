export const getProcessingAdvancedDataType = (payload) => {
  return {
    type: "SET_PROCESSING_ADVANCED_DATA_TYPE",
    payload: payload,
  };
};

export const resetProcessingAdvancedDataType = () => {
  return {
    type: "RESET_PROCESSING_ADVANCED_DATA_TYPE",
  };
};

export const failProcessingAdvancedDataType = (err) => {
  return {
    type: "FAIL_PROCESSING_ADVANCED_DATA_TYPE",
    payload: {
      err: err,
    },
  };
};
export const getProcessingAdvancedType = (payload) => {
  return {
    type: "SET_PROCESSING_ADVANCED_TYPE",
    payload: payload,
  };
};

export const resetProcessingAdvancedType = () => {
  return {
    type: "RESET_PROCESSING_ADVANCED_TYPE",
  };
};

export const failProcessingAdvancedType = (err) => {
  return {
    type: "FAIL_PROCESSING_ADVANCED_TYPE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingDataType = (payload) => {
  return {
    type: "SET_PROCESSING_DATA_TYPE",
    payload: payload,
  };
};

export const resetProcessingDataType = () => {
  return {
    type: "RESET_PROCESSING_DATA_TYPE",
  };
};

export const failProcessingDataType = (err) => {
  return {
    type: "FAIL_PROCESSING_DATA_TYPE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingArtSix = (payload) => {
  return {
    type: "SET_PROCESSING_ART_SIX",
    payload: payload,
  };
};

export const resetProcessingArtSix = () => {
  return {
    type: "RESET_PROCESSING_ART_SIX",
  };
};

export const failProcessingArtSix = (err) => {
  return {
    type: "FAIL_PROCESSING_ART_SIX",
    payload: {
      err: err,
    },
  };
};
export const getProcessingArtNine = (payload) => {
  return {
    type: "SET_PROCESSING_ART_NINE",
    payload: payload,
  };
};

export const resetProcessingArtNine = () => {
  return {
    type: "RESET_PROCESSING_ART_NINE",
  };
};

export const failProcessingArtNine = (err) => {
  return {
    type: "FAIL_PROCESSING_ART_NINE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingBasicDataType = (payload) => {
  return {
    type: "SET_PROCESSING_BASIC_DATA_TYPE",
    payload: payload,
  };
};

export const resetProcessingBasicDataType = () => {
  return {
    type: "RESET_PROCESSING_BASIC_DATA_TYPE",
  };
};

export const failProcessingBasicDataType = (err) => {
  return {
    type: "FAIL_PROCESSING_BASIC_DATA_TYPE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingBasicType = (payload) => {
  return {
    type: "SET_PROCESSING_BASIC_TYPE",
    payload: payload,
  };
};

export const resetProcessingBasicType = () => {
  return {
    type: "RESET_PROCESSING_BASIC_TYPE",
  };
};

export const failProcessingBasicType = (err) => {
  return {
    type: "FAIL_PROCESSING_BASIC_TYPE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingDataSubject = (payload) => {
  return {
    type: "SET_PROCESSING_DATA_SUBJECT",
    payload: payload,
  };
};

export const resetProcessingDataSubject = () => {
  return {
    type: "RESET_PROCESSING_DATA_SUBJECT",
  };
};

export const failProcessingDataSubject = (err) => {
  return {
    type: "FAIL_PROCESSING_DATA_SUBJECT",
    payload: {
      err: err,
    },
  };
};

export const getProcessingDataWarehouse = (payload) => {
  return {
    type: "SET_PROCESSING_DATA_WAREHOUSE",
    payload: payload,
  };
};

export const resetProcessingDataWarehouse = () => {
  return {
    type: "RESET_PROCESSING_DATA_WAREHOUSE",
  };
};

export const failProcessingDataWarehouse = (err) => {
  return {
    type: "FAIL_PROCESSING_DATA_WAREHOUSE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingEuTransfer = (payload) => {
  return {
    type: "SET_PROCESSING_EU_TRANSFER",
    payload: payload,
  };
};

export const resetProcessingEuTransfer = () => {
  return {
    type: "RESET_PROCESSING_EU_TRANSFER",
  };
};

export const failProcessingEuTransfer = (err) => {
  return {
    type: "FAIL_PROCESSING_EU_TRANSFER",
    payload: {
      err: err,
    },
  };
};

export const getProcessingOrgMeasures = (payload) => {
  return {
    type: "SET_PROCESSING_ORG_MEASURES",
    payload: payload,
  };
};

export const resetProcessingOrgMeasures = () => {
  return {
    type: "RESET_PROCESSING_ORG_MEASURES",
  };
};

export const failProcessingOrgMeasures = (err) => {
  return {
    type: "FAIL_PROCESSING_ORG_MEASURES",
    payload: {
      err: err,
    },
  };
};

export const getProcessingPurpose = (payload) => {
  return {
    type: "SET_PROCESSING_PURPOSE",
    payload: payload,
  };
};

export const resetProcessingPurpose = () => {
  return {
    type: "RESET_PROCESSING_PURPOSE",
  };
};

export const failProcessingPurpose = (err) => {
  return {
    type: "FAIL_PROCESSING_PURPOSE",
    payload: {
      err: err,
    },
  };
};

export const getProcessingStorageLocation = (payload) => {
  return {
    type: "SET_PROCESSING_STORAGE_LOCATION",
    payload: payload,
  };
};

export const resetProcessingStorageLocation = () => {
  return {
    type: "RESET_PROCESSING_STORAGE_LOCATION",
  };
};

export const failProcessingStorageLocation = (err) => {
  return {
    type: "FAIL_PROCESSING_STORAGE_LOCATION",
    payload: {
      err: err,
    },
  };
};

// export const getProcessingStorageMethod = (payload) => {
//   return {
//     type: "SET_PROCESSING_STORAGE_METHOD",
//     payload: payload,
//   };
// };

// export const resetProcessingStorageMethod = () => {
//   return {
//     type: "RESET_PROCESSING_STORAGE_METHOD",
//   };
// };

// export const failProcessingStorageMethod = (err) => {
//   return {
//     type: "FAIL_PROCESSING_STORAGE_METHOD",
//     payload: {
//       err: err,
//     },
//   };
// };

export const getProcessingStorageSupport = (payload) => {
  return {
    type: "SET_PROCESSING_STORAGE_SUPPORT",
    payload: payload,
  };
};

export const resetProcessingStorageSupport = () => {
  return {
    type: "RESET_PROCESSING_STORAGE_SUPPORT",
  };
};

export const failProcessingStorageSupport = (err) => {
  return {
    type: "FAIL_PROCESSING_STORAGE_SUPPORT",
    payload: {
      err: err,
    },
  };
};

export const getProcessingTecMeasures = (payload) => {
  return {
    type: "SET_PROCESSING_TEC_MEASURES",
    payload: payload,
  };
};

export const resetProcessingTecMeasures = () => {
  return {
    type: "RESET_PROCESSING_TEC_MEASURES",
  };
};

export const failProcessingTecMeasures = (err) => {
  return {
    type: "FAIL_PROCESSING_TEC_MEASURES",
    payload: {
      err: err,
    },
  };
};

export const getProcessingWarranty = (payload) => {
  return {
    type: "SET_PROCESSING_WARRANTY",
    payload: payload,
  };
};

export const resetProcessingWarranty = () => {
  return {
    type: "RESET_PROCESSING_WARRANTY",
  };
};

export const failProcessingWarranty = (err) => {
  return {
    type: "FAIL_PROCESSING_WARRANTY",
    payload: {
      err: err,
    },
  };
};

export const setConcurrentStructures = (payload) => {
  return {
    type: "SET_CONCURRENT_STRUCTURES",
    payload: payload,
  };
};

export const resetConcurrentStructures = () => {
  return {
    type: "RESET_CONCURRENT_STRUCTURES",
  };
};

export const setExternalProcessors = (payload) => {
  return {
    type: "SET_EXTERNAL_PROCESSORS",
    payload: payload,
  };
};

export const resetExternalProcessors = () => {
  return {
    type: "RESET_EXTERNAL_PROCESSORS",
  };
};

export const setControllers = (payload) => {
  return {
    type: "SET_CONTROLLERS",
    payload: payload,
  };
};

export const resetControllers = () => {
  return {
    type: "RESET_CONTROLLERS",
  };
};

export const setInternalStructures = (payload) => {
  return {
    type: "SET_INTERNAL_STRUCTURES",
    payload: payload,
  };
};

export const resetInternalStructures = () => {
  return {
    type: "RESET_INTERNAL_STRUCTURES",
  };
};

export const setExtraEuTransfer = (payload) => {
  return {
    type: "SET_EXTRA_EU_TRANSFER",
    payload: payload,
  };
};

export const resetExtraEuTransfer = () => {
  return {
    type: "RESET_EXTRA_EU_TRANSFER",
  };
};

export const setDataProtectionOfficers = (payload) => {
  return {
    type: "SET_DATA_PROTECTION_OFFICERS",
    payload: payload,
  };
};

export const resetDataProtectionOfficers = () => {
  return {
    type: "RESET_DATA_PROTECTION_OFFICERS",
  };
};
