import React from "react";
//import { useTranslation } from "react-i18next";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableBody from "@material-ui/core/TableBody";
import { history } from "../../store";
import { setDcrRegistry } from "../../actions";
import { useDispatch } from "react-redux";

import { useStyles } from "../../styles/TableDisplay.style";

function DcrProcessingRegistryBodyTab ({ row,value,handleClick }) {
  const classes = useStyles();
  //const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToRegistry = () => {
    dispatch(setDcrRegistry(row));
    history.push("/dcr/processing-registries/registry");
  };
  const getFullName = () => {
    let res = "";
    if (!!row.data_protection_officer_obj && !!row.data_protection_officer_obj.name)
      res += ` ${row.data_protection_officer_obj.name}`;
    if (!!row.data_protection_officer_obj && !!row.data_protection_officer_obj.family_name)
      res += ` ${row.data_protection_officer_obj.family_name}`;
    if (!!row.data_protection_officer_obj && !!row.data_protection_officer_obj.company_name) {
      if (res !== "") res += ",";
      res += ` ${row.data_protection_officer_obj.company_name}`;
    }
    return res
  };



  return (
    <TableBody key={row.id}>
      <TableRow className={classes.clickableRow} key={row.id}>
        <TableCell component="th" scope="row" onClick={() => goToRegistry()}>
          {row.data_controller_name}
        </TableCell>
        <TableCell align="right" onClick={() => goToRegistry()}>
          {row.name}
        </TableCell>
        <TableCell align="right" onClick={() => goToRegistry()}>
          {row.processing_count}
        </TableCell>
        <TableCell align="right" onClick={() => goToRegistry()}>
          {getFullName()}
        </TableCell>
        <TableCell align="right" style={{ width: "30px" }}>
          <IconButton
            color="inherit"
            className={classes.iconButton}
            onClick={(e) => handleClick(e,row,value)}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default DcrProcessingRegistryBodyTab;
