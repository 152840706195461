import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useStylesGlobal } from "../../styles/Global.style";
import { serializer } from "../../utils";

function PreviewDataController ({ value }) {

  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();
  let serializedObj = serializer("data_controller",value);
  //console.log(serializedObj);

  const getFields = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).replace(/_/g," ");
  };

  return (
    <div className={classesGlobal.stepContainer}>
      <h2
        className={classesGlobal.colorHeadingDark}
        style={{ marginBottom: "50px" }}
      >
        {t("Preview")}
      </h2>

      <Grid container xs={12} spacing={5}>
        {!!serializedObj &&
          Object.keys(serializedObj).map((k) => {
            if (k !== "created_at" && k !== "id" && k !== "updated_at") {
              return (
                <Grid item xs={4}>
                  <b
                  //   className={classesGlobal.colorHeadingLight}
                  >
                    {t(getFields(k).toUpperCase())}
                  </b>
                  <p>{serializedObj[k]}</p>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
}

const mapStateProps = (state) => ({
  dataControllerList: state.dataControllerState.dataControllerList,
  processingOptions: state.processingState,
  language: state.authState.language,
});

export default connect(mapStateProps,{})(PreviewDataController);
