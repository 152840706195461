const initialState = {
    dataProcessorList: { results: [] },
    selectedDataProcessor: {},
    err: null,
}

const dataProcessorState = (state = initialState,action) => {
    switch (action.type) {
        case 'SET_DATA_PROCESSOR_LIST':
            return {
                ...state,
                dataProcessorList: action.payload,
            }
        case 'RESET_DATA_CONTROLLER_LIST':
            return {
                ...state,
                dataProcessorList: {},
            }
        case 'FAIL_DATA_PROCESSOR_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DATA_PROCESSOR':
            return {
                ...state,
                selectedDataProcessor: action.payload,
            }
        case 'RESET_DATA_PROCESSOR':
            return {
                ...state,
                selectedDataProcessor: {},
            }
        case 'FAIL_DATA_PROCESSOR':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dataProcessorState
