import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import CustomButton from "../CustomButton";
//import SelectOption from "../SelectOption";

import { useStyles } from "../../styles/Modals.style";
import { history } from "../../store";
import SelectOption from "../SelectOption";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function NewOptionModal({
  data,
  handleClose,
  handleChange,
  handleConfirmModal,
  isProtectionOfficers = false,
  isProcessor = false, // TODO: Unused, so remove it? - Alle
  value = null,
  name = null
}) {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <div className={classes.delete}>
      <div style={{ textAlign: "center" }}>
        {/*
        {isProtectionOfficers ? (
          <p>{t("Insert new Data Protection Officer")}</p>
        ) : (
          <p>{t("Insert new option")}</p>
        )}
        */}

        {/* TODO: Unused, so remove it? - Alle */}
        {/*
        {isProtectionOfficers &&
          <p>{t("Insert new Data Protection Officer")}</p>
        }
        {isProcessor &&
          <p>{t("Insert new Data Processor")}</p>
        }
        {(!isProtectionOfficers) && (!isProcessor) &&
          <p>{t("Insert new option")}</p>
        }
        */}
      </div>

      {/* {isProtectionOfficers && value !== "update" && (
        <SelectOption
          field="data_controller"
          value="data_controller"
          label={`* ${t("DATA CONTROLLER")}`}
          select={data.data_controller}
          handle={handleChange("data_controller")}
        />
      )} */}

      {/*
      {history.location.pathname === "/dpr/processing-registries/registry/processing" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="city"
            name={`* ${t("CITY")}`}
            value={data.city}
            handle={handleChange("city")}
          />
          <Input
            field="country"
            name={`* ${t("COUNTRY")}`}
            value={data.country}
            handle={handleChange("country")}
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={data.email}
            handle={handleChange("email")}
          />
          <Input
            field="extra_ue"
            name={`* ${t("EXTRA UE")}`}
            value={data.extra_ue}
            handle={handleChange("extra_ue")}
          />
          <Input
            field="juridical_person"
            name={`* ${t("JURIDICAL PERSON")}`}
            value={data.juridical_person}
            handle={handleChange("juridical_person")}
          />
          <Input
            field="phone_number"
            name={`* ${t("PHONE NUMBER")}`}
            value={data.phone_number}
            handle={handleChange("phone_number")}
          />
          <Input
            field="postal_code"
            name={`* ${t("POSTAL CODE")}`}
            value={data.postal_code}
            handle={handleChange("postal_code")}
          />
          <Input
            field="street_name"
            name={`* ${t("STREET NAME")}`}
            value={data.street_name}
            handle={handleChange("street_name")}
          />
          <Input
            field="street_number"
            name={`* ${t("STREET NUMBER")}`}
            value={data.street_number}
            handle={handleChange("street_number")}
          />
          <Input
            field="ue_representative"
            name={`* ${t("UE REPRESENTATIVE")}`}
            value={data.ue_representative}
            handle={handleChange("ue_representative")}
          />
        </>
      }
      */}

      {(history.location.pathname === "/dpr/processing-registries/registry/processing" ||
        history.location.pathname === "/dpr/processing-registries/add-new")
        && name === "controllers" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="city"
            name={`${t("CITY")}`}
            value={data.city}
            handle={handleChange("city")}
          />
          <SelectOption
            field="country"
            value="country"
            label={`${t("COUNTRY")}`}
            select={data.country}
            handle={handleChange("country")}
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={data.email}
            handle={handleChange("email")}
          />
          <div style={{ marginRight: "1.5rem", marginLeft: "1.5rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  field="extra_ue"
                  color="primary"
                  checked={data.extra_ue || false}
                  onChange={handleChange("extra_ue")}
                  name={`${t("EXTRA UE")}`}
                />
              }
              label={`${t("EXTRA UE")}`}
            />
          </div>
          <div style={{ marginRight: "1.5rem", marginLeft: "1.5rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  field="juridical_person"
                  color="primary"
                  checked={data.juridical_person || false}
                  onChange={handleChange("juridical_person")}
                  name={`${t("JURIDICAL PERSON")}`}
                />
              }
              label={`${t("JURIDICAL PERSON")}`}
            />
          </div>
          <Input
            field="phone_number"
            name={`${t("PHONE NUMBER")}`}
            value={data.phone_number}
            handle={handleChange("phone_number")}
          />
          <Input
            field="postal_code"
            name={`${t("POSTAL CODE")}`}
            value={data.postal_code}
            handle={handleChange("postal_code")}
          />
          <Input
            field="street_name"
            name={`${t("STREET NAME")}`}
            value={data.street_name}
            handle={handleChange("street_name")}
          />
          <Input
            field="street_number"
            name={`${t("STREET NUMBER")}`}
            value={data.street_number}
            handle={handleChange("street_number")}
          />
          {
            data.extra_ue === true &&
            <Input
              field="ue_representative"
              name={`${t("UE REPRESENTATIVE")}`}
              value={data.ue_representative}
              handle={handleChange("ue_representative")}
            />
          }
        </>
      }

      {(history.location.pathname === "/dcr/processing-registries/registry/processing" ||
        history.location.pathname === "/dpr/processing-registries/registry/processing") &&
        name === "internal_reference_structure" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="description"
            name={`${t("DESCRIPTION")}`}
            value={data.description}
            handle={handleChange("description")}
          />
        </>
      }


      {(history.location.pathname === "/dcr/processing-registries/registry/processing" ||
        history.location.pathname === "/dpr/processing-registries/registry/processing") &&
        name === "concurrent_structures" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="description"
            name={`${t("DESCRIPTION")}`}
            value={data.description}
            handle={handleChange("description")}
          />
        </>
      }


      {(history.location.pathname === "/dcr/processing-registries/add-new" ||
        history.location.pathname === "/dpr/processing-registries/add-new") &&
        name === "internal_reference_structure" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="description"
            name={`${t("DESCRIPTION")}`}
            value={data.description}
            handle={handleChange("description")}
          />
        </>
      }

      {(history.location.pathname === "/dcr/processing-registries/add-new" ||
        history.location.pathname === "/dpr/processing-registries/add-new") &&
        name === "concurrent_structures" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="description"
            name={`${t("DESCRIPTION")}`}
            value={data.description}
            handle={handleChange("description")}
          />
        </>
      }

      {history.location.pathname === "/dcr/processing-registries/add-new" &&
        name === "external_processors" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name}
            handle={handleChange("name")}
          />
          <Input
            field="description"
            name={`${t("DESCRIPTION")}`}
            value={data.description}
            handle={handleChange("description")}
          />
        </>
      }


      {//history.location.pathname === "/dpo" ||
        history.location.pathname === "/dpr/processing-registries" ||
        history.location.pathname === "/dcr/processing-registries" &&
        <>
          <Input
            field="name"
            name={`* ${t("NAME")}`}
            value={data.name || ""}
            handle={handleChange("name")}
          />
          <Input
            field="family_name"
            name={`${t("FAMILY NAME")}`}
            value={data.family_name}
            handle={handleChange("family_name")}
          />
          <Input
            field="company_name"
            name={`${t("COMPANY NAME")}`}
            value={data.company_name}
            handle={handleChange("company_name")}
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={data.email}
            handle={handleChange("email")}
          />
          <Input
            field="phone_number"
            name={`${t("PHONE NUMBER")}`}
            value={data.phone_number}
            handle={handleChange("phone_number")}
          />
        </>
      }


      {/* TMP ALLE TEST
      <Input
        field="name"
        name={`* ${t("NAME")}`}
        value={data.name}
        handle={handleChange("name")}
      />
      */}

      {/* TMP ALLE TEST
      {isProtectionOfficers ? (
        <>
          <Input
            field="family_name"
            name={`* ${t("FAMILY NAME")}`}
            value={data.family_name}
            handle={handleChange("family_name")}
          />
          <Input
            field="company_name"
            name={`* ${t("COMPANY NAME")}`}
            value={data.company_name}
            handle={handleChange("company_name")}
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={data.email}
            handle={handleChange("email")}
          />
          <Input
            field="phone_number"
            name={`* ${t("PHONE NUMBER")}`}
            value={data.phone_number}
            handle={handleChange("phone_number")}
          />
        </>
      ) : (
        <Input
          field="description"
          name={`* ${t("DESCRIPTION")}`}
          value={data.description}
          handle={handleChange("description")}
        />
      )}
      */}


      {/* TODO: Unused, so remove it? - Alle */}
      {/*
      {isProcessor ? (
        <>
          <Input
            field="family_name"
            name={`* ${t("FAMILY NAME")}`}
            value={data.family_name}
            handle={handleChange("family_name")}
          />
          <Input
            field="company_name"
            name={`* ${t("COMPANY NAME")}`}
            value={data.company_name}
            handle={handleChange("company_name")}
          />
          <Input
            field="email"
            name={`* ${t("EMAIL")}`}
            value={data.email}
            handle={handleChange("email")}
          />
          <Input
            field="phone_number"
            name={`* ${t("PHONE NUMBER")}`}
            value={data.phone_number}
            handle={handleChange("phone_number")}
          />
        </>
      ) : (
        <Input
          field="description"
          name={`* ${t("DESCRIPTION")}`}
          value={data.description}
          handle={handleChange("description")}
        />
      )}
      */}

      <div style={{ marginTop: "20px" }}>
        {!!value ? (
          <CustomButton name="UPDATE" onClick={handleConfirmModal} />
        ) : (
          <CustomButton name="CREATE" onClick={handleConfirmModal} />
        )}
        <CustomButton name="CANCEL" value="back" onClick={handleClose} />
      </div>
    </div>
  );
}

export default NewOptionModal;
