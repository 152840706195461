export const setDprTreatmentsList = (payload) => {
    return {
        type: 'SET_DPR_TREATMENT_LIST',
        payload: payload,
    }
}

export const resetDprTreatmentsList = () => {
    return {
        type: 'RESET_DPR_TREATMENT_LIST',
    }
}

export const failUploadDprTreatmentsList = (err) => {
    return {
        type: 'FAIL_DPR_TREATMENT_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDprTreatment = (payload) => {
    return {
        type: 'SET_DPR_TREATMENT',
        payload: payload,
    }
}

export const resetDprTreatment = () => {
    return {
        type: 'RESET_DPR_TREATMENT',
    }
}

export const failUploadDprTreatment = (err) => {
    return {
        type: 'FAIL_DPR_TREATMENT',
        payload: {
            err: err,
        },
    }
}