const initialState = {
    registryList: {results: []},
    selectedRegistry: {},
    err: null,
}

const registryState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_REGISTRY_LIST':
            return {
                ...state,
                registryList: action.payload,
            }
        case 'RESET_REGISTRY_LIST':
            return {
                ...state,
                registryList: {},
            }
        case 'FAIL_REGISTRY_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_REGISTRY':
            return {
                ...state,
                selectedRegistry: action.payload,
            }
        case 'RESET_REGISTRY':
            return {
                ...state,
                selectedRegistry: {},
            }
        case 'FAIL_REGISTRY':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default registryState
