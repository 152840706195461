import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    // width: "100%",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
