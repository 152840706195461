import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    textAlign: "center",
    // overflowY: "scroll",
    overflowX: "hidden"
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
  },
}));
