import React,{ useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../store";
import TopStepBar from "../components/TopStepBar";
import LeftStepBar from "../components/LeftStepBar";
//import Container from "../components/Container";
import NewDcrRegistryContainer from "../components/NewDcrRegistryContainer";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import useWindowSize from "../utils";
import {
  getProcessingAdvancedDataType,
  getProcessingAdvancedType,
  getProcessingDataType,
  getProcessingArtSix,
  getProcessingArtNine,
  getProcessingBasicDataType,
  getProcessingBasicType,
  getProcessingDataSubject,
  getProcessingDataWarehouse,
  getProcessingEuTransfer,
  getProcessingOrgMeasures,
  getProcessingPurpose,
  getProcessingStorageLocation,
  // getProcessingStorageMethod,
  getProcessingStorageSupport,
  getProcessingTecMeasures,
  setError,
  resetExtraEuTransfer,
  setTreatment,
  setTreatmentsList,
  getProcessingWarranty,
  //setDcrTreatment,
} from "../actions";
import {
  getProcessingAdvancedDataTypeApi,
  getProcessingAdvancedTypeApi,
  getProcessingDataTypeApi,
  getProcessingArtNineApi,
  getProcessingArtSixApi,
  getProcessingBasicDataTypeApi,
  getProcessingBasicTypeApi,
  getProcessingDataSubjectApi,
  getProcessingDataWarehouseApi,
  getProcessingEuTransferApi,
  getProcessingOrgMeasuresApi,
  getProcessingPurposeApi,
  getProcessingStorageLocationApi,
  // getProcessingStorageMethodApi,
  getProcessingStorageSupportApi,
  getProcessingTecMeasuresApi,
  getProcessingWarrantyApi,
} from "../api";
import {
  riskLevel,
  sysAdmin,
  manyToMany,
  oneToMany,
  addNewOption,
  booleans,
  othersToAdd,
} from "../api";

import { useStyles } from "../styles/NewRegistry.style";
import Modals from "../components/modals/Modals";

function getSteps () {
  return [
    "General Info",
    "Purposes & Legal basis",
    "Processing type",
    "Recipient Transfers",
    "Storage",
    "External Processors",
    "Measures",
    "Preview",
  ];
}

const initTreatmentState = {
  internal_identifier: "",
  is_enabled: false,
  info_supplied_by: "",
  processing_name: "",
  processing_description: "",
  processing_note: "",
  other_gdpr_base: "",
  other_processing_types: "",
  other_processing_purposes: "",
  other_data_types: "",
  other_data_subjects: "",
  other_eu_transfers: "",
  // data_collection_description: "",
  extra_eu_transfers: false,
  other_storage_supports: "",
  instrument_description: "",
  interconnection_type: "",
  storage_period: "",
  storage_locations_description: "",
  processing_software: "",
  internal_person_in_charge: "",
  other_technical_measures: "",
  other_organizational_measures: "",
  system_administrator: "",
  internal_instructions: false,
  risk_level: "",
  pia_needed: false,
  internal_reference_structure: null,
  art6_gdpr_base: null,
  art9_gdpr_base: null,
  processing_purposes: [],
  concurrent_structures: [],
  processing_types: [],
  advanced_processing_types: [],
  data_subjects: [],
  basic_data_types: [],
  special_data_categories: [],
  eu_transfers: [],
  storage_supports: [],
  storage_locations: [],
  data_warehouses: [],
  technical_measures: [],
  organizational_measures: [],
  external_processors: [],
  controllers: [],
};

function NewDcrRegistry ({ dcrSelectedRegistry }) {
  const steps = getSteps();
  const classes = useStyles();
  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const [open,setOpen] = useState(false);
  const [activeStep,setActiveStep] = useState(0);
  const [completed,setCompleted] = useState({});
  const [treatment,setTreatment] = useState({
    ...initTreatmentState,
    registry_id: dcrSelectedRegistry.id,
  });
  const handleModal = () => setOpen(!open);

  const handleStep = (index) => {
    handleComplete();
    setActiveStep(index);
  };
  const handleNext = () => {
    handleComplete();
    setActiveStep((activeStep) => activeStep + 1);
  };

  const checkCompletedStep = () => {
    switch (activeStep) {
      case 0:
        return !!treatment.internal_identifier &&
          !!treatment.info_supplied_by &&
          !!treatment.processing_name &&
          !!treatment.internal_reference_structure &&
          !!treatment.concurrent_structures
          ? true
          : false;
      case 1:
        return !!treatment.processing_purposes &&
          !!treatment.data_subjects &&
          !!treatment.basic_data_types &&
          !!treatment.special_data_categories &&
          !!treatment.art6_gdpr_base &&
          !!treatment.art9_gdpr_base
          ? true
          : false;
      case 2:
        return treatment.processing_types.length > 0 &&
          treatment.advanced_processing_types.length > 0
          ? true
          : false;
      case 3:
        return treatment.eu_transfers.length > 0 ? true : false;
      case 4:
        return treatment.storage_supports.length > 0 &&
          treatment.storage_locations.length > 0 &&
          treatment.data_warehouses.length > 0
          ? true
          : false;
      case 5:
        return treatment.external_processors.length > 0 ? true : false;
      case 6:
        return !!treatment.technical_measures &&
          !!treatment.organizational_measures &&
          !!treatment.system_administrator &&
          !!treatment.risk_level
          ? true
          : false;
      default:
        break;
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    if (checkCompletedStep()) {
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
    }
  };

  const handleBack = (step) => {
    handleComplete();
    step === 0 ? setOpen(true) : setActiveStep((activeStep) => activeStep - 1);
  };

  const handleBack2 = () => setActiveStep((activeStep) => activeStep - 1);

  const handleChange = (prop) => (event,checked,str) => {
    if (manyToMany.some((p) => p === prop)) {
      setTreatment({
        ...treatment,
        [prop]: checked,
      });
    } else if (booleans.some((p) => p === prop)) {
      setTreatment({
        ...treatment,
        [prop]: !treatment[prop],
      });
    } else if (othersToAdd.some((p) => p === prop)) {
      setTreatment({
        ...treatment,
        [prop]: str,
      });
    } else {
      setTreatment({ ...treatment,[prop]: event.target.value });
    }
  };

  useEffect(() => {
    if (Object.keys(dcrSelectedRegistry).length == 0) {
      history.goBack();
    }
    console.log("mount2");
    dispatch(resetExtraEuTransfer());
  },[dispatch]);

  return (
    <div className={classes.container}>
      <Modals
        value="back"
        open={open}
        handleClose={handleModal}
        backInWizard={handleBack2}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TopStepBar steps={steps} activeStep={activeStep} />
        </Grid>
        {width > 500 && (
          <Grid item xs={4} className={classes.leftStepbar}>
            <LeftStepBar
              steps={steps}
              activeStep={activeStep}
              completed={completed}
              handleStep={handleStep}
            />
          </Grid>
        )}
        <Grid item xs={width > 500 ? 7 : 12}>
          <NewDcrRegistryContainer
            activeStep={activeStep}
            treatment={treatment}
            handleBack={handleBack}
            handleNext={handleNext}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateProps = (state) => ({
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
});

export default connect(mapStateProps,{})(NewDcrRegistry);
