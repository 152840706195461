import React from "react";
import logo from "../../images/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PersonIcon from "@material-ui/icons/Person";
import SelectLanguage from "../SelectLanguage";
import { history } from "../../store";
import { connect } from "react-redux";

import { useStyles } from "../../styles/Navbar.style";

function Navbar({ user }) {
  const classes = useStyles();

  const getUserName = () => {
    return !!user ? user.first_name + " " + user.last_name : " ";
  };

  return (
    <AppBar position="fixed" className={classes.navbar}>
      <Toolbar>
        <div onClick={() => history.push("/home")}>
          <img className={classes.logo} alt="Logo" src={logo} />{" "}
        </div>

        <div className={classes.right}>
          <SelectLanguage />
          <div
            className={classes.right}
            onClick={() => history.push("/profile")}
            style={{ flexDirection: "column", cursor: "pointer" }}
          >
            <PersonIcon className={classes.icon} />
            <p className={classes.profile}>{getUserName()}</p>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const mapStateProps = (state) => ({
  user: state.authState.user,
});

export default connect(mapStateProps, {})(Navbar);
