const initialState = {
    treatmentList: { results: [] },
    selectedTreatment: {
        // internal_identifier: "",
        // is_enabled: false,
        // info_supplied_by: "",
        // processing_name: "",
        // processing_description: "",
        // processing_note: "",
        // other_gdpr_base: "",
        // other_processing_types: "",
        // other_data_types: "",
        // other_data_subjects: "",
        // data_collection_description: "",
        // extra_eu_transfers: false,
        // other_storage_supports: "",
        // instrument_description: "",
        // interconnection_type: "",
        // storage_period: "",
        // storage_locations_description: "",
        // processing_software: "",
        // internal_person_in_charge: "",
        // other_technical_measures: "",
        // other_organizational_measures: "",
        // system_administrator: "",
        // internal_instructions: false,
        // risk_level: "",
        // pia_needed: false,
        // internal_reference_structure: null,
        // art6_gdpr_base: null,
        // art9_gdpr_base: null,
        // processing_purposes: [],
        // concurrent_structures: [],
        // processing_types: [],
        // advanced_processing_types: [],
        // data_subjects: [],
        // basic_data_types: [],
        // special_data_categories: [],
        // eu_transfers: [],
        // storage_supports: [],
        // storage_locations: [],
        // data_warehouses: [],
        // technical_measures: [],
        // organizational_measures: [],
        // external_processors: []
    },
    err: null,
}

const treatmentState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TREATMENT_LIST':
            return {
                ...state,
                treatmentList: action.payload,
            }
        case 'RESET_TREATMENT_LIST':
            return {
                ...state,
                treatmentList: {},
            }
        case 'FAIL_TREATMENTS_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_TREATMENT':
            return {
                ...state,
                selectedTreatment: action.payload,
            }
        case 'RESET_TREATMENT':
            return {
                ...state,
                selectedTreatment: {},
            }
        case 'FAIL_TREATMENT':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default treatmentState
