import React from "react";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useStyles } from "../styles/Box.style";

function Box ({ value,onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getInnerBoxValue = (val) => {
    switch (val) {
      case "Data Controller Registries":
        return "DCR";
      case "Data Processor Registries":
        return "DPR";
      case "Data Protection Officers":
        return "DPO"
      // TODO: FIXME: Keep "dpo"?
      case "Dpo":
        return "D";
      case "Data Controller":
        return "D";
      case "Data Processors":
        return <ArrowForwardIcon />;
      // TODO: Remove it!.
      //case "Data Protection Officers":
      //return <ArrowForwardIcon />;
      case "Data Controllers":
        return <ArrowForwardIcon />;
      case "Processing Registries":
        return <ArrowForwardIcon />;
      case "General Info":
        return "1";
      case "Purpose & Legal Basis":
        return "2";
      case "Processing Type":
        return "3";
      case "Recipient Transfers":
        return "4";
      case "Storage":
        return "5";
      case "External Processors":
        return "6";
      case "Controllers":
        return "6"
      case "Measures":
        return "7";
      case "Preview":
        return "8";
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.boxContainer} onClick={onClick}>
        <div className={classes.innerBox}>
          <p style={{ color: "#007a85" }}>{getInnerBoxValue(value)}</p>
        </div>
      </div>
      <p style={{ margin: "10px" }}>{t(value)}</p>
    </div>
  );
}

export default Box;
