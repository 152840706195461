const initialState = {
    dpoList: {results: []},
    selectedDpo: {},
    err: null,
}

const dpoState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DPO_LIST':
            return {
                ...state,
                dpoList: action.payload,
            }
        case 'RESET_DPO_LIST':
            return {
                ...state,
                dpoList: {},
            }
        case 'FAIL_DPO_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DPO':
            return {
                ...state,
                selectedDpo: action.payload,
            }
        case 'RESET_DPO':
            return {
                ...state,
                selectedDpo: {},
            }
        case 'FAIL_DPO':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dpoState
