export const setDcrTreatmentsList = (payload) => {
    return {
        type: 'SET_DCR_TREATMENT_LIST',
        payload: payload,
    }
}

export const resetDcrTreatmentsList = () => {
    return {
        type: 'RESET_DCR_TREATMENT_LIST',
    }
}

export const failUploadDcrTreatmentsList = (err) => {
    return {
        type: 'FAIL_DCR_TREATMENT_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDcrTreatment = (payload) => {
    return {
        type: 'SET_DCR_TREATMENT',
        payload: payload,
    }
}

export const resetDcrTreatment = () => {
    return {
        type: 'RESET_DCR_TREATMENT',
    }
}

export const failUploadDcrTreatment = (err) => {
    return {
        type: 'FAIL_DCR_TREATMENT',
        payload: {
            err: err,
        },
    }
}