export const setDprRegistryList = (payload) => {
    return {
        type: 'SET_DPR_REGISTRY_LIST',
        payload: payload,
    }
}

export const resetDprRegistryList = () => {
    return {
        type: 'RESET_DPR_REGISTRY_LIST',
    }
}

export const failUploadDprRegistryList = (err) => {
    return {
        type: 'FAIL_DPR_REGISTRY_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDprRegistry = (payload) => {
    return {
        type: 'SET_DPR_REGISTRY',
        payload: payload,
    }
}

export const resetDprRegistry = () => {
    return {
        type: 'RESET_DPR_REGISTRY',
    }
}

export const failUploadDprRegistry = (err) => {
    return {
        type: 'FAIL_DPR_REGISTRY',
        payload: {
            err: err,
        },
    }
}