import React, { useState } from "react";
import SelectOption from "../SelectOption";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
//import useWindowSize from "../../utils";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import CustomButton from "../CustomButton";
//import { setError } from "../../actions";
//import { updateExtraEuTransfer } from "../../api";
import useWindowSize from "../../utils";

import { useStylesGlobal } from "../../styles/Global.style";
import { useStyle } from "../../styles/TransfersStep.style";

const initExtra = {
  controllers: [],
};

function ControllersStep({ handle, value, handleChange, handleDeleteExtraEu,
  handleExtraEu,
  delExtraEu,
  extraEu = null,
  processing = null,
}) {
  const expanded = true
  const classesGlobal = useStylesGlobal();
  const classes = useStyle();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  //const { width } = useWindowSize();
  const [count, setCount] = useState(0);
  const [extra, setExtra] = useState({});
  const [controllers, setControllers] = useState([])
  const [key, setKey] = useState(Date.now());
  const handleExtra = (e, value) => {
    setExtra({ ...extra, [value]: e.target.value });
  };

  const getController = (id) => {
    let res = ""
    try {
      let el = processing.results?.find(elm => elm.id === id)
      res = `${el.name} - ${el.email} - ${el.country}`

    } catch {
      console.log("getController error", id)
    }
    return res
  }
  /*
  const handleEdit = (euTransfer) => {
    updateExtraEuTransfer(euTransfer.id, value.id, value.registry_id, {
      ...euTransfer,
      ...extra,
    }).catch((err) => setError(err));

    setExtra({});
  };
  */

  const handleEuTranfers = (count) => {
    if (!!extra.controllers) {
      handleExtraEu({ ...extra });
      setExtra(initExtra);
      setCount(count + 1);
    }
  };
  React.useEffect(() => {
    setControllers(value.controllers)
  }, [])
  const handleControllers = (value) => {
    setControllers([...controllers, value]);
  };
  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && (<h1>{t("Controllers")}</h1>)}

      <Accordion expanded={expanded} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>
            {t("Controllers")}
          </h1>
        </AccordionSummary>
        <AccordionDetails key={key}>

          <Grid container alignItems="center">

            {value.controllers &&
              //  !!processing && 
              !!extraEu && (
                <h2 style={{ width: "100%", textAlign: "center" }}>
                  {t("DATA CONTROLLERS")}
                </h2>
              )}
            {value.controllers && (
              <Grid
                item
                xs={12}
                // className={width > 500 ? classes.inner : classes.innerMobile}
              >
                {/* {count + 1}

                <Grid item xs={12} sm={4}> */}
                <SelectOption
                  value="controllers"
                  label={`* ${t("DATA CONTROLLERS")}`}
                  select={value.controllers}
                  handle={handleChange("controllers")} />
                {/* </Grid>
                <CustomButton
                  name="SAVE"
                  onClick={() => handleEuTranfers(count)}
                  disabled={!!!extra.controllers}
                />*/}
              </Grid>
            )}

            {value.controllers &&
              value.controllers.map((val, i) => {
                /* !!val per evitare il rendering se si sta aggiungendo una option */
                return !!val && (
                  <Grid
                    item
                    xs={12}
                    className={
                      width > 500 ? classes.inner : classes.innerMobile
                    }
                  >
                    {/* <Grid item xs={12} sm={1}>
                      {" "}
                      {i + 1}{" "}
                    </Grid> */}

                    <Grid item xs={12} sm={10}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {getController(val)}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <CustomButton
                        name="DELETE"
                        onClick={(event) => {
                          let newControllers = value.controllers.filter(elm => elm != val)
                          handleChange("controllers")(event, [...newControllers], "")
                          setKey(Date.now())
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}

            {/* {value.controllers &&
              !!processing &&
              processing?.results?.map((val) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className={
                      width > 500 ? classes.inner : classes.innerMobile
                    }
                  >
                    <Grid item xs={12} sm={1}>
                      {val.id}{" "}
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {val.controllers}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                     
                      <CustomButton
                        name="DELETE"
                        onClick={() => handleDeleteExtraEu(val)}
                      />
                    </Grid>
                  </Grid>
                );
              })} */}
          </Grid>
        </AccordionDetails>
      </Accordion>


    </div>
  )
}

const mapStateProps = state => ({
  selectedTreatment: state.treatmentState.selectedTreatment,

});

export default connect(mapStateProps, {})(ControllersStep);
