import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden"
  },
  icon: {
    color: "#007a85",
    width: "50px",
    height: "50px",
    marginBottom: "0px",
    marginTop: "20px",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  // innerMobile: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   backgroundColor: "yellow"
  // },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  innerMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    margin: "10px",
    marginTop: "10px",
    width: "400px",
    backgroundColor: "white",
  },
}));
