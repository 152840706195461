import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
    inner: {
        display: "flex", 
        alignItems: "center",
    },
    innerMobile: {
        display: "flex", 
        alignItems: "center",
        flexDirection: "column"
    }
}));
