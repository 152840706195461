import React from "react";
import { useTranslation } from "react-i18next";

import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { history } from "../../store";

import { connect } from "react-redux";

import clsx from "clsx";
import { useStyles } from "../../styles/Sidebar.style";

function Sidebar ({ sidebarOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();


  const elementsTwo = {
    "Data Controller Registries": [
      "Data Controllers",
      "Processing Registries",
    ],
    "Data Processor Registries": [
      "Data Processors",
      "Processing Registries",
    ],
    "Data Protection Officers": []
  };

  const getClass = (key,element) => {
    if (
      key === "Data Controller Registries" &&
      //element === "Data Controllers"
      element === "Data Controllers" &&
      window.location.pathname.startsWith("/dcr/data-controller")
    ) {
      return clsx(classes.nested,classes.orange);
    } else if (
      key === "Data Controller Registries" &&
      //element === "Processing Registries"
      element === "Processing Registries" &&
      window.location.pathname.startsWith("/dcr/processing-registries")
    ) {
      return clsx(classes.nested,classes.orange);
    } else if (
      key === "Data Processor Registries" &&
      element === "Data Processors" &&
      window.location.pathname.startsWith("/dpr/data-processor")
    ) {
      return clsx(classes.nested,classes.orange);
    } else if (
      key === "Data Processor Registries" &&
      element === "Processing Registries" &&
      window.location.pathname.startsWith("/dpr/processing-registries")
    ) {
      return clsx(classes.nested,classes.orange);
    } else {
      return classes.nested;
    }
  };

  const handleClick = (key,element) => {
    // Navigate to Ssection
    if (key && element === undefined) {
      switch (key) {
        case "Data Controller Registries":
          history.push("/dcr");
          break;
        case "Data Processor Registries":
          history.push("/dpr");
          break;
        case "Data Protection Officers":
          history.push("/dpo");
          break;
        default:
          break;
      }
    } else {
      // Navigate to Sub-section.
      const keyElement = `${key} ${element}`;

      switch (keyElement) {
        case "Data Controller Registries Data Controllers":
          history.push("/dcr/data-controller");
          break;
        case "Data Controller Registries Processing Registries":
          history.push("/dcr/processing-registries");
          break;
        case "Data Processor Registries Data Processors":
          history.push("/dpr/data-processor");
          break;
        case "Data Processor Registries Processing Registries":
          history.push("/dpr/processing-registries");
          break;
        /*
        case "Data Protection Officers Data Controllers":
          history.push("/dpo/data-controller");
          break;
        case "Data Protection Officers Processing Registries":
          history.push("/dpo/processing-registries");
          break;
        */
        default:
          break;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        {!!sidebarOpen &&
          Object.keys(elementsTwo).map(function (key) {
            return (
              <List key={key}>
                <ListItem
                  button
                  //key={key} 
                  onClick={() => handleClick(key)}
                >
                  <ListItemText primary={t(key)} />
                </ListItem>
                {elementsTwo[key].length !== 0 && (
                  <Collapse
                    in={true}
                    timeout="auto"
                    unmountOnExit
                    style={{ backgroundColor: "#7dbac1" }}
                  >
                    <List component="div" disablePadding>
                      {elementsTwo[key].length !== 0 &&
                        elementsTwo[key].map((element) => {
                          return (
                            <ListItem
                              button
                              key={element}
                              className={getClass(key,element)}
                              onClick={() => handleClick(key,element)}
                            >
                              <ListItemText primary={t(element)} />
                            </ListItem>
                          );
                        })}
                    </List>
                  </Collapse>
                )}
              </List>
            );
          })}
      </Drawer>
    </div>
  );
}
const mapStateProps = (state) => ({
  sidebarOpen: state.authState.sidebarOpen,
});

export default connect(mapStateProps,{})(Sidebar);
