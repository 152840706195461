import React from "react";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../styles/Modals.style";

function DataControllerConfirmModal ({
    value = null,
    handleClose,
    handleConfirmModal,
    back = null,
}) {
    const classes = useStyles();
    const { t } = useTranslation();


    return (
        <div className={classes.delete}>
            <p style={{ textAlign: "center" }}>{t("Do you want to add a Data Protection Officer?")}</p>

            <div style={{ marginTop: "40px" }}>
                <CustomButton name="YES" onClick={handleConfirmModal} />
                <CustomButton name="NO" value="back" onClick={handleClose} />
            </div>
        </div>
    );
}

export default DataControllerConfirmModal;
