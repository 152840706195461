import React from 'react';


function NoMatch () {
  return (
    <>
      <h1>Hello NoMatch</h1>
    </>
  );
}

export default NoMatch;
