export const setRegistryList = (payload) => {
    return {
        type: 'SET_REGISTRY_LIST',
        payload: payload,
    }
}

export const resetRegistryList = () => {
    return {
        type: 'RESET_REGISTRY_LIST',
    }
}

export const failUploadRegistryList = (err) => {
    return {
        type: 'FAIL_REGISTRY_LIST',
        payload: {
            err: err,
        },
    }
}

export const setRegistry = (payload) => {
    return {
        type: 'SET_REGISTRY',
        payload: payload,
    }
}

export const resetRegistry = () => {
    return {
        type: 'RESET_REGISTRY',
    }
}

export const failUploadRegistry = (err) => {
    return {
        type: 'FAIL_REGISTRY',
        payload: {
            err: err,
        },
    }
}