import React,{ useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import SelectOption from "../SelectOption";

import { useStylesGlobal } from "../../styles/Global.style";

function InfoStep ({ handle,value,handleChange }) {
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  const [expanded,setExpanded] = useState(false);

  const handleChangePanel = (panel) => (event,isExpanded) =>
    setExpanded(isExpanded ? panel : false);



  return (
    <div className={classesGlobal.stepContainer} >
      {!!handle && <h2>{t("General Info")}</h2>}

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangePanel("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded === "panel1"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("General Info")}</h1>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <Input
                field="internal_identifier"
                name={`* ${t("INTERNAL IDENTIFIER")}`}
                value={value.internal_identifier}
                handle={handleChange("internal_identifier")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: "flex",justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    field="is_enabled"
                    color="primary"
                    checked={value.is_enabled}
                    onChange={handleChange("is_enabled")}
                    name="IS ENABLED"
                  />
                }
                label={t("IS ENABLED")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="info_supplied_by"
                name={`* ${t("INFO SUPPLIED BY")}`}
                value={value.info_supplied_by}
                handle={handleChange("info_supplied_by")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="processing_name"
                name={`* ${t("PROCESSING NAME")}`}
                value={value.processing_name}
                handle={handleChange("processing_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="processing_description"
                name="PROCESSING DESCRIPTION"
                value={value.processing_description}
                handle={handleChange("processing_description")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="processing_note"
                name="NOTE"
                value={value.processing_note}
                handle={handleChange("processing_note")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangePanel("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={
            expanded === "panel2"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Structures")}</h1>
        </AccordionSummary>
        <AccordionDetails className={classesGlobal.input}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="internal_reference_structure"
                value="internal_reference_structure"
                label={`* ${t("INTERNAL STRUCTURES")}`}
                select={value.internal_reference_structure || ""}
                handle={handleChange("internal_reference_structure")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="concurrent_structures"
                value="concurrent_structures"
                label={`* ${t("CONCURRENT STRUCTURES")}`}
                select={value.concurrent_structures || ""}
                handle={handleChange("concurrent_structures")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChangePanel("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          style={
            expanded === "panel3"
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Managers")}</h1>
        </AccordionSummary>
        <AccordionDetails className={classesGlobal.input}>
          <Input
            field="internal_person_in_charge"
            name="INTERNAL PERSON IN CHARGE"
            value={value.internal_person_in_charge}
            handle={handleChange("internal_person_in_charge")}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const mapStateProps = (state) => ({
  selectedTreatment: state.treatmentState.selectedTreatment,
});

export default connect(mapStateProps,{})(InfoStep);
