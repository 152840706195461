import React from 'react';
import Box from "../components/Box";
import HomeHeader from "../components/HomeHeader";
import { history } from '../store';

import { useStyles } from "../styles/Home.style";

function Home () {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HomeHeader value="home" />

      <div className={classes.inner}>
        <Box value="Data Controller Registries" onClick={() => history.push("/dcr")} />
        <Box value="Data Processor Registries" onClick={() => history.push("/dpr")} />
        <Box value="Data Protection Officers" onClick={() => history.push("/dpo")} />
      </div>
    </div>
  );
}

export default Home;
