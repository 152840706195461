import React,{ useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  booleans,
  manyToMany,
  oneToMany,
  othersToAdd,
  riskLevel,
  sysAdmin
} from "../../api";
import { useStylesGlobal } from "../../styles/Global.style";
import { getCountryValue } from "../../utils";
import { DataControllerProcessingActivity,DataProcessorProcessingActivity } from "../../nxprivacy";


function PreviewStep ({
  value,
  processingOptions,
  dataControllerList,
  selectedRegistry,
  language,
  extraEuTransfers = false,
}) {
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  const getFields = (str) => {
    // str?.charAt(0).toUpperCase() + str?.slice(1).replace(/_/g, " ")

    return t(str?.replace(/_/g," ").toUpperCase());
  };

  const getTranslatedContent = (val) => {
    // console.log("getTranslated", val, val.name);
    if (val?.name !== undefined) return val.name;
    else {
      if (language === "en" && val?.eng !== undefined) return val.eng;
      else if (language === "it" && val?.ita !== undefined) return val.ita;
    }
    return "";
  };
  /*
  const getConcatenate = (k,v) => {
    return v
      .reduce((s,elm) => {
        const get = () => {
          switch (k) {
            case "concurrent_structures":
              return Object.values(
                processingOptions?.concurrentStructures.results
              ).find((itm) => itm.id === elm);
            case "processing_purposes":
              return Object.values(processingOptions?.purpose?.results).find(
                (itm) => itm.id === elm
              );
            case "processing_types":
              return Object.values(processingOptions?.basicType?.results).find(
                (itm) => itm.id === elm
              );
            case "advanced_processing_types":
              return Object.values(
                processingOptions?.advancedType?.results
              ).find((itm) => itm.id === elm);
            case "data_subjects":
              return Object.values(
                processingOptions?.dataSubject?.results
              ).find((itm) => itm.id === elm);
            case "basic_data_types":
              return Object.values(
                processingOptions?.basicDataType?.results
              ).find((itm) => itm.id === elm);
            case "eu_transfers":
              return Object.values(processingOptions?.euTransfer?.results).find(
                (itm) => itm.id === elm
              );
            case "storage_supports":
              return Object.values(
                processingOptions?.storageSupport?.results
              ).find((itm) => itm.id === elm);
            case "storage_locations":
              return Object.values(
                processingOptions?.storageLocation?.results
              ).find((itm) => itm.id === elm);
            case "data_warehouses":
              return Object.values(
                processingOptions?.dataWarehouse?.results
              ).find((itm) => itm.id === elm);
            case "technical_measures":
              return Object.values(
                processingOptions?.tecMeasures?.results
              ).find((itm) => itm.id === elm);
            case "organizational_measures":
              return Object.values(
                processingOptions?.orgMeasures?.results
              ).find((itm) => itm.id === elm);
            case "external_processors":
              return Object.values(
                processingOptions?.externalProcessors?.results
              ).find((itm) => itm.id === elm);
            case "special_data_categories":
              return Object.values(
                processingOptions?.advancedDataType?.results
              ).find((itm) => itm.id === elm);
            default:
              break;
          }
        };
        const x = get();
        return k === "concurrent_structures" || k === "external_processors"
          ? s + x?.name + ", "
          : language === "en"
            ? s + x.eng + ", "
            : s + x.ita + ", ";
      },"")
      .slice(0,-2);
  };
  */

  const renderArrayValues = (key,values) => {

    let res = [];
    try {
      res = values.map((val) => {
        let obj = null;
        switch (key) {
          case "concurrent_structures":
            if (processingOptions?.concurrentStructures?.results != undefined) {
              obj = Object.values(
                processingOptions?.concurrentStructures?.results
              ).find((itm) => itm.id === val);
              return getTranslatedContent(obj);
            }
          case "processing_purposes":
            obj = Object.values(processingOptions?.purpose?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "processing_types":
            obj = Object.values(processingOptions?.basicType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "advanced_processing_types":
            obj = Object.values(processingOptions?.advancedType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "data_subjects":
            obj = Object.values(processingOptions?.dataSubject?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "basic_data_types":
            obj = Object.values(processingOptions?.basicDataType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "eu_transfers":
            obj = Object.values(processingOptions?.euTransfer?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "storage_supports":
            obj = Object.values(
              processingOptions?.storageSupport?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "storage_locations":
            obj = Object.values(
              processingOptions?.storageLocation?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "data_warehouses":
            obj = Object.values(processingOptions?.dataWarehouse?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "technical_measures":
            obj = Object.values(processingOptions?.tecMeasures?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "organizational_measures":
            obj = Object.values(processingOptions?.orgMeasures?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "external_processors":
            obj = Object.values(
              processingOptions?.externalProcessors?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "controllers":
            if (processingOptions?.controllers?.results != undefined) {
              obj = Object.values(
                processingOptions?.controllers?.results
              ).find((itm) => itm.id === val);
              return getTranslatedContent(obj);
            }
          case "special_data_categories":
            obj = Object.values(
              processingOptions?.advancedDataType?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          default:
            break;
        }
      });
    } catch (e) {
      console.log("ERROR in RENDER MULTIPLE",key,e);
    }
    // console.log("getArrayValues", res);
    return res.join(", ");
  };
  const renderSingleValue = (key,value) => {
    let obj = null;
    let res = "";
    try {
      switch (key) {
        case "country":
          res = getCountryValue(value);
          break;
        case "warranty":
          obj = Object.values(processingOptions.warranty?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "data_controller":
          obj = Object.values(dataControllerList?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "art6_gdpr_base":
          obj = Object.values(processingOptions.artSix?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "art9_gdpr_base":
          obj = Object.values(processingOptions.artNine?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "internal_instructions":
          obj = Object.values(
            processingOptions.internalInstruction?.results
          ).find((itm) => itm.id === value);
          res = getTranslatedContent(obj);
          break;
        case "system_administrator":
          res = t(sysAdmin[value]);
          break;
        case "risk_level":
          res = t(riskLevel[value]);
          break;
        case "data_protection_officers":
          obj = Object.values(
            processingOptions.dataProtectionOfficers?.results
          ).find((itm) => itm.id === value);
          res = getTranslatedContent(obj);
          break;
        case "internal_reference_structure":
          if (processingOptions.internalStructures?.results != undefined) {
            obj = Object.values(
              processingOptions.internalStructures?.results
            ).find((itm) => itm.id === value);
            res = getTranslatedContent(obj);
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("ERROR in RENDER SINGLE",key,e);
    }
    // console.log("getSingleValue", value, obj, res);
    return res;
  };
  const getVal = (k,v) => {
    if (!!v) {
      if (manyToMany.some((e) => e === k)) {
        return renderArrayValues(k,v);
      } else if (oneToMany.some((e) => e === k)) {
        return renderSingleValue(k,v);
      } else if (othersToAdd.some((e) => e === k)) {
        return v;
      } else if (booleans.some((e) => e === k)) {
        if (v === true) return t("YES");
        else return t("NO");
      } else return v;
    } else return t("---");
  };

  const createSection = (keys) => {
    let max = 6;
    let size =
      keys.length % 6 === 0 || keys.length % 6 === max - 1
        ? 6
        : keys.length % 6;
    // let size = 6;

    let section = keys.map((k) => {
      if (k in value && k !== "registry_id") {
        if (manyToMany.some((e) => e === k)) size = 2;
        return (
          <Grid item xs={12 / size} key={k}>
            <b
              // className={classesGlobal.colorHeadingLight}
              style={{ marginRight: "5px" }}
            >
              {getFields(k)}:{" "}
            </b>
            <p>{getVal(k,value[k])}</p>
          </Grid>
        );
      }
    });
    return section;
  };

  const createList = (list) => {
    if (!!list && list !== undefined) {
      let section = list.map((el) => {
        return [
          <Grid item xs={6}>
            <b
              // className={classesGlobal.colorHeadingLight}
              style={{ marginRight: "5px" }}
            >
              {getFields("country")}:{" "}
            </b>
            <p>{getVal("country",el["country"])}</p>
          </Grid>,
          <Grid item xs={6}>
            <b
              // className={classesGlobal.colorHeadingLight}
              style={{ marginRight: "5px" }}
            >
              {getFields("warranty")}:{" "}
            </b>
            <p>{getVal("warranty",el["warranty"])}</p>
          </Grid>,
        ];
      });
      return section;
    } else return [];
  };



  return (
    <div className={classesGlobal.stepContainer}>
      <h2 className={classesGlobal.colorHeadingDark}>{t("Preview")}</h2>
      <Grid container>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("General Info")}
            </h3>
          </Grid>
          {createSection([
            "internal_identifier",
            "is_enabled",
            "info_supplied_by",
          ])}
          <Grid item container xs={12}>
            {createSection([
              "processing_name",
              "processing_description",
              "processing_note",
            ])}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Structures")}
            </h3>
          </Grid>
          {createSection([
            "internal_reference_structure",
            "concurrent_structures",
          ])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>{t("Managers")}</h3>
          </Grid>
          {createSection(["internal_person_in_charge"])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>{t("Purpose")}</h3>
          </Grid>
          {createSection(["processing_purposes","other_processing_purposes"])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Data Subject")}
            </h3>
          </Grid>
          {createSection(["data_subjects","other_data_subjects"])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Legal Basis")}
            </h3>
          </Grid>
          {createSection(["art6_gdpr_base","art9_gdpr_base"])}
        </Grid>
        <Grid item container xs={12}>
          {createSection(["other_processing_types"])}
        </Grid>
        <Grid item container xs={12}>
          {createSection(["processing_types","advanced_processing_types"])}
        </Grid>
        <Grid item container xs={12}>
          {createSection(["other_processing_types"])}
        </Grid>
        <Grid item container xs={12}>
          {createSection(["eu_transfers", "other_eu_transfers"])}
        </Grid>
        <Grid item container xs={12}>
          {createSection(["extra_eu_transfers"])}
        </Grid>
        <Grid item container xs={12}>
          {value["extra_eu_transfers"] === true &&
            createList(extraEuTransfers || value?.extra_eu_transfers_list)}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>{t("Data Type")}</h3>
          </Grid>
          {createSection([
            "basic_data_types",
            "special_data_categories",
            // "data_collection_description",
            "other_data_types",
          ])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>{t("Storage")}</h3>
          </Grid>
          {createSection([
            "storage_supports",
            "other_storage_supports",
            "storage_locations",
            "storage_period",
            "instrument_description",
            "processing_software",
            "storage_locations_description",
            "interconnection_type",
          ])}
        </Grid>
        {(value instanceof DataControllerProcessingActivity) | (Object.keys(processingOptions?.externalProcessors).length > 0) ?
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <hr className={classesGlobal.colorHeadingDark} />
              <h3 className={classesGlobal.colorHeadingMain}>
                {t("External Processors")}
              </h3>
            </Grid>
            {createSection(["external_processors"])}
          </Grid>
          :
          <></>
        }
        {(value instanceof DataProcessorProcessingActivity) | (Object.keys(processingOptions?.controllers).length > 0) ?
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <hr className={classesGlobal.colorHeadingDark} />
              <h3 className={classesGlobal.colorHeadingMain}>
                {t("Data Controllers")}
              </h3>
            </Grid>
            {createSection(["controllers"])}
          </Grid>
          :
          <></>
        }
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Technical Measures")}
            </h3>
          </Grid>
          {createSection(["technical_measures","other_technical_measures"])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Organizational Measures")}
            </h3>
          </Grid>
          {createSection([
            "organizational_measures",
            "other_organizational_measures",
          ])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("System Admin and Instructions")}
            </h3>
          </Grid>
          {createSection(["internal_instructions","system_administrator"])}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <hr className={classesGlobal.colorHeadingDark} />
            <h3 className={classesGlobal.colorHeadingMain}>
              {t("Risk Level")}
            </h3>
          </Grid>
          {createSection(["risk_level","pia_needed"])}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateProps = (state) => ({
  dataControllerList: state.dataControllerState.dataControllerList,
  processingOptions: state.processingState,
  selectedRegistry: state.registryState.selectedRegistry,
  language: state.authState.language,
});

export default connect(mapStateProps,{})(PreviewStep);
