export const setTreatmentsList = (payload) => {
    return {
        type: 'SET_TREATMENT_LIST',
        payload: payload,
    }
}

export const resetTreatmentsList = () => {
    return {
        type: 'RESET_TREATMENT_LIST',
    }
}

export const failUploadTreatmentsList = (err) => {
    return {
        type: 'FAIL_TREATMENT_LIST',
        payload: {
            err: err,
        },
    }
}

export const setTreatment = (payload) => {
    return {
        type: 'SET_TREATMENT',
        payload: payload,
    }
}

export const resetTreatment = () => {
    return {
        type: 'RESET_TREATMENT',
    }
}

export const failUploadTreatment = (err) => {
    return {
        type: 'FAIL_TREATMENT',
        payload: {
            err: err,
        },
    }
}