const initialState = {
    dcrRegistryList: { results: [] },
    dcrSelectedRegistry: {},
    err: null,
}

const dcrRegistryState = (state = initialState,action) => {
    switch (action.type) {
        case 'SET_DCR_REGISTRY_LIST':
            return {
                ...state,
                dcrRegistryList: action.payload,
            }
        case 'RESET_DCR_REGISTRY_LIST':
            return {
                ...state,
                dcrRegistryList: {},
            }
        case 'FAIL_DCR_REGISTRY_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DCR_REGISTRY':
            return {
                ...state,
                dcrSelectedRegistry: action.payload,
            }
        case 'RESET_DCR_REGISTRY':
            return {
                ...state,
                dcrSelectedRegistry: {},
            }
        case 'FAIL_DCR_REGISTRY':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dcrRegistryState
