import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@material-ui/core/TextField";
import { resetPassword } from "../api";
// import { useDispatch } from 'react-redux';
import { setError } from "../actions";
import { history } from "../store";
import CustomButton from "../components/CustomButton";

import { useDispatch } from "react-redux";

import { useStyles } from "../styles/ResetPassword.style";

function ResetPassword() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleChange = (prop) => (event) => setEmail(event.target.value);
  const handleResetPassword = () => {
    resetPassword(email).catch((err) => dispatch(setError(err)));
  };

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <h1>{t("RESET PASSWORD")}</h1>

        <form>
          <TextField
            className={classes.form}
            label={t("E-mail")}
            value={email}
            variant="outlined"
            type="text"
            onChange={handleChange()}
          />
        </form>

        <CustomButton
          name="RESET"
          style={{ marginTop: "20px" }}
          onClick={(e) => {
            handleResetPassword();
            history.push("/");
          }}
        />
      </div>
    </div>
  );
}

export default ResetPassword;
