import React from 'react';  
import { useTranslation } from "react-i18next";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import { useStyles } from "../styles/ProcessingRegistryHeaderTab.style";


function RegistryHeaderTab() {
    //   const classes = useStyles(); 
const { t } = useTranslation();
    

    return (
        <TableHead>
            <TableRow>
                <TableCell align="left">{t("INTERNAL IDENTIFIER")}</TableCell>
                <TableCell align="center">{t("IS ENABLED")}</TableCell>
                <TableCell align="right">{t("PROCESSING NAME")}</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default RegistryHeaderTab;