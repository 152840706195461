import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#efeff4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    minHeight: "780px",
  },
  form: {
    marginTop: "20px",
    width: "400px",
  },
  logo: {
    width: "300px",
  },
}));
