const initialState = {
    dprRegistryList: { results: [] },
    dprSelectedRegistry: {},
    err: null,
}

const dprRegistryState = (state = initialState,action) => {
    switch (action.type) {
        case 'SET_DPR_REGISTRY_LIST':
            return {
                ...state,
                dprRegistryList: action.payload,
            }
        case 'RESET_DPR_REGISTRY_LIST':
            return {
                ...state,
                dprRegistryList: {},
            }
        case 'FAIL_DPR_REGISTRY_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DPR_REGISTRY':
            return {
                ...state,
                dprSelectedRegistry: action.payload,
            }
        case 'RESET_DPR_REGISTRY':
            return {
                ...state,
                dprSelectedRegistry: {},
            }
        case 'FAIL_DPR_REGISTRY':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dprRegistryState
