import React from 'react';
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";


import { useStyles } from "../styles/HomeHeader.style";

function HomeHeader({ value, user }) {
  const classes = useStyles();
  const { t } = useTranslation();


  const getCapitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1)
  }

  const getUserName = () => {
    if (!!user) {
      switch (value) {
        case "home":
          return t("Welcome, ") + getCapitalize(user.first_name) + "."
        case "profile":
          return getCapitalize(user.first_name) + " " + getCapitalize(user.last_name)
        case "Data Controller Registries":
          return t("Data Controller Registries")
        case "Data Processor Registries":
          return t("Data Processor Registries")
        case "Data Protection Officers":
          return t("Data Protection Officers")
        case "data_processor":
          return t("Data Processors")
        case "data_controller":
          return t("Data Controllers")
        case "New Data Controller":
          return t("New Data Controller")
        case "New Data Processor":
            return t("New Data Processor")
        case "Processing Registries":
          return t("Processing Registries")
        case "Treatment":
          return t("Processing")
        default:
          return value
      }
    }
  }

  return (
    <p className={classes.header}  >
      {getUserName()}
    </p>
  )
}

const mapStateProps = state => ({
  user: state.authState.user
});

export default connect(mapStateProps, {})(HomeHeader);
