import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Input from "../Input";
import SelectOption from "../SelectOption";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStylesGlobal } from "../../styles/Global.style";

function ProcessingStep({ handle, value, handleChange }) {
  const expanded = true
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && <h2>{t("Processing Type")}</h2>}

      <Accordion
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("Processing Type")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="processing_types"
                value="processing_types"
                label={`* ${t("PROCESSING TYPE")}`}
                select={value.processing_types}
                handle={handleChange("processing_types")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectOption
                field="advanced_processing_types"
                value="advanced_processing_types"
                label={`* ${t("ADVANCED PROCESSING TYPE")}`}
                select={value.advanced_processing_types}
                handle={handleChange("advanced_processing_types")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_processing_types"
                name="OTHER PROCESSING TYPES"
                value={value.other_processing_types}
                handle={handleChange("other_processing_types")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ProcessingStep;
