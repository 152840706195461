import React,{ useEffect } from "react";
import Box from "../components/Box";
import HomeHeader from "../components/HomeHeader";
import { connect } from "react-redux";
import { history } from "../store";
import { useDispatch } from "react-redux";
import {
  getDprRegistries,
  getDirectDataProtectionOfficers,
  getDataProcessors,
} from "../api";
import {
  setDprRegistryList,
  setDpoList,
  setError,
  setDataProcessorList,
} from "../actions";

import { useStyle } from "../styles/DataProcessorRegistries.style";



function DataProcessorRegistries ({ user }) {
  const classes = useStyle();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!user) {
      getDataProcessors()
        .then((data) => dispatch(setDataProcessorList(data)))
        .catch((err) => dispatch(setError(err)));

      getDprRegistries()
        .then((data) => dispatch(setDprRegistryList(data)))
        .catch((err) => dispatch(setError(err)));

      getDirectDataProtectionOfficers()
        .then((data) => dispatch(setDpoList(data)))
        .catch((err) => dispatch(setError(err)));
    }
  },[user,dispatch]);

  return (
    <>
      <div className={classes.container}>
        <HomeHeader value="Data Processor Registries" />
        <p style={{ maxWidth: "60%" }}></p>

        <div className={classes.inner}>
          <Box
            value="Data Processors"
            onClick={() => history.push("/dpr/data-processor")}
          />
          {/* <div style={{ width: "80px" }} /> */}
          {/*
           TODO: Remove it! - Alle
          <Box
            value="Data Protection Officers"
            onClick={() => history.push("/dcr/dpo")}
          />
          */}

          {/* <div style={{ width: "80px" }} /> */}
          <Box
            value="Processing Registries"
            onClick={() => history.push("/dpr/processing-registries")}
          />
        </div>
      </div>
    </>
  );
}

const mapStateProps = (state) => ({
  user: state.authState.user,
});

export default connect(mapStateProps,{})(DataProcessorRegistries);
