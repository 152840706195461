export const setDcrRegistryList = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY_LIST',
        payload: payload,
    }
}

export const resetDcrRegistryList = () => {
    return {
        type: 'RESET_DCR_REGISTRY_LIST',
    }
}

export const failUploadDcrRegistryList = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDcrRegistry = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY',
        payload: payload,
    }
}

export const resetDcrRegistry = () => {
    return {
        type: 'RESET_DCR_REGISTRY',
    }
}

export const failUploadDcrRegistry = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY',
        payload: {
            err: err,
        },
    }
}