import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
    color: "white",
    top: theme.mainLayout.bottomTopBar.topAnchor,
    height: theme.mainLayout.bottomTopBar.height,
    width: "100%",
    boxShadow: "none !important"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    color: "white",
  },
  right: {
    float: "right",
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    cursor: "pointer"
  },
}));
