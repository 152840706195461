import React from 'react';
//import { useTranslation } from "react-i18next";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableBody from '@material-ui/core/TableBody';
import { history } from "../../store"
import { useDispatch } from "react-redux";
import { setDataController,resetTreatment,resetDpo } from "../../actions";

import { useStyles } from "../../styles/TableDisplay.style";

function DataControllerBodyTab ({ row,value,handleClick }) {
    const classes = useStyles();
    //const { t } = useTranslation();
    const dispatch = useDispatch();

    const showInformation = () => {
        dispatch(setDataController(row))
        dispatch(resetTreatment())
        dispatch(resetDpo())
        history.push("/dcr/data-controller/display")
    }

    return (
        <TableBody key={row.id}>
            <TableRow className={classes.clickableRow} key={row.id}>
                <TableCell component="th" scope="row" onClick={() => showInformation()}>{row.name}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.email}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.city}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.country}</TableCell>
                <TableCell align="right" style={{ width: "30px" }}>
                    <IconButton color="inherit" className={classes.iconButton} onClick={(e) => handleClick(e,row,value)}><MoreVertIcon /></IconButton>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

export default DataControllerBodyTab;
