import React from 'react';
import { useTranslation } from "react-i18next";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import { useStyles } from "../styles/DataProcessorHeaderTab.style";


function DataProcessorHeaderTab () {
    //   const classes = useStyles(); 
    const { t } = useTranslation();


    return (
        <TableHead>
            <TableRow>
                <TableCell>{t("NAME")}</TableCell>
                <TableCell align="right">{t("EMAIL")}</TableCell>
                <TableCell align="right">{t("CITY")}</TableCell>
                <TableCell align="right">{t("COUNTRY")}</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default DataProcessorHeaderTab;
