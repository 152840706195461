import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import Table from "@material-ui/core/Table";
import Select from "@material-ui/core/Select";
import Modals from "../components/modals/Modals";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import DpoBodyTab from "./tabs/DpoBodyTab";
import DpoHeaderTab from "./tabs/DpoHeaderTab";
import DeleteModal from "./modals/DeleteModal";
import FormControl from "@material-ui/core/FormControl";
import TableContainer from "@material-ui/core/TableContainer";
import TreatmentBodyTab from "./tabs/TreatmentBodyTab";
import TreatmentHeaderTab from "./tabs/TreatmentHeaderTab";
import DataControllerBodyTab from "./tabs/DataControllerBodyTab";
import DataControllerHeaderTab from "./tabs/DataControllerHeaderTab";
import DataProcessorHeaderTab from "./tabs/DataProcessorHeaderTab";
import DataProcessorBodyTab from "./tabs/DataProcessorBodyTab";
import DcrProcessingRegistryHeaderTab from "./tabs/DcrProcessingRegistryHeaderTab";
import DcrProcessingRegistryBodyTab from "./tabs/DcrProcessingRegistryBodyTab";
import DprProcessingRegistryHeaderTab from "./tabs/DprProcessingRegistryHeaderTab";
import DprProcessingRegistryBodyTab from "./tabs/DprProcessingRegistryBodyTab";
import useWindowSize from "../utils";
import { history } from "../store";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { getTreatments, getDprTreatments, deleteDataController, getDataControllers, deleteDataProcessor, getDataProcessors, deleteDprTreatment, deleteDcrTreatment, getDcrTreatments } from "../api";
import {
  resetDcrRegistry,
  setDcrRegistry,
  resetDprRegistry,
  setDprRegistry,
  setDataProcessor,
  setTreatmentsList,
  setDprTreatmentsList,
  resetDataController,
  resetDataProcessor,
  resetTreatment,
  setDataControllerList,
  setDataProcessorList,
  setDataController,
  setRegistry,
  setError,
  setDpo,
  resetDpo,
  setTreatment,
  resetDcrTreatment,
  resetDprTreatment,
  resetDprTreatmentsList,
  resetDcrTreatmentsList,
  setDcrTreatmentsList,
  resetTreatmentsList
} from "../actions";

import { useStyles } from "../styles/TableDisplay.style";
import DpoModal from "./modals/DpoModal";
import DprProcessingRegistriesModal from "./modals/DprProcessingRegistriesModal";
import DcrProcessingRegistriesModal from "./modals/DcrProcessingRegistriesModal";
import GeneralDeleteModal from "./modals/GeneralDeleteModal";
import { useLocation } from "react-router-dom";

export function TableDisplay({
  dataControllerList,
  dataProcessorList,
  //registryList,
  selectedRegistry,
  dcrRegistryList,
  dcrSelectedRegistry,
  dprRegistryList,
  dprSelectedRegistry,
  treatmentList,
  dpoList,
  value,
  selectedDataController,
  selectedDataProcessor,
  selectedTreatment
}) {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  //const [updateRegistryOpen,setUpdateRegistryOpen] = useState(false);

  //- Data Controller
  const [deleteDataControllerOpen, setDeleteDataControllerOpen] = useState(false);

  //- DCR Registry.
  const [updateDcrRegistryOpen, setUpdateDcrRegistryOpen] = useState(false);
  const [deleteDcrRegistryOpen, setDeleteDcrRegistryOpen] = useState(false);
  const [newOptionDcrRegistryOpen, setNewOptionDcrRegistryOpen] = useState(false);

  //- Data Processor
  const [deleteDataProcessorOpen, setDeleteDataProcessorOpen] = useState(false);

  //- DPR Registry.
  const [updateDprRegistryOpen, setUpdateDprRegistryOpen] = useState(false);
  const [deleteDprRegistryOpen, setDeleteDprRegistryOpen] = useState(false);
  const [newOptionDprRegistryOpen, setNewOptionDprRegistryOpen] = useState(false);

  //- DCR/DPR Treatment
  const [deleteTreatmentOpen, setDeleteTreatmentOpen] = useState(false);

  //- DPO
  const [updateDpo, setUpdateDpo] = useState(false);
  const [deleteDpo, setDeleteDpo] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableRows, setTableRows] = useState([]);

  // - Data Controller.
  const handleCloseDataControllerModal = () => {
    dispatch(resetDataController())
    setDeleteDataControllerOpen(!deleteDataControllerOpen);

  }
  const handleDeleteDataControllerModal = (id) => {

    deleteDataController(selectedDataController.id)
      .then((data) => {
        dispatch(resetDataController());
        getDataControllers()
          .then((data1) => dispatch(setDataControllerList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
    handleCloseDataControllerModal()
  }
  // - Data Processor.
  const handleCloseDataProcessorModal = () => {
    dispatch(resetDataProcessor())
    setDeleteDataProcessorOpen(!deleteDataProcessorOpen);

  }
  const handleDeleteDataProcessorModal = () => {
    deleteDataProcessor(selectedDataProcessor.id)
      .then((data) => {
        dispatch(resetDataProcessor());
        getDataProcessors()
          .then((data1) => dispatch(setDataProcessorList(data1)))
          .catch((err1) => dispatch(setError(err1)));
      })
      .catch((err) => dispatch(setError(err)));
    handleCloseDataProcessorModal()
  }
  // - DCR/DPR Treatment.
  const handleCloseTreatmentModal = () => {
    dispatch(resetTreatment())
    if (location.pathname.startsWith("/dcr/")) {
      dispatch(resetDcrTreatment())

    } else if (location.pathname.startsWith("/dpr/")) {
      dispatch(resetDprTreatment())

    }
    setDeleteTreatmentOpen(!deleteTreatmentOpen);
  }
  const handleDeleteTreatmentModal = () => {
    if (location.pathname.startsWith("/dcr/")) {
      deleteDcrTreatment(selectedTreatment.id, dcrSelectedRegistry.id)
        .then((data) => {
          // dispatch(resetDcrTreatmentsList());
          // dispatch(resetTreatmentsList());
          getDcrTreatments(dcrSelectedRegistry.id)
            .then((data1) => dispatch(setTreatmentsList(data1)))
            .catch((err1) => dispatch(setError(err1)));
        })
        .catch((err) => dispatch(setError(err)));
    } else if (location.pathname.startsWith("/dpr/")) {
      deleteDprTreatment(selectedTreatment.id, dprSelectedRegistry.id)
        .then((data) => {
          // dispatch(resetDprTreatmentsList());
          // dispatch(resetTreatmentsList());
          getDprTreatments(dprSelectedRegistry.id)
            .then((data1) => dispatch(setTreatmentsList(data1)))
            .catch((err1) => dispatch(setError(err1)));
        })
        .catch((err) => dispatch(setError(err)));
    }

    handleCloseTreatmentModal()
  }

  // - DCR Registry.
  const handleUpdateDcrRegistryModal = () => {
    dispatch(resetDcrRegistry())
    setUpdateDcrRegistryOpen(!updateDcrRegistryOpen);
  }
  const handleDeleteDcrRegistryModal = () => {
    dispatch(resetDcrRegistry())
    setDeleteDcrRegistryOpen(!deleteDcrRegistryOpen);
  }
  const handleNewOptionDcrRegistryModal = () => {
    dispatch(resetDcrRegistry())
    setNewOptionDcrRegistryOpen(!newOptionDcrRegistryOpen)
  }


  // - DPR Registry.
  const handleUpdateDprRegistryModal = () => {
    dispatch(resetDprRegistry())
    setUpdateDprRegistryOpen(!updateDprRegistryOpen);
  }
  const handleDeleteDprRegistryModal = () => {
    dispatch(resetDprRegistry())
    setDeleteDprRegistryOpen(!deleteDprRegistryOpen);
  }

  const handleNewOptionDprRegistryModal = () => {
    dispatch(resetDprRegistry())
    setNewOptionDprRegistryOpen(!newOptionDprRegistryOpen)
  }

  // - DPO.
  const handleUpdateDpoModal = () => {
    dispatch(resetDpo())
    setUpdateDpo(!updateDpo);
  }
  const handleDeleteDpoModal = () => {
    dispatch(resetDpo())
    setDeleteDpo(!deleteDpo);
  }

  const path = () => {
    switch (window.location.pathname) {
      case "/dcr/data-controller":
        return "/dcr/data-controller/admin";
      case "/dcr/processing-registries":
        return "/dcr/processing-registries/registry";
      case "/dcr/processing-registries/registry":
        return "/dcr/processing-registries/registry/processing";
      case "/dpr/data-processor":
        return "/dpr/data-processor/admin";
      case "/dpr/processing-registries":
        return "/dpr/processing-registries/registry";
      case "/dpr/processing-registries/registry":
        return "/dpr/processing-registries/registry/processing";
      default:
        break;
    }
  };

  const handleClick = (event, row, value) => {
    switch (value) {
      case "data_controller":
        dispatch(setDataController(row));
        setAnchorEl(event.currentTarget);
        break;
      case "data_processor":
        dispatch(setDataProcessor(row));
        setAnchorEl(event.currentTarget);
        break;
      case "dcr_processing_registry":
        dispatch(setDcrRegistry(row));
        setAnchorEl(event.currentTarget);
        break;
      case "dpr_processing_registry":
        dispatch(setDprRegistry(row));
        setAnchorEl(event.currentTarget);
        break;
      case "processing_registry":
        dispatch(setRegistry(row));
        setAnchorEl(event.currentTarget);
        break;
      case "registry":
        dispatch(setTreatment(row));
        setAnchorEl(event.currentTarget);
        break;
      case "dpo":
        dispatch(setDpo(row));
        setAnchorEl(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const openConfirmDeleteModal = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const getNumbersPage = (value) => {
    switch (value) {
      case "data_controller":
        return dataControllerList.results.length % rowsPerPage === 0
          ? Math.floor(dataControllerList.results.length / rowsPerPage)
          : Math.floor(dataControllerList.results.length / rowsPerPage) + 1;
      case "data_processor":
        return dataProcessorList.results.length % rowsPerPage === 0
          ? Math.floor(dataProcessorList.results.length / rowsPerPage)
          : Math.floor(dataProcessorList.results.length / rowsPerPage) + 1;
      case "dcr_processing_registry":
        return dcrRegistryList.results.length % rowsPerPage === 0
          ? Math.floor(dcrRegistryList.results.length / rowsPerPage)
          : Math.floor(dcrRegistryList.results.length / rowsPerPage) + 1;
      case "dpr_processing_registry":
        return dprRegistryList.results.length % rowsPerPage === 0
          ? Math.floor(dprRegistryList.results.length / rowsPerPage)
          : Math.floor(dprRegistryList.results.length / rowsPerPage) + 1;
      /*
      case "processing_registry":
        return registryList.results.length % rowsPerPage === 0
          ? Math.floor(registryList.results.length / rowsPerPage)
          : Math.floor(registryList.results.length / rowsPerPage) + 1;
      */
      case "registry":
        return treatmentList.results.length % rowsPerPage === 0
          ? Math.floor(treatmentList.results.length / rowsPerPage)
          : Math.floor(treatmentList.results.length / rowsPerPage) + 1;
      case "dpo":
        return dpoList.results.length % rowsPerPage === 0
          ? Math.floor(dpoList.results.length / rowsPerPage)
          : Math.floor(dpoList.results.length / rowsPerPage) + 1;
      default:
        break;
    }
  };

  const setRows = useCallback(
    (rowsPerPage, page, value) => {
      switch (value) {
        case "data_controller":
          return setTableRows(
            dataControllerList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        case "data_processor":
          return setTableRows(
            dataProcessorList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        case "dcr_processing_registry":
          return setTableRows(
            dcrRegistryList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        case "dpr_processing_registry":
          return setTableRows(
            dprRegistryList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        /*
        case "processing_registry":
          return setTableRows(
            registryList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        */
        case "registry":
          return setTableRows(
            treatmentList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        case "dpo":
          return setTableRows(
            dpoList.results.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )
          );
        default:
          break;
      }
    },
    [
      dataControllerList,
      dataProcessorList,
      //registryList,
      dcrRegistryList,
      dprRegistryList,
      treatmentList,
      dpoList
    ]
  );

  const getHeaderTab = (val) => {
    switch (val) {
      case "data_controller":
        return <DataControllerHeaderTab />;
      case "data_processor":
        return <DataProcessorHeaderTab />;
      case "dcr_processing_registry":
        return <DcrProcessingRegistryHeaderTab />;
      case "dpr_processing_registry":
        return <DprProcessingRegistryHeaderTab />;
      /*
      case "processing_registry":
        return <ProcessingRegistryHeaderTab />;
      */
      case "registry":
        return <TreatmentHeaderTab />;
      case "dpo":
        return <DpoHeaderTab />;
      default:
        break;
    }
  };

  const getBodyTab = (row, value) => {
    switch (value) {
      case "data_controller":
        return (
          <DataControllerBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      case "data_processor":
        return (
          <DataProcessorBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      case "dcr_processing_registry":
        return (
          <DcrProcessingRegistryBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      case "dpr_processing_registry":
        return (
          <DprProcessingRegistryBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      case "registry":
        return (
          <TreatmentBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      case "dpo":
        return (
          <DpoBodyTab
            row={row}
            value={value}
            handleClick={handleClick}
            key={row.id}
          />
        );
      default:
        break;
    }
  };

  const handleEditClick = (isModal, action) => {
    setAnchorEl(null);
    setOpen(false);

    switch (action) {
      case "EDIT":
        if (value === "dpo" && isModal) {
          setUpdateDpo(!updateDpo)
        } else if (value === "dcr_processing_registry" && isModal) {
          setUpdateDcrRegistryOpen(!updateDcrRegistryOpen)
        } else if (value === "dpr_processing_registry" && isModal) {
          setUpdateDprRegistryOpen(!updateDprRegistryOpen)
        } else {
          history.push(path());
        }
        break;
      case "DELETE":
        if (value === "dpo" && isModal) {
          setDeleteDpo(!deleteDpo)
        } else if (value === "dcr_processing_registry" && isModal) {
          setDeleteDcrRegistryOpen(!deleteDcrRegistryOpen)
        } else if (value === "dpr_processing_registry" && isModal) {
          setDeleteDprRegistryOpen(!deleteDprRegistryOpen)
        } else if (value === "data_controller" && isModal) {
          setDeleteDataControllerOpen(!deleteDataControllerOpen)
        } else if (value === "data_processor" && isModal) {
          setDeleteDataProcessorOpen(!deleteDataProcessorOpen)
        } else if (value === "registry" && isModal) {
          setDeleteTreatmentOpen(!deleteTreatmentOpen)
        } else {
          history.push(path());
        }
      default:
        if (value === "dpo" && isModal) {
          setDeleteDpo(!deleteDpo)
        } else if (value === "dcr_processing_registry" && isModal) {
          setDeleteDcrRegistryOpen(!deleteDcrRegistryOpen)
        } else if (value === "dpr_processing_registry" && isModal) {
          setDeleteDprRegistryOpen(!deleteDprRegistryOpen)
        } else if (value === "data_controller" && isModal) {
          setDeleteDataControllerOpen(!deleteDataControllerOpen)
        } else if (value === "data_processor" && isModal) {
          setDeleteDataProcessorOpen(!deleteDataProcessorOpen)
        } else if (value === "registry" && isModal) {
          setDeleteTreatmentOpen(!deleteTreatmentOpen)
        } else {
          history.push(path());
        }
        break;
    }

    //if (value === "dpo" && isModal) setUpdateDpo(!updateDpo)
    //if (value === "dcr_processing_registry" && isModal) setUpdateDcrRegistryOpen(!updateDcrRegistryOpen)
    //else if (value === "dpr_processing_registry" && isModal) setUpdateDprRegistryOpen(!updateDprRegistryOpen)
    //else history.push(path());
  };

  useEffect(() => {
    setRows(rowsPerPage, page, value);
    if (!dcrSelectedRegistry) {
      getTreatments(dcrSelectedRegistry.id)
        .then((data) => {
          if (value === "registry") {
            dispatch(setTreatmentsList(data));
            setRows(rowsPerPage, page, value);
          }
        })
        .catch((err) => dispatch(setError(err)));
    }
    if (!dprSelectedRegistry) {
      getTreatments(dprSelectedRegistry.id)
        .then((data) => {
          if (value === "registry") {
            dispatch(setTreatmentsList(data));
            setRows(rowsPerPage, page, value);
          }
        })
        .catch((err) => dispatch(setError(err)));
    }
  }, [
    rowsPerPage,
    page,
    dataControllerList,
    dataProcessorList,
    treatmentList,
    value,
    dcrSelectedRegistry,
    dprSelectedRegistry,
    dispatch,
    setRows,
  ]);

  return (
    <TableContainer className={width > 500 ? classes.root : classes.rootMobile}>
      <div className={classes.headerTab}>
        <p className={classes.leftHeaderTab}>{t("SUMMARY")}</p>

        <div className={classes.rightHeaderTab}>
          <Pagination
            variant="outlined"
            count={getNumbersPage(value)}
            color="primary"
            onChange={(e, p) => setPage(p)}
            style={{ marginRight: "10px", display: "flex", float: "right" }}
          />
          <p style={{ marginRight: "10px" }}>{t("RESULTS PER PAGE")}</p>
          <FormControl className={classes.formControl}>
            <Select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <Table className={classes.table} size="small" aria-label="a dense table">
        {getHeaderTab(value)}

        {tableRows.map((row) => getBodyTab(row, value))}
      </Table>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {value === "dcr_processing_registry" && (
          <MenuItem
            className={classes.menu}
            onClick={() => handleEditClick(false, "VIEW ACTIVITIES")}
          >
            {t("VIEW ACTIVITIES")}
          </MenuItem>
        )}
        {value === "dpr_processing_registry" && (
          <MenuItem
            className={classes.menu}
            onClick={() => handleEditClick(false, "VIEW ACTIVITIES")}
          >
            {t("VIEW ACTIVITIES")}
          </MenuItem>
        )}
        <MenuItem className={classes.menu} onClick={() => handleEditClick(true, "EDIT")}>
          {t("EDIT")}
        </MenuItem>
        {/* Alle
        <MenuItem className={classes.menu} onClick={openConfirmDeleteModal}>
          {t("DELETE")}
        </MenuItem>
        */}
        <MenuItem className={classes.menu} onClick={() => handleEditClick(true, "DELETE")}>
          {t("DELETE")}
        </MenuItem>
      </Menu>
      {/*
      <Modals
        value="delete"
        name={value}
        open={open}
        handleClose={handleClose}
      />
      */}
      {/* Data Controller */}
      {deleteDataControllerOpen &&
        <GeneralDeleteModal value="delete" name={value} open={deleteDataControllerOpen} handleConfirmModal={handleDeleteDataControllerModal} handleClose={handleCloseDataControllerModal} />

      }
      {/* Data Processor */}
      {deleteDataProcessorOpen &&
        <GeneralDeleteModal value="delete" name={value} open={deleteDataProcessorOpen} handleConfirmModal={handleDeleteDataProcessorModal} handleClose={handleCloseDataProcessorModal} />
      }
      {/* Treatment */}
      {deleteTreatmentOpen &&
        <GeneralDeleteModal value="delete" name={value} open={deleteTreatmentOpen} handleConfirmModal={handleDeleteTreatmentModal} handleClose={handleCloseTreatmentModal} />
      }
      {/* DCR Registry */}
      {updateDcrRegistryOpen &&
        <DcrProcessingRegistriesModal value="update" name={value} open={updateDcrRegistryOpen} handleClose={handleUpdateDcrRegistryModal} />
      }
      {deleteDcrRegistryOpen &&
        <DcrProcessingRegistriesModal value="delete" name={value} open={deleteDcrRegistryOpen} handleClose={handleDeleteDcrRegistryModal} />
      }
      {newOptionDcrRegistryOpen &&
        <DcrProcessingRegistriesModal value="new_option" name={value} open={newOptionDcrRegistryOpen} handleClose={handleNewOptionDcrRegistryModal} />
      }
      {/*
      <Modals
        value="update"
        name={value}
        open={updateDcrRegistryOpen}
        handleClose={handleUpdateDcrRegistryModal}
      />
      */}

      {/* DPR Registry */}
      {updateDprRegistryOpen &&
        <DprProcessingRegistriesModal value="update" name={value} open={updateDprRegistryOpen} handleClose={handleUpdateDprRegistryModal} />
      }
      {deleteDprRegistryOpen &&
        <DprProcessingRegistriesModal value="delete" name={value} open={deleteDprRegistryOpen} handleClose={handleDeleteDprRegistryModal} />
      }
      {newOptionDprRegistryOpen &&
        <DprProcessingRegistriesModal value="new_option" name={value} open={newOptionDprRegistryOpen} handleClose={handleNewOptionDprRegistryModal} />
      }
      {/*
      <Modals
        value="update"
        name={value}
        open={updateDprRegistryOpen}
        handleClose={handleUpdateDprRegistryModal}
      />
      */}
      {/* DPO */}
      <DpoModal value="update" name={value} open={updateDpo} handleClose={handleUpdateDpoModal} />
      <DpoModal value="delete" name={value} open={deleteDpo} handleClose={handleDeleteDpoModal} />

    </TableContainer>
  );
}

const mapStateProps = (state) => ({
  dataControllerList: state.dataControllerState.dataControllerList,
  selectedDataController: state.dataControllerState.selectedDataController,
  dataProcessorList: state.dataProcessorState.dataProcessorList,
  selectedDataProcessor: state.dataProcessorState.selectedDataProcessor,
  //registryList: state.registryState.registryList,
  selectedRegistry: state.registryState.selectedRegistry,
  dcrRegistryList: state.dcrRegistryState.dcrRegistryList,
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
  dprRegistryList: state.dprRegistryState.dprRegistryList,
  dprSelectedRegistry: state.dprRegistryState.dprSelectedRegistry,
  treatmentList: state.treatmentState.treatmentList,
  dpoList: state.dpoState.dpoList,
  selectedTreatment: state.treatmentState.selectedTreatment,
});

export default connect(mapStateProps, {})(TableDisplay);
