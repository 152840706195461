import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `3px solid ${theme.palette.dark_gray.main}`,
    padding: "20px",
    fontSize: "30px",
    color: `${theme.palette.dark_gray.main}`,
  },
}));
