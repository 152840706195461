import React,{ useState,useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import DeleteModal from "./DeleteModal";
import NewOptionModal from "./NewOptionModal";
import NewRegistryModal from "./NewRegistryModal";
import DataControllerConfirmModal from "./DataControllerConfirmModal";
import DataProcessorConfirmModal from "./DataProcessorConfirmModal";
import { connect } from "react-redux";
import { history } from "../../store";
import { useDispatch } from "react-redux";
import {
  addDcrRegistry,
  addDprRegistry,
  addTreatment,
  deleteDataProcessor,
  getDataProcessors,
  getDcrRegistries,
  getDprRegistries,
  getTreatments,
  updateDirectDataProtectionOfficer,
  updateDcrRegistry,
} from "../../api";
import {
  resetDataController,
  resetDcrRegistry,
  resetDprRegistry,
  setError,
  setDataControllerList,
  setDcrRegistryList,
  setDprRegistryList,
  setTreatmentsList,
  setConcurrentStructures,
  setInternalStructures,
  setExternalProcessors,
  setDataProtectionOfficers,
  resetDpo,
  setDpoList,
  resetDataProcessor,
  setDataProcessorList,
} from "../../actions";
import {
  getDataControllers,
  deleteDataController,
  deleteDcrRegistry,
  deleteDprRegistry,
  deleteDirectDataProtectionOfficer,
  addExternalProcessor,
  addConcurrentStructure,
  addDirectDataProtectionOfficer,
  getDirectDataProtectionOfficers,
  addInternalStructure,
  getConcurrentStructures,
  getInternalStructures,
  getExternalProcessors,
} from "../../api";

import { useStyles } from "../../styles/Modals.style";

function Modals ({
  value,
  handleClose,
  handleConfirm,
  open,
  backInWizard,
  selectedDataController,
  selectedDataProcessor,
  dcrSelectedRegistry,
  dprSelectedRegistry,
  selectedDpo,
  name = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data,setData] = useState(() => {
    switch (value) {
      case "dcr_processing_registry":
        return dcrSelectedRegistry || { data_controller: null };
      case "dpr_processing_registry":
        return dprSelectedRegistry || { data_processor: null }
      default:
        break;
    }
  }
    //dcrSelectedRegistry || { data_controller: null }
  );

  const [optionData,setOptionData] = useState({});

  const handleRegistryChange = (prop) => (event) => {
    console.log(prop);

    if (prop === "data_controller") {
      setOptionData({ ...optionData,[prop]: event.target.value });
    }

    if (prop === "data_processor") {
      setOptionData({ ...optionData,[prop]: event.target.value });
    }

    setData({ ...data,[prop]: event.target.value });
  };

  const handleOptionChange = (prop) => (event) => {

    if (prop === "data_controller") {
      setData({ ...data,[prop]: event.target.value });
    }

    if (prop === "data_processor") {
      setData({ ...data,[prop]: event.target.value });
    }

    setOptionData({
      ...selectedDpo,
      ...optionData,
      [prop]: event.target.value,
    });
  };

  const deleteActions = (name) => {
    switch (name) {
      case "data_controller":
        deleteDataController(selectedDataController.id)
          .then((data) => {
            dispatch(resetDataController());
            getDataControllers()
              .then((data1) => dispatch(setDataControllerList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "data_processor":
        deleteDataProcessor(selectedDataProcessor.id)
          .then((data) => {
            dispatch(resetDataProcessor());
            getDataProcessors()
              .then((data1) => dispatch(setDataProcessorList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        break;
      case "dpo":
        deleteDirectDataProtectionOfficer(selectedDpo.id)
          .then((data) => {
            dispatch(resetDpo());
            getDirectDataProtectionOfficers()
              .then((data1) => dispatch(setDpoList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "dcr_processing_registry":
        deleteDcrRegistry(dcrSelectedRegistry.id)
          .then((data) => {
            dispatch(resetDcrRegistry());
            getDcrRegistries()
              .then((data1) => dispatch(setDcrRegistryList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "dpr_processing_registry":
        deleteDprRegistry(dprSelectedRegistry.id)
          .then((data) => {
            dispatch(resetDprRegistry());
            getDprRegistries()
              .then((data1) => dispatch(setDprRegistryList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      /*
    case "dcr_registry":
      deleteTreatment(selectedTreatment.id,dcrSelectedRegistry.id)
        .then((data) => {
          dispatch(resetTreatment());
          getTreatments(dcrSelectedRegistry.id)
            .then((data1) => dispatch(setTreatmentsList(data1)))
            .catch((err1) => dispatch(setError(err1)));
        })
        .catch((err) => dispatch(setError(err)));
      break;
      */
      default:
        break;
    }
  };

  const callDataProtectionOfficers = () => {
    if (open) {
      getDirectDataProtectionOfficers()
        .then((data) => dispatch(setDataProtectionOfficers(data)))
        .catch((err) => dispatch(resetDpo()));
    }
  };

  const addNewOption = (name,value) => {

    console.log(value);

    switch (name) {
      case "concurrent_structures" || "concurrent structures":
        addConcurrentStructure(dcrSelectedRegistry.data_controller,optionData)
          .then((data1) => {
            getConcurrentStructures(data.data_controller)
              .then((data2) => dispatch(setConcurrentStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "external_processors" || "external processors":
        addExternalProcessor(dcrSelectedRegistry.data_controller,optionData)
          .then((data1) => {
            getExternalProcessors(data.data_controller)
              .then((data2) => dispatch(setExternalProcessors(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => dispatch(setError(err)));
        break;
      case "internal_reference_structure" || "internal reference structures":
        addInternalStructure(dcrSelectedRegistry.data_controller,optionData)
          .then((data1) => {
            getInternalStructures(data.data_controller)
              .then((data2) => dispatch(setInternalStructures(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      case "data_protection_officers" || "data protection officers":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers(optionData.data_controller)
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err))
          });
        break;
      case "dpo":
        addDirectDataProtectionOfficer(optionData)
          .then((data1) => {
            getDirectDataProtectionOfficers()
              .then((data2) => dispatch(setDpoList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });
        break;
      default:
        break;
    }
    setOptionData({
      data_controller: dcrSelectedRegistry.data_controller || null,
    });
  };

  const handleConfirmModal = () => {
    switch (value) {
      case "confirm":
        handleConfirm();
        break;
      case "delete":
        handleClose();
        deleteActions(name);
        break;
      case "cancel":
        handleClose();
        history.goBack();
        break;
      case "back":
        handleClose();
        backInWizard();
        history.goBack();
        break;
      case "dcr_processing_registry":
        addDcrRegistry(data)
          .then((data) => {
            getDcrRegistries()
              .then((data1) => {
                dispatch(setDcrRegistryList(data1))
              })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;
      case "dpr_processing_registry":
        addDprRegistry(data)
          .then((data) => {
            getDprRegistries()
              .then((data1) => {
                dispatch(setDprRegistryList(data1))
              })
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();
        break;

      case "update":
        if (name === "dpo") {
          updateDirectDataProtectionOfficer(selectedDpo.id,optionData)
            .then((data) => {
              getDirectDataProtectionOfficers()
                .then((data1) => dispatch(setDpoList(data1)))
                .catch((err1) => dispatch(setError(err1)));
            })
            .catch((err) => dispatch(setError(err)));
        } else if (name === "dcr_processing_registry") {
          updateDcrRegistry(dcrSelectedRegistry.id,data)
            .then((data) => {
              getDcrRegistries()
                .then((data1) => { dispatch(setDcrRegistryList(data1)) })
                .catch((err1) => dispatch(setError(err1)));
            })
            .catch((err) => dispatch(setError(err)));
        }

        handleClose();
        break;
      case "processing":
        addTreatment(data)
          .then((data) => {
            getTreatments(dcrSelectedRegistry.id)
              .then((data1) => dispatch(setTreatmentsList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        handleClose();
        history.push("/dcr/processing-registries/add-new");
        break;
      case "new_option":
        addNewOption(name,value);
        handleClose();
        break;
      case "dpo":
        addNewOption(name);
        handleClose();
        break;
      default:
        break;
    }
  };

  const getBodyModal = (history,value) => {

    switch (value) {
      case "confirm":
        if (history.location.pathname === "/dpr/data-processor/add-new") {
          return (
            <DataProcessorConfirmModal
              handleConfirmModal={handleConfirmModal}
              handleClose={handleClose}
            ></DataProcessorConfirmModal>
          );
        } else if (history.location.pathname === "/dcr/data-controller/add-new") {
          return (
            <DataControllerConfirmModal
              handleConfirmModal={handleConfirmModal}
              handleClose={handleClose}
            ></DataControllerConfirmModal>
          )
        }
        break;
      case "delete":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          ></DeleteModal>
        );
      case "cancel":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          ></DeleteModal>
        );
      case "back":
        return (
          <DeleteModal
            back={true}
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          ></DeleteModal>
        );
      case "dcr_processing_registry":
        callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={value}
            data={data}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDcrRegistry());
              handleClose();
            }}
          ></NewRegistryModal>
        );
      case "dpr_processing_registry":
        callDataProtectionOfficers();
        return (
          <NewRegistryModal
            value={value}
            data={data}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetDprRegistry());
              handleClose();
            }}
          ></NewRegistryModal>
        );
      case "update":
        callDataProtectionOfficers();
        if (name === "dpo") {
          return (
            <NewOptionModal
              data={{ ...(selectedDpo || null) }}
              value={value}
              isProtectionOfficers={true}
              handleChange={handleOptionChange}
              handleConfirmModal={handleConfirmModal}
              handleClose={handleClose}
            ></NewOptionModal>
          );
        } else if (name === "dcr_processing_registry") {
          return (
            <NewRegistryModal
              value={value}
              data={{ ...(dcrSelectedRegistry || null) }}
              handleChange={handleRegistryChange}
              handleConfirmModal={handleConfirmModal}
              handleClose={() => {
                dispatch(resetDcrRegistry());
                handleClose();
              }}
            ></NewRegistryModal>
          );
        } else if (name === "dpr_processing_registry") {
          return (
            <NewRegistryModal
              value={value}
              data={{ ...(dprSelectedRegistry || null) }}
              handleChange={handleRegistryChange}
              handleConfirmModal={handleConfirmModal}
              handleClose={() => {
                dispatch(resetDprRegistry());
                handleClose();
              }}
            ></NewRegistryModal>
          );
        } /*else {
          <NewRegistryModal
            value={value}
            data={data}
            handleChange={handleRegistryChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={() => {
              dispatch(resetRegistry());
              handleClose();
            }}
          ></NewRegistryModal>
        };
        */
        break;
      case "new_option":
        const isProtectionOfficers =
          name === "data_protection_officers" ? true : false;

        const isProcessor =
          name === "data_processor" ? true : false;

        switch (history.location.pathname) {
          case "/dcr/processing-registries":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_controller: data?.data_controller || null,
                }}
                isProtectionOfficers={isProtectionOfficers}
                isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dpr/processing-registries":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_processor: data?.data_processor || null,
                }}
                isProtectionOfficers={isProtectionOfficers}
                isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );

          case "/dcr/processing-registries/add-new":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_controller: data?.data_controller || null,
                }}
                isProtectionOfficers={isProtectionOfficers}
                isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            );
          case "/dpr/processing-registries/add-new":
            return (
              <NewOptionModal
                data={{
                  ...optionData,
                  data_processor: data?.data_processor || null,
                }}
                isProtectionOfficers={isProtectionOfficers}
                isProcessor={isProcessor}
                handleChange={handleOptionChange}
                handleConfirmModal={handleConfirmModal}
                handleClose={handleClose}
              ></NewOptionModal>
            )
          default:
            break;
        }
      case "dpo":
        return (
          <NewOptionModal
            data={{ ...optionData }}
            isProtectionOfficers={true}
            handleChange={handleOptionChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          ></NewOptionModal>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    switch (value) {
      case "dcr_processing_registry":
        setData(dcrSelectedRegistry);
        break;

      case "dpr_processing_registry":
        setData(dprSelectedRegistry);
        break;

      default:
        break;
    }
  },[value,dcrSelectedRegistry,dprSelectedRegistry]);

  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <>{getBodyModal(history,value)}</>
      </Modal>
    </div>
  );
}

const mapStateProps = (state,dispatch) => ({
  user: state.authState.user,
  selectedDataController: state.dataControllerState.selectedDataController,
  selectedDataProcessor: state.dataProcessorState.selectedDataProcessor,
  //selectedRegistry: state.registryState.selectedRegistry,
  dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
  dprSelectedRegistry: state.dprRegistryState.dprSelectedRegistry,
  selectedTreatment: state.treatmentState.selectedTreatment,
  selectedDpo: state.dpoState.selectedDpo,
});

export default connect(mapStateProps,{})(Modals);
