import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslation } from "react-i18next";
import useWindowSize from "../utils";

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function TopStepBar({ steps, activeStep }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <div className={classes.root}>
            {width > 500 && (
                <p style={{ marginLeft: "20px" }}>{t("Add new processing activity")}</p>
            )}
            <Stepper style={{ width: width > 500 ? "75%" : "100%" }} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

export default TopStepBar;
