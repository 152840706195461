import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  form: {
    marginTop: "5px",
    borderRadius: "10px",
    minWidth: "350px",

    backgroundColor: "white",
    maxHeight: "50px",
  },
  label: {
    fontSize: "15px",
    color: `${theme.palette.dark_gray.main}`,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    "& span": {
      maxWidth: "50px",
      textOverflow: "ellipsis",
    },
  },
}));
