export const setError = (payload) => {
    return {
        type: 'SET_ERROR',
        payload: payload,
    }
}

export const resetError = () => {
    return {
        type: 'RESET_ERROR',
    }
}