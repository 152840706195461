import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#efeff4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    minHeight: "780px",
  },
  inner: {
    border: "3px solid black",
    minWidth: "30%",
    height: "50%",
    padding: "20px",
  },
  form: {
    marginTop: "20px",
    width: "400px",
    backgroundColor: "white",
  },
}));
