export const setDataProcessorList = (payload) => {
    return {
        type: 'SET_DATA_PROCESSOR_LIST',
        payload: payload,
    }
}

export const resetDataProcessorList = () => {
    return {
        type: 'RESET_DATA_PROCESSOR_LIST',
    }
}

export const failUploadDataProcessorList = (err) => {
    return {
        type: 'FAIL_DATA_PROCESSOR_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDataProcessor = (payload) => {
    return {
        type: 'SET_DATA_PROCESSOR',
        payload: payload,
    }
}

export const resetDataProcessor = () => {
    return {
        type: 'RESET_DATA_PROCESSOR',
    }
}

export const failUploadDataProcessor = (err) => {
    return {
        type: 'FAIL_DATA_PROCESSOR',
        payload: {
            err: err,
        },
    }
}