import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "90%",
    marginTop: "0px",
    overflowY: "scroll",
    overflowX: "hidden"
  },
  leftStepbar: {
    display: "flex",
    maxHeight: "20%",
    justifyContent: "center"
  }
}));
