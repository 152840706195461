import React from 'react';
import SelectOption from "../SelectOption";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
//import useWindowSize from "../../utils";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStylesGlobal } from "../../styles/Global.style";


function ExternalStep ({ handle,value,handleChange }) {
  const expanded = true
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();
  //const { width } = useWindowSize();

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && (<h1>{t("External Processors")}</h1>)}

      <Accordion expanded={expanded} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={
            expanded
              ? { backgroundColor: "rgb(0,122,133, 0.3)" }
              : {}
          }
        >
          <h1 className={classesGlobal.heading}>{t("External Processors")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <SelectOption
            value="external_processors"
            label={`* ${t("EXTERNAL PROCESSORS")}`}
            select={value.external_processors}
            handle={handleChange("external_processors")} />

        </AccordionDetails>
      </Accordion>


    </div>
  )
}

const mapStateProps = state => ({
  selectedTreatment: state.treatmentState.selectedTreatment
});

export default connect(mapStateProps,{})(ExternalStep);
