import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxContainer: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: "white",
    width: "90px",
    height: "90px",
    margin: "30px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "1px 1px 1px 1px grey",
    cursor: "pointer",
  },
  innerBox: {
    display: "flex",
    border: "3px solid #007a85",
    borderRadius: "100%",
    width: "45px",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
  },
}));
