import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  innerMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    flexDirection: "column",
  },
  innerContainer: {
    width: "1000px",
    marginBottom: "10%",
  },
  innerContainerMobile: {
    width: "auto",
    marginBottom: "10%",
  },
  innerButton: {
    display: "flex",
    flexDirection: "column",
   
  },
}));
