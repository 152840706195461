import authState from "./userReducers";
import dataControllerState from "./dataControllerReducers";
import dataProcessorState from "./dataProcessorReducers";
// TODO: Remove it -- Alle
import registryState from "./registryReducers";
import dcrRegistryState from "./dcrRegistryReducers"
import dprRegistryState from "./dprRegistryReducers"
import treatmentState from "./treatmentReducers";
// TODO: Remove it -- Alle
import processingState from "./processingReducers";
import errorState from "./errorReducers";
import dpoState from "./dpoReducers";
import { connectRouter } from "connected-react-router";
import { combineReducers } from 'redux';

const createReducers = history =>
    combineReducers({
        router: connectRouter(history),
        authState,
        dataControllerState,
        dataProcessorState,
        registryState,
        dcrRegistryState,
        dprRegistryState,
        processingState,
        treatmentState,
        errorState,
        dpoState,
    });

export default createReducers