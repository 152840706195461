import React from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { resetError } from "../actions";

function Alert (props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ErrorFeedback ({ error,resetError,showError,errors }) {
  const anchor = { vertical: "top",horizontal: "right" };
  const handleClose = () => resetError();
  return (
    !!error &&
    error.status !== 500 && (
      <Snackbar open={showError} onClose={handleClose} anchorOrigin={anchor}>
        <div>
          {Object.keys(errors).map(function (key,index) {
            let msgLabel =
              key === "non_field_errors"
                ? ""
                : `${capitalizeFirstLetter(key)
                  .replace(/\\/g,"")
                  .replace(/_/g," ")}`;
            let msg = errors[key]?.replace(/\\/g,"").replace(/_/g," ");
            return (
              <Alert key={key} onClose={handleClose} severity={"error"}>
                {msgLabel !== "" && `${msgLabel}: `}
                {msg}
              </Alert>
            );
          })}
        </div>
      </Snackbar>
    )
  );
}

const mapStateProps = (state) => ({
  error: state.errorState.error,
  showError: state.errorState.showError,
  errors: state.errorState.errors,
});

export default connect(mapStateProps,{ resetError })(ErrorFeedback);
