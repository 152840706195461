import React,{ useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import CustomButton from "../CustomButton";
//import SelectOption from "../SelectOption";

import { useStyles } from "../../styles/Modals.style";

function NewDpoModal ({
  data,
  handleClose,
  handleChange,
  handleConfirmModal,
  value = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => { },[data])

  return (
    <div className={classes.delete}>
      <div style={{ textAlign: "center" }}>
        {value === "update" ? (
          <p>{t("Update Data Protection Officer")}</p>
        ) : (
          <p>{t("Insert new Data Protection Officer")}</p>
        )}
      </div>

      <Input
        field="name"
        name={`* ${t("NAME")}`}
        value={data.name || ""}
        handle={handleChange("name")}
      />

      <Input
        field="family_name"
        name={`${t("FAMILY NAME")}`}
        value={data.family_name || ""}
        handle={handleChange("family_name")}
      />
      <Input
        field="company_name"
        name={`${t("COMPANY NAME")}`}
        value={data.company_name || ""}
        handle={handleChange("company_name")}
      />
      <Input
        field="email"
        name={`* ${t("EMAIL")}`}
        value={data.email || ""}
        handle={handleChange("email")}
      />
      <Input
        field="phone_number"
        name={`${t("PHONE NUMBER")}`}
        value={data.phone_number || ""}
        handle={handleChange("phone_number")}
      />

      <div style={{ marginTop: "20px" }}>
        {value === "update" ? (
          <CustomButton name="UPDATE" onClick={handleConfirmModal} />
        ) : (
          <CustomButton name="CREATE" onClick={handleConfirmModal} />
        )}
        <CustomButton name="CANCEL" value="back" onClick={handleClose} />
      </div>
    </div>
  );
}

export default NewDpoModal;
