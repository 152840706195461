import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SelectOption from "../SelectOption";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../CustomButton";
//import { setError } from "../../actions";
//import { updateExtraEuTransfer } from "../../api";
import useWindowSize from "../../utils";
import { connect } from "react-redux";
import Input from "../Input";
import { useStylesGlobal } from "../../styles/Global.style";
import { useStyle } from "../../styles/TransfersStep.style";

const initExtra = {
  warranty: null,
  country: null,
};

function TransferStep ({
  handle,
  handleDeleteExtraEu,
  value,
  handleChange,
  handleExtraEu,
  delExtraEu,
  warranty,
  language,
  extraEu = null,
  processing = null,
}) {
  const expanded = true;
  const classesGlobal = useStylesGlobal();
  const classes = useStyle();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [count,setCount] = useState(0);
  const [extra,setExtra] = useState({});

  const handleExtra = (e,value) => {
    setExtra({ ...extra,[value]: e.target.value });
  };

  /*
  const handleEdit = (euTransfer) => {
    updateExtraEuTransfer(euTransfer.id, value.id, value.registry_id, {
      ...euTransfer,
      ...extra,
    }).catch((err) => setError(err));

    setExtra({});
  };
  */

  const handleEuTranfers = (count) => {
    if (!!extra.warranty && !!extra.country) {
      handleExtraEu({ ...extra });
      setExtra(initExtra);
      setCount(count + 1);
    }
  };

  const getWarranty = (war) => {
    const x = Object.values(warranty?.results).find((itm) => itm.id === war);
    return language === "en" ? x.eng : x.ita;
  };

  return (
    <div className={classesGlobal.stepContainer}>
      {!!handle && <h2>{t("Recipient Transfers")}</h2>}

      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={expanded ? { backgroundColor: "rgb(0,122,133, 0.3)" } : {}}
        >
          <h1 className={classesGlobal.heading}>{t("Recipient Transfers")}</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <SelectOption
                value="eu_transfers"
                label={`${t("EU RECIPIENTS TRANSFER")}`}
                select={value.eu_transfers}
                handle={handleChange("eu_transfers")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                field="other_eu_transfers"
                name="OTHER EU RECIPIENTS TRANSFERS"
                value={value.other_eu_transfers}
                handle={handleChange("other_eu_transfers")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: "flex",justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={value.extra_eu_transfers}
                    onChange={handleChange("extra_eu_transfers")}
                    name="EXTRA EU RECIPIENTS TRANSFER"
                  />
                }
                label={t("EXTRA EU RECIPIENTS TRANSFER")}
              />
            </Grid>

            {value.extra_eu_transfers && !!processing && !!extraEu && (
              <h2 style={{ width: "100%",textAlign: "center" }}>
                {t("Extra EU List")}
              </h2>
            )}

            {value.extra_eu_transfers && (
              <Grid
                item
                xs={12}
                className={width > 500 ? classes.inner : classes.innerMobile}
              >
                {count + 1}
                <Grid item xs={12} sm={4}>
                  <SelectOption
                    key={`select-warranty-${count + 1}`}
                    value="warranty"
                    label={`${t("WARRANTY")}`}
                    select={extra.warranty}
                    handle={(e) => handleExtra(e,"warranty")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectOption
                    key={`select-country-${count + 1}`}
                    value="country"
                    label={`${t("COUNTRY")}`}
                    select={extra.country}
                    handle={(e) => handleExtra(e,"country")}
                  />
                </Grid>
                <CustomButton
                  name="SAVE"
                  onClick={() => handleEuTranfers(count)}
                  disabled={!!!extra.warranty || !!!extra.country}
                />
              </Grid>
            )}

            {value.extra_eu_transfers &&
              !!extraEu &&
              extraEu.map((val,i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className={
                      width > 500 ? classes.inner : classes.innerMobile
                    }
                  >
                    <Grid item xs={12} sm={1}>
                      {" "}
                      {i + 1}{" "}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {getWarranty(val.warranty)}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {val.country}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <CustomButton
                        name="DELETE"
                        onClick={() => {
                          setCount(count - 1);
                          delExtraEu(val);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}

            {value.extra_eu_transfers &&
              !!processing &&
              processing?.results?.map((val) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className={
                      width > 500 ? classes.inner : classes.innerMobile
                    }
                  >
                    <Grid item xs={12} sm={1}>
                      {val.id}{" "}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {getWarranty(val.warranty)}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <p className={classesGlobal.colorHeadingLight}>
                        {val.country}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      {/* <CustomButton
                        name="EDIT"
                        onClick={() => handleEdit(val)}
                      /> */}
                      <CustomButton
                        name="DELETE"
                        onClick={() => handleDeleteExtraEu(val)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const mapStateProps = (state) => ({
  warranty: state.processingState.warranty,
  language: state.authState.language,
});

export default connect(mapStateProps,{})(TransferStep);
