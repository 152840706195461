import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginTop: "10px",
    display: "flex",
    backgroundColor: "white",
    padding: "1%",
    borderRadius: "20px",
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    // overflowY: "scroll",
    overflowX: "hidden",
    marginBottom: "50px",
  },
  inner: {
    display: "flex",
    position: "relative",
    bottom: "0px",
    right: "0px",
    float: "right",
  }
}));
