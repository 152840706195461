import React,{ useState,useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import HomeHeader from "../components/HomeHeader";
import SearchIcon from "@material-ui/icons/Search";
import GeneratePDF from "../components/reportGenerator";
import CustomButton from "../components/CustomButton";
import TableDisplay from "../components/TableDisplay";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useWindowSize from "../utils";
import { history } from "../store";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDataProcessors } from "../api";
import { setDataProcessorList,setError } from "../actions";

import { useStyle } from "../styles/DataController.style";
import { useStylesGlobal } from "../styles/Global.style";

function DataProcessor ({ dataProcessorList,stateForPDF }) {
  const classes = useStyle();
  const classesGlobal = useStylesGlobal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue,setSearchValue] = useState("");
  const { width } = useWindowSize();

  const handleExport = (e) => {
    e.preventDefault();
    GeneratePDF("data_processors",dataProcessorList.results,t,stateForPDF);
  };

  const searchOnChange = (e) => {
    setSearchValue(e.target.value);

    getDataProcessors(null,null,null,e.target.value)
      .then((data) => dispatch(setDataProcessorList(data)))
      .catch((err) => dispatch(setError(err)));
  };

  useEffect(() => {
    getDataProcessors()
      .then((data) => dispatch(setDataProcessorList(data)))
      .catch((err) => dispatch(setError(err)))
  },[dispatch]);

  return (
    <div className={classesGlobal.container}>
      <HomeHeader value="data_processor" />

      <div className={width > 500 ? classes.inner : classes.innerMobile}>
        <CustomButton
          name="ADD DATA PROCESSOR"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => history.push("/dpr/data-processor/add-new")}
        />

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder={t("Search…")}
            value={searchValue}
            classes={{ root: classes.inputRoot,input: classes.inputInput }}
            onChange={(e) => searchOnChange(e)}
          />
        </div>

        <CustomButton
          name="EXPORT PDF"
          startIcon={<SystemUpdateAltIcon />}
          onClick={(e) => handleExport(e)}
        />
      </div>

      <TableDisplay value={"data_processor"} />
    </div>
  );
}

const mapStateProps = (state) => ({
  dataProcessorList: state.dataProcessorState.dataProcessorList,
  stateForPDF: state,
});

export default connect(mapStateProps,{})(DataProcessor);
