import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import CustomButton from "../CustomButton";
import SelectOption from "../SelectOption";

import { useStyles } from "../../styles/Modals.style";

function NewRegistryModal({
  data,
  handleClose,
  handleChange,
  handleConfirmModal,
  value = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <div className={classes.delete}>
      <div style={{ textAlign: "center" }}>

        {value.includes("update") ? (
          <p>{t("Update Registry")}</p>
        ) : (
          <p>{t("Insert new Registry")}</p>
        )}

      </div>

      {value.includes("dcr_processing_registry") &&
        <SelectOption
          field="data_controller"
          value="data_controller"
          label={`* ${t("DATA CONTROLLER")}`}
          select={data.data_controller || ""}
          handle={handleChange("data_controller")}
        />
      }
      {value.includes("dpr_processing_registry") &&
        <SelectOption
          field="data_processor"
          value="data_processor"
          label={`* ${t("DATA PROCESSOR")}`}
          select={data.data_processor || ""}
          handle={handleChange("data_processor")}
        />
      }

      <Input
        field="name"
        name={`* ${t("REGISTRY NAME")}`}
        value={data.name || ""}
        handle={handleChange("name")}
      />
      <SelectOption
        field="data_protection_officer"
        value="data_protection_officers"
        label={`${t("DATA PROTECTION OFFICER")}`}
        select={data.data_protection_officer || ""}
        handle={handleChange("data_protection_officer")}
      />

      <div style={{ marginTop: "20px" }}>
        {value.includes("update") ? (
          <CustomButton name="UPDATE" onClick={handleConfirmModal} />
        ) : (
          <CustomButton name="CREATE" onClick={handleConfirmModal} />
        )}

        <CustomButton
          name="CANCEL"
          value="back"
          onClick={() => handleClose()}
        />
      </div>
    </div>
  );
}

export default NewRegistryModal;
