import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerOpen: {
    width: "250px",
    top: theme.mainLayout.mainContainer.topAnchor,
    backgroundColor: "#007a85",
    color: "white",
  },
  drawerClose: {},
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    color: "white",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    borderBottom: "2px solid white",
  },
  orange: {
    backgroundColor: "#ffa500",
  },
}));
