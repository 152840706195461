import React from 'react';
import { useTranslation } from "react-i18next";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import { useStyles } from "../styles/ProcessingRegistryHeaderTab.style";


function DcrProcessingRegistryHeaderTab () {
    //   const classes = useStyles(); 
    const { t } = useTranslation();


    return (
        <TableHead>
            <TableRow>
                <TableCell>{t("DATA CONTROLLER")}</TableCell>
                <TableCell align="right">{t("NAME")}</TableCell>
                <TableCell align="right">{t("PROCESSING COUNT")}</TableCell>
                <TableCell align="right">{t("DATA PROTECTION OFFICER")}</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default DcrProcessingRegistryHeaderTab;