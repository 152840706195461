import React,{ Suspense } from "react";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import ResetPassword from "./pages/ResetPassword";
import DataControllerRegistries from "./pages/DataControllerRegistries";
import DataProcessorRegistries from "./pages/DataProcessorRegistries";
import DataController from "./pages/DataController";
import DataProcessor from "./pages/DataProcessor";
import NewDataProcessor from "./pages/NewDataProcessor";
import DataProtectionOfficers from "./pages/DataProtectionOfficers";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import NewDataController from "./pages/NewDataController";
// TODO Remove it -- Alle.
import NewRegistry from "./pages/NewRegistry";
import DcrProcessingRegistries from "./pages/DcrProcessingRegistries";
import DprProcessingRegistries from "./pages/DprProcessingRegistries";
import DprRegistry from "./pages/DprRegistry";
import DcrRegistry from "./pages/DcrRegistry";
import NewDcrRegistry from "./pages/NewDcrRegistry";
import NewDprRegistry from "./pages/NewDprRegistry";
import DisplayInfo from "./pages/DisplayInfo";
import Treatment from "./pages/Treatment";
import Profile from "./pages/Profile";
import NoMatch from "./pages/NoMatch";
import { Provider } from "react-redux";
import { Route,Redirect,Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { history,store } from "./store";
import { ThemeProvider } from "@material-ui/core/styles";
import { doLogin } from "./actions";
import { ApiClient } from "./nxprivacy";

import i18n from "./i18n";
import theme from "./theme";
import DprTreatment from "./pages/DprTreatment";
import DcrTreatment from "./pages/DcrTreatment";



export const breadcrumbs = {
  //
  // Home.
  //
  "/home": ["Home"],
  //
  // Profile.
  //
  "/profile": ["Profile"],
  //
  // DCR.
  //
  "/dcr": ["Data Controller Registries"],

  "/dcr/data-controller": ["Data Controller Registries","Data Controllers"],

  "/dcr/data-controller/admin": ["Data Controller Registries","Data Controllers","Admin"],

  "/dcr/data-controller/display": [
    "Data Controller Registries",
    "Data Controller",
    "Display",
  ],

  "/dcr/data-controller/add-new": [
    "Data Controller Registries",
    "Data Controller",
    "Add New",
  ],

  "/dcr/processing-registries": ["Data Controller Registries","Processing Registries"],

  "/dcr/processing-registries/registry": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dcr/processing-registries/registry/display": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dcr/processing-registries/registry/processing": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dcr/processing-registries/add-new": [
    "Data Controller Registries",
    "Processing Registries",
    "Add New",
  ],
  //
  // DPR.
  //
  "/dpr": ["Data Processor Registries"],

  "/dpr/data-processor": ["Data Processor Registries","Data Processors"],

  "/dpr/data-processor/admin": ["Data Processor Registries","Data Processor","Admin"],

  "/dpr/data-processor/add-new": [
    "Data Processor Registries",
    "Data Processor",
    "Add New",
  ],

  "/dpr/data-processor/display": [
    "Data Processor Registries",
    "Data Processor",
    "Display",
  ],

  "/dpr/processing-registries": ["Data Processor Registries","Processing Registries"],

  "/dpr/processing-registries/registry": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dpr/processing-registries/registry/display": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dpr/processing-registries/registry/processing": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dpr/processing-registries/add-new": [
    "Data Processor Registries",
    "Processing Registries",
    "Add New",
  ],
  //
  // DPO.
  //
  "/dpo": ["Data Protection Officers"],

  //"/dpo/data-controller": ["Data Processor Officers","Data Controller"],

  //"/dpo/data-controller/admin": ["Data Processor Officers","Data Controller","Admin"],

  "/dpo/display": [
    "Data Protection Officers",
    "Display",
  ],
  /*
  "/dpo/data-controller/add-new": [
    "Data Controller Registries",
    "Data Controller",
    "Add New",
  ],

  "/dpo/processing-registries": ["Data Processor Officers","Processing Registries"],

  "/dpo/processing-registries/registry": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dpo/processing-registries/registry/display": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dpo/processing-registries/registry/processing": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dpo/processing-registries/add-new": [
    "Data Controller Registries",
    "Processing Registries",
    "Add New",
  ],
  */
};

function App () {
  let defaultClient = ApiClient.instance;

  const PrivateRoute = ({ component: Component,...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          store.getState().authState.isAuthenticated === true ? (
            <Layout opened={false} children={props.children}>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  const AvailableRoutes = () => {
    let res = [];

    const token = JSON.stringify(localStorage.token);

    if (!token) return <Redirect to={"/"} />;
    else {
      res = [
        //
        // HOME.
        //
        <PrivateRoute path={"/home"} component={Home} key={"home"} />,
        //
        // PROFILE.
        //
        <PrivateRoute path={"/profile"} component={Profile} key={"profile"} />,
        //
        // DCR.
        //
        <PrivateRoute
          path={"/dcr"}
          component={DataControllerRegistries}
          key={"dcr"}
        />,
        <PrivateRoute
          path={"/dcr/data-controller"}
          component={DataController}
          key={"dcrDataController"}
        />,
        <PrivateRoute
          path={"/dcr/data-controller/admin"}
          component={Admin}
          key={"dcrDataControllerAdmin"}
        />,
        <PrivateRoute
          path={"/dcr/data-controller/add-new"}
          component={NewDataController}
          key={"dcrDataControllerNewRegistry"}
        />,
        <PrivateRoute
          path={"/dcr/processing-registries"}
          component={DcrProcessingRegistries}
          key={"dcrProcessingRegistries"}
        />,
        <PrivateRoute
          path={"/dcr/processing-registries/registry"}
          component={DcrRegistry}
          key={"dcrProcessingRegistriesRegistry"}
        />,
        <PrivateRoute
          path={"/dcr/processing-registries/registry/processing"}
          component={DcrTreatment}
          key={"dcrProcessingRegistriesProcessingTreatments"}
        />,
        <PrivateRoute
          path={"/dcr/processing-registries/add-new"}
          component={NewDcrRegistry}
          key={"dcrProcessingRegistriesNewTreatment"}
        />,
        <PrivateRoute
          path={"/dcr/data-controller/display"}
          component={DisplayInfo}
          key={"dcrDataControllerDisplayDataControllerInfo"}
        />,
        <PrivateRoute
          path={"/dcr/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dcrProcessingRegistriesDisplayRegistryInfo"}
        />,
        /*
        <PrivateRoute
          path={"/dcr/dpo/display"}
          component={DisplayInfo}
          key={"dcrDisplayDpo"}
        />
        */,
        //
        // DPR.
        //
        <PrivateRoute
          path={"/dpr"}
          component={DataProcessorRegistries}
          key={"dpr"}
        />,
        <PrivateRoute
          path={"/dpr/data-processor"}
          component={DataProcessor}
          key={"dprDataProcessor"}
        />,
        <PrivateRoute
          path={"/dpr/data-processor/display"}
          component={DisplayInfo}
          key={"dprDataProcessorDisplayDataProcessorInfo"}
        />,
        <PrivateRoute
          path={"/dpr/data-processor/admin"}
          component={Admin}
          key={"dprDataProcessorAdmin"}
        />,
        <PrivateRoute
          path={"/dpr/data-processor/add-new"}
          component={NewDataProcessor}
          key={"dprDataProcessorNewRegistry"}
        />,
        <PrivateRoute
          path={"/dpr/processing-registries"}
          component={DprProcessingRegistries}
          key={"dprProcessingRegistries"}
        />,
        <PrivateRoute
          path={"/dpr/processing-registries/registry"}
          component={DprRegistry}
          key={"dprProcessingRegistriesRegistry"}
        />,
        <PrivateRoute
          path={"/dpr/processing-registries/registry/processing"}
          component={DprTreatment}
          key={"dprProcessingRegistriesProcessingTreatments"}
        />,
        <PrivateRoute
          path={"/dpr/processing-registries/add-new"}
          component={NewDprRegistry}
          key={"dprProcessingRegistriesNewTreatment"}
        />,
        <PrivateRoute
          path={"/dpr/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dprProcessingRegistriesDisplayRegistryInfo"}
        />,
        //
        // DPO.
        //
        <PrivateRoute
          path={"/dpo"}
          //component={DataProtectionOfficers}
          component={DataProtectionOfficers}
          key={"dpo"}
        />,
        <PrivateRoute
          path={"/dpo/display"}
          component={DisplayInfo}
          key={"dpoDisplayDpo"}
        />,
        /*
        <PrivateRoute
          path={"/dpo/data-controller"}
          component={DataController}
          key={"dpoDataController"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/data-controller/admin"}
          component={Admin}
          key={"dpoDataControllerAdmin"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/data-controller/add-new"}
          component={NewDataController}
          key={"dpoDataControllerNewRegistry"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/processing-registries"}
          component={ProcessingRegistries}
          key={"dpoProcessingRegistries"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/processing-registries/registry"}
          component={Registry}
          key={"dpoProcessingRegistriesRegistry"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/processing-registries/registry/processing"}
          component={Treatment}
          key={"dpoProcessingRegistriesProcessingTreatments"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/processing-registries/add-new"}
          component={NewRegistry}
          key={"dpoProcessingRegistriesNewTreatment"}
        />
        */,
        /*
        <PrivateRoute
          path={"/dpo/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dpoDisplayRegistryInfo"}
        />
        */,
      ];

      return res;
    }
  };
  const token = localStorage.getItem("token");

  if (token) {
    store.dispatch(doLogin({ key: token }));

    let tokenAuth = defaultClient.authentications["tokenAuth"];
    tokenAuth.apiKey = token;
    tokenAuth.apiKeyPrefix = "Token ";
  }

  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route exact path="/">
                  {" "}
                  <Redirect to={"/login"} />{" "}
                </Route>
                <Route path="/login" component={Login} key={"login"} />
                <Route
                  path="/reset-password"
                  component={ResetPassword}
                  key={"reset"}
                />

                <AvailableRoutes />

                <Route path="/" component={NoMatch} key={"Error"} />
              </Switch>
            </ConnectedRouter>
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
