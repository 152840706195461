import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: theme.palette.primary,
    color: "black",
    height: "70px",
  },
  logo: {
    width: "70px",
    height: "70px",
    cursor: "pointer",
  },
  icon: {
    width: "30px",
    height: "30px",
    color: "white",
  },
  right: {
    display: "flex",
    // flexDirection: "column",
    float: "right",
    alignItems: "center",
    marginLeft: "auto",
    justifyContent: "center",
  },
  profile: {
    color: "white",
    marginTop: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
    whiteSpace: "nowrap",
  }
}));
